import React from 'react'

import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { VsportGame } from 'widgets/vsport-iframe'

import { LayoutStyled } from './page.styled'

export const PageGamesVsportGame = () => {
  return (
    <LayoutStyled type={ELayoutsList.DEFAULT}>
      <VsportGame />
    </LayoutStyled>
  )
}
