import { CashoutUpdate } from 'betweb-openapi-axios'

export enum ECellEventTitle {
  ORDINAR = 'ordinar',
  EXPRESS = 'express',
  SYSTEM = 'system',
  default = 'none'
}

export interface ICellEventCashoutText extends CashoutUpdate {}
