import React, { FC, memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import dayjs from 'dayjs'
import { VirtualGameVendor } from 'betweb-openapi-axios'
import { useTwainGamesData } from 'og-core/providers/virtual-games/hooks'
import { Maybe } from 'astra-core/typings'

import { RootState } from 'shared/types/store'
import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { removeAllChildren } from 'shared/lib/dom'
import { ServiceAvailability } from 'shared/ui/ServiceAvailability'

import {
  StyledTwainGames,
  StyledLoaderPointsWrapper
} from './TwainGames.styled'

export const TwainGames: FC = () => {
  const { token, loaded } = useTwainGamesData()

  if (!loaded) {
    return (
      <StyledLoaderPointsWrapper>
        <LoaderPoints />
      </StyledLoaderPointsWrapper>
    )
  }
  return (
    <ServiceAvailability vendor={VirtualGameVendor.TwainSport}>
      <LiveGamesContainer token={token} />
    </ServiceAvailability>
  )
}

const TWAIN_GAMES_CONTAINER_ID = 'twain_sport_container'

const removeTwainGames = (twainGamesScript?: HTMLScriptElement) => {
  if (twainGamesScript) {
    document.head.removeChild(twainGamesScript)
  }
  const twainGamesContainer = document.getElementById(TWAIN_GAMES_CONTAINER_ID)
  console.log('---------twainGamesContainer', twainGamesContainer) // todo remove after debug
  if (twainGamesContainer) {
    removeAllChildren(twainGamesContainer) // To remove the iFrame added by TwainGames script
  }
}

const LiveGamesContainer = memo(({ token }: { token: Maybe<string> }) => {
  const { i18n } = useTranslation()
  const config = useSelector(selectConfig)
  const isBalanceHidden = useSelector((state: RootState) =>
    selectSettingsDataProp(state, 'balanceHidden')
  )

  useEffect(() => {
    const script = document.createElement('script')

    script.src = `${
      config.TWAIN_GAMES_CLIENT_URL
    }/public/ts-loader.js?${Date.now()}`
    script.onload = () => {
      console.log('---------REMOVE while script.onload') // todo remove after debug
      removeTwainGames()

      window.TwainSports.setup({
        containerElement: document.getElementById(TWAIN_GAMES_CONTAINER_ID),
        clientUrl: config.TWAIN_GAMES_CLIENT_URL,
        apiUrl: config.TWAIN_GAMES_API_URL,
        wsUrl: config.TWAIN_GAMES_WS_URL,
        partnerCode: config.TWAIN_GAMES_PARTNER_CODE,
        token: token ?? '-',
        locale: i18n.languages[0] || 'en',
        showBalance: isBalanceHidden ? 0 : 1,
        theme: 'dark',
        timezone: dayjs().format('Z')
      })
    }

    document.head.appendChild(script)

    return () => {
      console.log('---AFTER') // todo remove after debug
      removeTwainGames(script)
    }
  }, [
    config.TWAIN_GAMES_API_URL,
    config.TWAIN_GAMES_CLIENT_URL,
    config.TWAIN_GAMES_PARTNER_CODE,
    config.TWAIN_GAMES_WS_URL,
    i18n.languages,
    isBalanceHidden,
    token
  ])

  return <StyledTwainGames id={TWAIN_GAMES_CONTAINER_ID} />
})
