import styled, { css } from 'styled-components'

export const StyledEventStatusBanner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 72px 0;
`
export const StyledIcon = styled.div`
  display: flex;
  margin-bottom: 8px;
  ${({ theme }) => `
    color: ${theme.colors.default.primary[50]};
  `};
`
export const StyledEventStatusTitle = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.default.primary[90]};
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.bold};
    line-height: 20px;
  `}
`
export const StyledEventStatusText = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.custom.primary[17]};
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.normal};
    line-height: 16px;
  `}
`
