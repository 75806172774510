import styled from 'styled-components'

import { StyledLineDivider } from 'pages/page-live-events/Line/components/ui/LineDivider'

export const StyledLineTable = styled.div`
  display: grid;
`

export const StyledTopDivider = styled(StyledLineDivider)``

export const StyledGroupedLine = styled.div``

export const StyledLineTableTop = styled.div`
  position: relative;
`
