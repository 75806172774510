import styled from 'styled-components'

export const StyledEventType = styled.div`
  line-height: 16px;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.fixed.primary[50]};
    `};
`
