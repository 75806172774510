import styled, { css } from 'styled-components'

export const StyledOutcomesHead = styled.div<{ withBorder?: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.custom.primary[2]};
  border-bottom: ${({ withBorder, theme }) =>
    withBorder ? `1px solid ${theme.colors.default.primary[5]}` : 'none'};
  display: flex;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
`

export const StyledOutcomesHeadRight = styled.div<{ isLinkedEvent?: boolean }>`
  display: flex;

  > *:first-child {
    margin-left: ${({ isLinkedEvent }) => (isLinkedEvent ? '4px' : '16px')};
  }

  > * {
    margin-left: 16px;
  }
`

export const StyledOutcomesToggleDisplay = styled.div`
  ${(props) => css`
    align-items: center;
    color: ${props.theme.colors.default.primary[50]};
    cursor: pointer;
    display: flex;
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.normal};
  `}
`
