import { EEventsStatusList } from 'astra-core/utils/events/types'

import {
  IconEventCanceled,
  IconEventComing,
  IconEventInterrupted,
  IconEventPostponed,
  IconStatisticsNotAvailable
} from 'shared/ui/Icon/Event/EventStatus'

import { EventsStatusConfiguration } from './EventStatusBanner.types'

export const eventsStatusConfiguration: EventsStatusConfiguration = {
  [EEventsStatusList.CANCELED]: {
    icon: IconEventCanceled,
    statusTitle: 'event canceled'
  },
  [EEventsStatusList.INTERRUPTED]: {
    icon: IconEventInterrupted,
    statusTitle: 'event interrupted'
  },
  [EEventsStatusList.POSTPONED]: {
    icon: IconEventPostponed,
    statusTitle: 'event postponed'
  },
  [EEventsStatusList.COMING]: {
    icon: IconEventComing,
    statusTitle: 'information coming'
  },
  [EEventsStatusList.OUTCOMES_COMING]: {
    icon: IconEventComing,
    statusTitle: 'outcome information coming'
  },
  [EEventsStatusList.STATISTICS_NOT_AVAILABLE]: {
    icon: IconStatisticsNotAvailable,
    statusTitle: 'statistics not available'
  }
}
