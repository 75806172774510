import { createSelector } from '@reduxjs/toolkit'
import { getEventById } from 'astra-core/containers/EventsProvider'
import { getCompetitorLogos } from 'astra-core/containers/LogosProvider/selectors'
import { enrichEvent } from 'astra-core/containers/LogosProvider/utils'

import { RootState } from 'shared/types/store'

import { initialState } from './slice'

export const getEventFetchItem = (state: RootState) =>
  state.eventContainer.fetchItem || initialState.fetchItem

export const selectEvent = createSelector([getEventById], (event) => ({
  event,
  isEmpty: !event
}))

export const selectEventEnriched = createSelector(
  [getEventById, getCompetitorLogos],
  (event, logos) => {
    const enrichedEvent = enrichEvent(event, logos)

    return {
      event: enrichedEvent,
      isEmpty: !enrichedEvent
    }
  }
)

export const selectEventFetchItems = createSelector(
  [getEventFetchItem],
  (liveFetchItems) => liveFetchItems
)
