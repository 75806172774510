import styled from 'styled-components'

export const StyledTournamentShow = styled.button<{ isCursor: boolean }>`
  background-color: ${({ theme }) => theme.colors.custom.primary[65]};
  border: none;
  display: grid;
  outline: none;
  width: 100%;

  ${({ isCursor }) =>
    isCursor ? 'cursor: pointer' : 'pointer-events: none; cursor:none'}
`

export const StyledTournamentTableWrapper = styled.div`
  display: grid;
`
