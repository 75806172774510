import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { verificationActions } from 'og-core/providers/verification/slice'
import { EVerificationSteps } from 'og-core/providers/verification/types'
import { selectIsUserVerified } from 'og-web-core/providers/account/selectors'

import { NotificationAbleWithdrawStyled } from './Styled'

export const NotificationAbleWithdrawal = () => {
  const dispatch = useDispatch()
  const isUserVerified = useSelector(selectIsUserVerified)

  const showAuthenticationModal = useCallback(() => {
    dispatch(
      verificationActions.changeCurrentVerificationStep({
        step: EVerificationSteps.ONE
      })
    )
  }, [dispatch])

  if (isUserVerified) {
    return null
  }

  return (
    <NotificationAbleWithdrawStyled
      linkText="to verification"
      modalWindowAction={showAuthenticationModal}
      text="to be able to withdraw"
    />
  )
}
