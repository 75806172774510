import React, {
  ComponentProps,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useState
} from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Event } from 'betweb-openapi-axios'
import { useGroupedEventsByGroupId } from 'astra-core/hooks/useEvents'
import range from 'lodash/range'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'

import { ETestData } from 'shared/lib/testData'
import {
  Button,
  EButtonIconPositionSides,
  EButtonSizes,
  EButtonViews
} from 'shared/ui/Button'
import { IconChevronRight } from 'shared/ui/Icon/General/IconChevronRight'
import { IconChevronLeft } from 'shared/ui/Icon/General/IconChevronLeft'
import { EColorsNames } from 'shared/types/theme'
import { EOnboardingType } from 'widgets/onboarding/Onboarding.types'
import { useOnboardingAttr } from 'widgets/onboarding/hooks'
import { TableBetsHead } from 'widgets/event-row-head'
import { EventRow } from 'widgets/event-row'
import { useCarouselScroll } from 'hooks/useCarouselScroll'

import { EventCard, EventCardSkeleton } from '../EventCard'

import { SportsList, SportsListSkeleton } from './SportsList'
import { MainPageEventsContext } from './MainPageEvents.context'
import {
  EMainPageEventsRepresentation,
  MainPageEventsProps
} from './MainPageEvents.types'
import {
  EVENTS_TO_ONBOARDING_ATTRS_MAP,
  getMainPageEventsConfiguration
} from './MainPageEvents.constants'
import { StyledSportsListWrapper } from './SportsList/SportsList.styled'
import {
  StyledEventsCarousel,
  StyledEventsTable,
  StyledMainPageEventsControlsWrapper,
  StyledMainPageEventsHeader,
  StyledMainPageEventsHeaderWrapper,
  StyledMainPageEventsWrapper
} from './MainPageEvents.styled'
import { EventsTableSkeleton } from './EventsTableSkeleton'

export const MainPageEvents: FC<MainPageEventsProps> = ({
  mainPageEventsListType
}) => {
  const isEntityTagsEnabled = useFeatureFlag(EFeatureFlags.ENTITY_TAGS_ENABLED)

  const config = getMainPageEventsConfiguration(isEntityTagsEnabled)
  const {
    icon: Icon,
    untranslatedTitle,
    selectLoading,
    selectEvents,
    representation,
    link,
    selectIsEventsIdsBySport
  } = config[mainPageEventsListType]

  const { onboardingAttr } = useOnboardingAttr(
    EVENTS_TO_ONBOARDING_ATTRS_MAP[mainPageEventsListType],
    EOnboardingType.MAIN_PAGE_WELCOME
  )

  const { t } = useTranslation()
  const events = useSelector(selectEvents) // TODO Make request by condition considering last fetch date
  const [initialLoad, setInitialLoad] = useState(true)
  const loading = useSelector(selectLoading)
  const isEventsIdsBySport = useSelector(selectIsEventsIdsBySport)

  useEffect(() => {
    if (loading === false) {
      setInitialLoad(false)
    }
  }, [loading])

  const showSkeleton = initialLoad || loading
  const isEmpty = loading === false && events.length === 0 && isEventsIdsBySport

  const { carouselApi, scrollToIndex } = useCarouselScroll(events)

  const scrollToNext = useCallback(() => {
    carouselApi.current?.scrollToNext()
  }, [carouselApi])

  const scrollToPrevious = useCallback(() => {
    carouselApi.current?.scrollToPrevious()
  }, [carouselApi])

  if (isEmpty) {
    return null
  }

  return (
    <MainPageEventsContext.Provider
      value={{ mainPageEventsListType, scrollToIndex }}
    >
      <StyledMainPageEventsWrapper>
        <StyledMainPageEventsHeaderWrapper>
          {!!Icon && <Icon />}
          <StyledMainPageEventsHeader {...onboardingAttr}>
            {t(untranslatedTitle)}
          </StyledMainPageEventsHeader>

          <Button
            as={Link}
            size={EButtonSizes.S}
            to={link}
            view={EButtonViews.SECONDARY}
          >
            {t('mainPage goOver')}
          </Button>
        </StyledMainPageEventsHeaderWrapper>

        <StyledSportsListWrapper>
          {showSkeleton ? <SportsListSkeleton /> : <SportsList />}

          {representation === EMainPageEventsRepresentation.CAROUSEL && (
            <StyledMainPageEventsControlsWrapper>
              <Button
                iconProps={{
                  size: 16,
                  colorProps: { name: EColorsNames.Primary, value: 50 },
                  positionSide: EButtonIconPositionSides.Center
                }}
                icon={IconChevronLeft}
                size={EButtonSizes.S}
                testData={ETestData.TestMainPageEventsControlLeft}
                view={EButtonViews.SECONDARY}
                onClick={scrollToPrevious}
              />
              <Button
                iconProps={{
                  size: 16,
                  colorProps: { name: EColorsNames.Primary, value: 50 },
                  positionSide: EButtonIconPositionSides.Center
                }}
                icon={IconChevronRight}
                size={EButtonSizes.S}
                testData={ETestData.TestMainPageEventsControlRight}
                view={EButtonViews.SECONDARY}
                onClick={scrollToNext}
              />
            </StyledMainPageEventsControlsWrapper>
          )}
        </StyledSportsListWrapper>
        {representation === EMainPageEventsRepresentation.CAROUSEL ? (
          <EventsCarousel
            events={events}
            showSkeleton={showSkeleton}
            onCarouselMounted={(api) => {
              carouselApi.current = api
            }}
          />
        ) : (
          <EventsTable events={events} showSkeleton={showSkeleton} />
        )}
      </StyledMainPageEventsWrapper>
    </MainPageEventsContext.Provider>
  )
}

type EventsCarouselProps = {
  events: Event[]
  showSkeleton: boolean | undefined
} & ComponentProps<typeof StyledEventsCarousel>

// Skeleton carousel shouldn't call onCarouselMounted event
const EventsCarouselSkeletonCarousel = () => {
  const SKELETON_ITEMS_COUNT = 10

  return (
    <StyledEventsCarousel>
      {range(SKELETON_ITEMS_COUNT).map((item) => (
        <EventCardSkeleton key={item} />
      ))}
    </StyledEventsCarousel>
  )
}

const EventsCarousel: FC<EventsCarouselProps> = ({
  events,
  showSkeleton,
  ...carouselProps
}) => {
  if (showSkeleton) {
    return <EventsCarouselSkeletonCarousel />
  } else {
    return (
      <StyledEventsCarousel {...carouselProps}>
        {events.map((event) => (
          <EventCard event={event} key={event.id} />
        ))}
      </StyledEventsCarousel>
    )
  }
}

interface EventsTableProps {
  events: Event[]
  showSkeleton: boolean | undefined
}

const EventsTable: FC<EventsTableProps> = ({ events, showSkeleton }) => {
  const groupedEvents = useGroupedEventsByGroupId(events) // group events by tournament

  return (
    <StyledEventsTable>
      {showSkeleton ? (
        <EventsTableSkeleton />
      ) : (
        <>
          {groupedEvents.map((eventsGroup) => (
            <Fragment key={eventsGroup.id}>
              <TableBetsHead tournament={eventsGroup} />

              {eventsGroup.events.map((event) => (
                <EventRow event={event} key={event.id} isMainPage />
              ))}
            </Fragment>
          ))}
        </>
      )}
    </StyledEventsTable>
  )
}
