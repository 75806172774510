import styled, { css } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { StyledTitleString } from 'shared/ui/Marquee/styled'

export const MARQUEE_COLORS = (theme: LayoutTheme, color?: string) => {
  if (theme === LayoutTheme.Dark) {
    return `background: linear-gradient(270deg, ${color} 0%, rgba(38, 38, 38, 0.00) 86.67%);`
  } else {
    return `background: linear-gradient(270deg, ${color} 0%, rgba(246, 246, 246, 0.00) 86.67%);`
  }
}
export const MARQUEE_COLORS_ACTIVE = (theme: LayoutTheme, color?: string) => {
  if (theme === LayoutTheme.Dark) {
    return `background: linear-gradient(270deg, ${color} 0%, rgba(51, 51, 51, 0.00) 86.67%);`
  } else {
    return `background: linear-gradient(270deg, ${color} 0%, rgba(230, 230, 230, 0.00) 86.67%);`
  }
}

export const StyledGenericSportListItem = styled.div<{
  isActive?: boolean
  activeBackground?: string
}>`
  ${({ theme, isActive = false, activeBackground }) => css`
    align-items: center;
    background: ${isActive
      ? activeBackground
      : theme.colors.custom.primary[37]};
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: ${theme.fonts.size.s};
    height: 56px;
    justify-content: space-between;
    padding: 6px 8px;
    position: relative;
    width: 92px;

    &:hover {
      background: ${isActive
        ? activeBackground
        : theme.colors.custom.primary[8]};
    }

    & ${StyledGenericSportListItemText} {
      color: ${isActive
        ? theme.colors.default.primary[90]
        : theme.colors.default.primary[50]};
    }

    &:hover ${StyledGenericSportListItemText} {
      color: ${theme.colors.default.primary[90]};
    }

    & ${StyledTitleString} {
      &::after {
        ${MARQUEE_COLORS(
          theme.typeLayoutTheme,
          isActive ? activeBackground : theme.colors.custom.primary[37]
        )}
      }
    }

    &:hover ${StyledTitleString} {
      &::after {
        ${MARQUEE_COLORS_ACTIVE(
          theme.typeLayoutTheme,
          isActive ? activeBackground : theme.colors.custom.primary[8]
        )}
      }
    }
  `}
`

export const StyledGenericSportListItemText = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
    line-height: 16px;
    max-width: 76px;
    position: relative;
  `}
`
