import React, { FC } from 'react'
import { Event } from 'betweb-openapi-axios'
import { useDispatch } from 'react-redux'
import { selectBroadcastAvailabilityByEventId } from 'astra-core/containers/BroadcastProvider/selectors'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'

import { IconEventVideo } from 'shared/ui/Icon/Event'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { matchBroadcastModel } from 'entities/match-broadcast'
import { useAppSelector } from 'app/lib/store'

export const OpenActionMatchLiveBroadcast: FC<{ event: Event }> = ({
  event
}) => {
  const dispatch = useDispatch()
  const hasBroadcast = useAppSelector((state) =>
    selectBroadcastAvailabilityByEventId(state, event.id)
  )
  const isFeatureFlagEnabled = useFeatureFlag(EFeatureFlags.BROADCAST_ENABLED)
  const broadcastEnabled = event.live && hasBroadcast && isFeatureFlagEnabled

  const handleClick = () => {
    dispatch(
      matchBroadcastModel.liveMatchBroadcastActions.setCurrentBroadcast({
        eventId: event.id
      })
    )
  }

  if (!broadcastEnabled) return null

  return (
    <IconEventVideo
      colorProps={{
        name: EColorsNames.Blue,
        type: EColorsTypes.CUSTOM,
        value: 2
      }}
      size={16}
      onClick={handleClick}
    />
  )
}
