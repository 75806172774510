import React, { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { selectInputSearchLine } from 'astra-core/containers/SearchProvider'
import { useGroupedEventsByGroupId } from 'astra-core/hooks/useEvents'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { RootState } from 'shared/types/store'
import {
  selectEventsLayoutIsLoading,
  selectLineEvents,
  selectLineFetchItems
} from 'pages/page-line-tournament/LineTournamentsContainer/selectors'
import { LineParams } from 'pages/page-line/LineContainer/types'
import { lineTournamentsContainerActions } from 'pages/page-line-tournament/LineTournamentsContainer/slice'

import { ClearResultSearch, TableBets } from './components/ui'
import { StyledLineEvents } from './Line.styled'
import { EmptyPageFilter } from './components/EmptyPageFilter'
import { ILineUseLocationState } from './Line.types'
import { TournamentIdContext } from './Line.context'

export const LineEvents: FC = () => {
  const dispatch = useDispatch()
  const { tournamentId } = useParams<LineParams>()
  const location = useLocation()
  const searchText = useSelector(selectInputSearchLine)
  const events = useSelector((state: RootState) =>
    selectLineEvents(state, { tournamentId })
  )

  const eventsGroups = useGroupedEventsByGroupId(events)
  const isLayoutEventsLoading = useSelector(selectEventsLayoutIsLoading)
  const eventsLineFetchItems = useSelector(selectLineFetchItems)

  const resetLineFilter = useCallback(() => {
    dispatch(lineTournamentsContainerActions.resetLineFilter())
  }, [dispatch])

  useEffect(() => {
    const locationState = (location.state as ILineUseLocationState) || {}

    window.onpopstate = () => {
      if (!locationState.maintainLineFilter) {
        resetLineFilter()
      }
    }
  }, [resetLineFilter, location.state])

  const tournamentIdNumber = Number(tournamentId)

  return (
    <TournamentIdContext.Provider value={{ tournamentId: tournamentIdNumber }}>
      <StyledLineEvents $isEmpty={!eventsGroups.length}>
        {isLayoutEventsLoading && !eventsGroups.length ? (
          <LoaderSpinner />
        ) : eventsGroups.length ? (
          <TableBets eventsGroups={eventsGroups} isLinkHead />
        ) : searchText ? (
          <ClearResultSearch />
        ) : eventsLineFetchItems.loading && !eventsGroups.length ? (
          <LoaderSpinner />
        ) : (
          <EmptyPageFilter onButtonClickResetFilter={resetLineFilter} />
        )}
      </StyledLineEvents>
    </TournamentIdContext.Provider>
  )
}
