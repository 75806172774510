import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ELoyaltyStatus,
  accountProviderActions,
  selectLoyaltyFilters,
  selectLoyaltyLengthByStatus
} from 'astra-core/containers/AccountProvider'
import { useDispatch, useSelector } from 'react-redux'

import { Tabs, TabsItem } from 'shared/ui/Tabs'

import {
  StyledBonusCouponsBodyTabsWrapper,
  StyledTabAmount,
  StyledTabName
} from './BonusCouponsBodyTabs.styled'
import { BONUS_COUPONS_BODY_TABS_LIST, getIsActiveTab } from './constants'

export const BonusCouponsBodyTabs = memo(() => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const loyaltyFilters = useSelector(selectLoyaltyFilters)
  const loyaltyLengthByStatus = useSelector(selectLoyaltyLengthByStatus)

  const handleSetCurrentTab = (status: ELoyaltyStatus) => () =>
    dispatch(accountProviderActions.setLoyaltyStatus(status))

  return (
    <StyledBonusCouponsBodyTabsWrapper>
      <Tabs variant={6}>
        {BONUS_COUPONS_BODY_TABS_LIST(loyaltyLengthByStatus).map(
          ({ name, locale, amount }) => (
            <TabsItem
              active={getIsActiveTab(name, loyaltyFilters.currentStatus)}
              handleTabChange={handleSetCurrentTab(name)}
              key={name}
            >
              <StyledTabName>{t(locale)}</StyledTabName>
              <StyledTabAmount>{amount}</StyledTabAmount>
            </TabsItem>
          )
        )}
      </Tabs>
    </StyledBonusCouponsBodyTabsWrapper>
  )
})
