import styled from 'styled-components'

export const StyledDivider = styled.div`
  &::after {
    background-color: ${(props) => props.theme.colors.custom.primary[8]};
    content: '';
    display: block;
    height: 1px;
  }
`
