import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountProviderActions,
  EAuthenticationSteps,
  selectAccountUser
} from 'astra-core/containers/AccountProvider'
import { ClientVerificationStatusEnum } from 'betweb-openapi-axios'

import { Notification } from 'widgets/notification'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { ETestData } from 'shared/lib/testData'

import {
  EBalanceNotification,
  TBalancePageNotificationProps
} from './BalancePageNotification.types'

export const BalancePageNotification: FC<TBalancePageNotificationProps> = ({
  type
}) => {
  const dispatch = useDispatch()
  const user = useSelector(selectAccountUser)

  const handleSupport = () => {
    window.LC_API.open_chat_window()
  }

  const showAuthenticationModal = useCallback(() => {
    dispatch(
      accountProviderActions.changeAuthenticationCurrentStep({
        step: EAuthenticationSteps.ONE
      })
    )
  }, [dispatch])

  if (user?.verification_status !== ClientVerificationStatusEnum.Verified) {
    return (
      <Notification
        linkText="full registration"
        modalWindowAction={showAuthenticationModal}
        text="to be able to withdraw"
      />
    )
  } else if (type === EBalanceNotification.ACCOUNT_BLOCKED) {
    return (
      <Notification
        colors={{
          backgroundColor: {
            name: EColorsNames.Yellow,
            value: 11,
            type: EColorsTypes.CUSTOM
          },
          textColor: {
            name: EColorsNames.Primary,
            value: 38,
            type: EColorsTypes.CUSTOM
          }
        }}
        linkText="contact support"
        modalWindowAction={handleSupport}
        testData={ETestData.TestNotificationLink}
        text="account has been blocked"
      />
    )
  } else {
    return null
  }
}
