import * as core from 'og-core/providers/main-page'

import { IconLiveFilled } from 'shared/ui/Icon/General/IconLiveFilled'
import { IconLineFilled } from 'shared/ui/Icon/General/IconLineFilled'
import { EWelcomeOnboardingDataAttrs } from 'widgets/onboarding/typesOfOnboarding/welcomeOnboarding/welcomeOnboarding.types'

import {
  EMainPageEventsList,
  MainPageEventsConfiguration,
  EMainPageEventsRepresentation
} from './MainPageEvents.types'

export const getMainPageEventsConfiguration = (
  isEntityTagsEnabled: boolean
): MainPageEventsConfiguration => {
  return {
    [EMainPageEventsList.TOP_LIVE]: {
      selectCurrentSport: core.selectTopLiveEventCurrentSportId,
      selectSports: core.selectTopLiveEventsSports,
      selectEvents: core.selectTopLiveEventsEnriched,
      selectLoading: core.selectTopLiveEventsLoading,
      setCurrentSport: core.mainPageProviderActions.selectTopLiveEventsSport,
      untranslatedTitle: 'mainPage topLive',
      icon: IconLiveFilled,
      representation: EMainPageEventsRepresentation.CAROUSEL,
      link: '/live',
      selectIsEventsIdsBySport: core.selectIsTopLiveEventsCurrentSportId
    },
    [EMainPageEventsList.TOP_LINE]: {
      selectCurrentSport: core.selectTopLineEventsCurrentSportId,
      selectSports: core.selectTopLineEventsSports,
      selectEvents: core.selectTopLineEventsEnriched,
      selectLoading: core.selectTopLineEventsLoading,
      setCurrentSport: core.mainPageProviderActions.selectTopLineEventsSport,
      untranslatedTitle: 'mainPage topLine',
      icon: IconLineFilled,
      representation: EMainPageEventsRepresentation.CAROUSEL,
      link: '/line',
      selectIsEventsIdsBySport: core.selectIsTopLineEventsIdsBySport
    },
    [EMainPageEventsList.UPCOMING]: {
      selectCurrentSport: core.selectUpcomingEventsCurrentSportId,
      selectSports: core.selectUpcomingEventsSports,
      selectEvents: isEntityTagsEnabled
        ? core.selectUpcomingEventsByTag
        : core.selectUpcomingEvents,
      selectLoading: core.selectUpcomingEventsLoading,
      setCurrentSport: core.mainPageProviderActions.selectUpcomingEventsSport,
      untranslatedTitle: 'mainPage upcoming',
      representation: EMainPageEventsRepresentation.TABLE,
      link: '/live',
      selectIsEventsIdsBySport: core.selectIsUpcomingEventsIdsBySport
    }
  }
}

export const EVENTS_TO_ONBOARDING_ATTRS_MAP = {
  [EMainPageEventsList.TOP_LINE]: EWelcomeOnboardingDataAttrs.TOP_LINE_SECTION,
  [EMainPageEventsList.TOP_LIVE]: EWelcomeOnboardingDataAttrs.TOP_LIVE_SECTION,
  [EMainPageEventsList.UPCOMING]: EWelcomeOnboardingDataAttrs.NEAREST_EVENTS
}
