import React, { FC, memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'react-fast-compare'

import { RootState } from 'shared/types/store'
import { selectEvent } from 'containers/EventContainer/selectors'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import {
  LINE_EVENT_POLLING_INTERVAL,
  LIVE_EVENT_POLLING_INTERVAL
} from 'containers/EventContainer/utils'
import { useInterval } from 'hooks'
import { actions } from 'containers/EventContainer/slice'
import { selectActiveLinkedEventId } from 'containers/OutcomesContainer/selectors'

import { EventOutcomes, EventOutcomesProps } from '../EventOutcomes'

export const LinkedEventOutcomes: FC<EventOutcomesProps> = memo(
  ({ event, outcomesRef }) => {
    const dispatch = useDispatch()
    const linkedEvents = event.linkedEvents!

    const selectedLinkedEventId =
      useSelector(selectActiveLinkedEventId) ?? linkedEvents[0].eventId

    const { event: linkedEvent, isEmpty } = useSelector((state: RootState) =>
      selectEvent(state, { eventId: selectedLinkedEventId })
    )

    const fetchEvent = useCallback(() => {
      if (selectedLinkedEventId) {
        dispatch(
          actions.fetchEventsByIds({
            ids: [selectedLinkedEventId]
          })
        )
      }
    }, [dispatch, selectedLinkedEventId])

    useInterval(
      fetchEvent,
      event.live ? LIVE_EVENT_POLLING_INTERVAL : LINE_EVENT_POLLING_INTERVAL,
      true
    )

    return isEmpty ? (
      <LoaderSpinner />
    ) : (
      <EventOutcomes
        event={linkedEvent}
        key={linkedEvent.id}
        outcomesRef={outcomesRef}
      />
    )
  },
  isEqual
)
