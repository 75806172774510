import React, { memo } from 'react'
import { useFriggData } from 'og-core/providers/virtual-games/hooks'

import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { FriggFrame } from 'widgets/frigg/ui'

export const Frigg = memo(() => {
  const { loaded, error, url } = useFriggData()

  if (!loaded) {
    return <LoaderPoints />
  }

  if (error) {
    console.error(`Couldn't get frigg token: ${error.title}`)
  }

  return <FriggFrame src={url} />
})
