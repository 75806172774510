import styled from 'styled-components'

import { DataListWithReduxPagination } from 'shared/ui/DataList'

export const StyledCalendarEvents = styled.div``

export const StyledDataList = styled(DataListWithReduxPagination)`
  height: 100%;
  padding-right: 0;
`

export const StyledClearResultSearch = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`
