import { LinkedEvent } from 'betweb-openapi-axios/dist/api'
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { outcomesContainerActions } from 'containers/OutcomesContainer/slice'
import { selectActiveLinkedEventId } from 'containers/OutcomesContainer/selectors'
import { Tabs, TabsItem } from 'shared/ui/Tabs'

import {
  StyledLeftScrollButton,
  StyledRightScrollButton,
  StyledScrollContainer,
  StyledTabItemWrapper,
  StyledTabsWrapper
} from './OutcomesGroupsTabs.styled'

type LinkedEventsOutcomesGroupsTabsProps = {
  linkedEvents: LinkedEvent[]
}

const scrollStep = 200

export const LinkedEventsTabs: FC<LinkedEventsOutcomesGroupsTabsProps> = memo(
  ({ linkedEvents }) => {
    const { state } = useLocation<{ linkedEventId?: number } | undefined>()
    const dispatch = useDispatch()
    const [isScrollable, setIsScrollable] = useState(false)

    const scrollContainerRef = useRef<HTMLDivElement>(null)

    const scrollLeft = () => {
      if (scrollContainerRef) {
        scrollContainerRef?.current?.scrollBy({
          left: -scrollStep,
          behavior: 'smooth'
        })
      }
    }

    const scrollRight = () => {
      if (scrollContainerRef) {
        scrollContainerRef?.current?.scrollBy({
          left: scrollStep,
          behavior: 'smooth'
        })
      }
    }

    const checkIfScrollable = () => {
      if (scrollContainerRef.current) {
        const { scrollWidth, clientWidth } = scrollContainerRef.current
        setIsScrollable(scrollWidth > clientWidth)
      }
    }

    useLayoutEffect(() => {
      checkIfScrollable()

      window.addEventListener('resize', checkIfScrollable)
      return () => window.removeEventListener('resize', checkIfScrollable)
    }, [])

    const selectedLinkedEventId = useSelector(selectActiveLinkedEventId)

    useEffect(() => {
      if (selectedLinkedEventId) {
        return
      }

      const id = state?.linkedEventId ?? linkedEvents[0].eventId

      dispatch(outcomesContainerActions.setSelectedLinkedEventId({ id }))
    }, [dispatch, linkedEvents, selectedLinkedEventId, state?.linkedEventId])

    const handleSelectLinkedEvent = useCallback(
      (id: LinkedEvent['eventId']) => {
        dispatch(outcomesContainerActions.setSelectedLinkedEventId({ id }))
      },
      [dispatch]
    )

    if (!linkedEvents?.length) {
      return null
    }

    return (
      <StyledScrollContainer>
        <StyledTabsWrapper ref={scrollContainerRef}>
          <Tabs variant={2} combined>
            {linkedEvents.map((event) => (
              <StyledTabItemWrapper key={event.eventId}>
                <TabsItem
                  active={selectedLinkedEventId === event.eventId}
                  handleTabChange={handleSelectLinkedEvent}
                  type={event.eventId}
                >
                  {event.eventType?.name}
                </TabsItem>
              </StyledTabItemWrapper>
            ))}
          </Tabs>
          {isScrollable && (
            <>
              <StyledLeftScrollButton onClick={scrollLeft} />
              <StyledRightScrollButton onClick={scrollRight} />
            </>
          )}
        </StyledTabsWrapper>
      </StyledScrollContainer>
    )
  }
)
