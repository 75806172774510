import styled, { css } from 'styled-components'

import { StyledBasketQuickAmountsItemProps } from './BasketQuickAmounts.types'

export const BasketQuickBetsAmountWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 4px;
  overflow: auto;
`

export const BasketQuickBetsAmountItemWrapper = styled.div<StyledBasketQuickAmountsItemProps>`
  background-color: ${(props) =>
    props.active
      ? props.theme.colors.custom.primary[52]
      : props.theme.colors.custom.primary[2]};
  border: 1px solid ${(props) => props.theme.colors.custom.primary[4]};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 4px 0;
`

export const BasketQuickBetsAmountItem = styled.div<StyledBasketQuickAmountsItemProps>`
  ${(props) => css`
    color: ${props.active
      ? props.theme.colors.fixed.primary[0]
      : props.theme.colors.custom.primary[17]};
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.normal};
    line-height: 16px;
    text-align: center;
    width: max-content;
  `}
`
