import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const PanelWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`
export const PanelContent = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.default.primary[90]};
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const PanelTypography = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.m};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  padding: 8px 0 8px 0;
`

export const PanelTypographyLink = styled(Link)`
  background-color: ${({ theme }) => theme.colors.custom.primary[4]};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.custom.primary[7]};
  font-size: ${({ theme }) => theme.fonts.size.s};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  line-height: 16px;
  padding: 4px 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.default.primary[10]};
  }
`
