import React, { memo, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  resultsProviderActions,
  selectCurrentResultsLength,
  selectFetchItemsResults,
  selectFetchPaginationResults,
  selectHasMoreResults,
  selectResultsFilterDate,
  selectResultsPaginationKey
} from 'astra-core/containers/ResultsProvider'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { useTheme } from 'styled-components'
import { defaultResultsStatuses } from 'astra-core/containers/ResultsProvider/constants'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { isFeatureFlagEnabled } from 'astra-core/containers/ConfigProvider/utils'

import { getIsTodayValue } from 'shared/lib/todayDate'
import { IconPreloader } from 'shared/ui/Icon/General/IconPreloader'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'

import {
  StyledClearResult,
  StyledClearResultIcon,
  StyledClearResultIconDescription,
  StyledClearResultIconTitle,
  StyledIconPreloaderWrapper,
  StyledIconWrapper,
  StyledResultDate,
  StyledResultsWrapper
} from './Results.styled'
import { RESULTS_DATE_FORMAT } from './constants'
import { Championships } from './Championships'

const ClearResult = memo(() => {
  const [t] = useTranslation()
  return (
    <StyledClearResult>
      <StyledClearResultIcon size={64} />
      <StyledClearResultIconTitle>{t('no results')}</StyledClearResultIconTitle>
      <StyledClearResultIconDescription
        dangerouslySetInnerHTML={{ __html: t('change filtering') }}
      />
    </StyledClearResult>
  )
})

export const Results = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const date = useSelector(selectResultsFilterDate)
  const isLoadingResults = useSelector(selectFetchItemsResults)
  const theme = useTheme()
  const resultsLength = useSelector(selectCurrentResultsLength)

  const isNewResultsEndpoint = isFeatureFlagEnabled(
    EFeatureFlags.NEW_RESULTS_ENDPOINT
  )

  const isPaginationLoading = useSelector(selectFetchPaginationResults)
  const hasMore = useSelector(selectHasMoreResults)
  const paginationKey = useSelector(selectResultsPaginationKey)

  const loadMore = () => {
    if (paginationKey && hasMore && !isPaginationLoading) {
      dispatch(
        resultsProviderActions.fetchResults({
          withLinkedEvents: false,
          paginationFetch: true,
          reqData: {
            // @ts-ignore
            statuses: defaultResultsStatuses,
            pageKey: paginationKey
          }
        })
      )
    }
  }

  const { loadingRef } = useInfiniteScroll(loadMore, isPaginationLoading)

  useEffect(() => {
    if (isNewResultsEndpoint) {
      dispatch(
        resultsProviderActions.fetchResults({
          withLinkedEvents: false,
          reqData: {
            // @ts-ignore
            statuses: defaultResultsStatuses
          }
        })
      )
    } else {
      dispatch(
        resultsProviderActions.fetchResults({
          withLinkedEvents: false
        })
      )
    }
  }, [dispatch, isNewResultsEndpoint])

  const currentDate = useMemo(
    () =>
      `${getIsTodayValue({ date, t })} ${dayjs(date).format(
        RESULTS_DATE_FORMAT
      )}`,
    [date, t]
  )

  return (
    <StyledResultsWrapper>
      <StyledResultDate>{currentDate}</StyledResultDate>
      {isLoadingResults ? (
        <StyledIconPreloaderWrapper>
          <IconPreloader color={theme.colors.default.green[50]} />
        </StyledIconPreloaderWrapper>
      ) : !resultsLength ? (
        <ClearResult />
      ) : (
        <>
          <Championships />
          {isNewResultsEndpoint && hasMore && (
            <div ref={loadingRef}>
              {isPaginationLoading && (
                <StyledIconWrapper>
                  <IconPreloader color={theme.colors.default.green[50]} />
                </StyledIconWrapper>
              )}
            </div>
          )}
        </>
      )}
    </StyledResultsWrapper>
  )
}
