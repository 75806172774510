import round from 'lodash/round'

import { getDeclinationType } from 'shared/lib/format'

import { IFormatCountSystemCombinations } from './System.types'

export const getCombinationsCount = (length: number, count: number): number => {
  const range = (from: number, to: number) =>
    Array.from({ length: to - from }, (_, index) => index + from)

  const max = Math.max(count, length - count)
  const min = Math.min(count, length - count)

  const divisor = range(max + 1, length + 1).reduce((acc, n) => acc * n, 1)
  const divider = range(1, min + 1).reduce((acc, n) => acc * n, 1)

  return divisor / divider
}

const MAX_EXPRESS_RATE = 2000

export const getSystemMaxWinSum = (systemSize, value: number, outcomes) => {
  if (!systemSize) return 0

  const combinations = getCombinations(outcomes, systemSize)

  const newCombination = combinations
    .map((stakes) =>
      stakes.reduce((acc: any, stake: any) => acc * stake.probability.odd, 1)
    )
    .map((coef: any) => Math.min(coef, MAX_EXPRESS_RATE))
    .map(
      (coef, _, expresses) =>
        round(coef * (value / expresses.length) * 100) / 100
    )
    .reduce((acc, sum) => acc + sum, 0)
    .toFixed(2)

  return +newCombination
}

// TODO improve it
export function getCombinations<T>(
  arr: Array<T>,
  count: number | string
): Array<Array<T>> {
  const combinations: Array<Array<T>> = []
  const { length } = arr
  const countNum = +count

  if (Number.isNaN(countNum)) {
    return []
  }

  if (countNum > length) return []

  if (countNum === length) return [arr]

  if (countNum === length - 1) {
    return arr.map((_, index) =>
      arr.filter((__, idx) => countNum - index !== idx)
    )
  }

  const combinationsCount = getCombinationsCount(length, countNum)

  if (process.env.NODE_ENV !== 'production' && combinationsCount > 500000) {
    console.error(
      'Синхронный расчёт %s комбинаций займет значительное время. Убедитесь, что расчёт действительно необходим или используйте асинхронный вариант',
      combinationsCount
    )
  }
  // Используем рекурсию минимальное количество раз
  // Дешевле пробежаться по массиву, чем уронить поток
  // Даже если итерация чуть медленнее
  const paired = length - countNum
  const delta = Math.abs(countNum - paired)

  if (delta > 3 && countNum > paired && length > 10) {
    const temp = getCombinations(arr, paired)

    return temp.map((items) => arr.filter((i) => !items.includes(i)))
  }
  const combinationData: Array<T> = []

  function combinationUtil(pushToIndex: number, elementIndex: number) {
    // комбинация собрана
    if (pushToIndex === countNum) {
      combinations.push([...combinationData])
      return
    }

    if (elementIndex >= length) return

    // добавляем элемент в комбинацию
    combinationData[pushToIndex] = arr[elementIndex]
    // добавляем в очередь все комбинации с добавленным элементом
    combinationUtil(pushToIndex + 1, elementIndex + 1)

    // когда управление дойдет сюда, мы уже нашли все комбинации с элементом на текущей позиции и можем его перезаписать
    combinationUtil(pushToIndex, elementIndex + 1)
  }
  // начинаем итерации с первого элемента
  combinationUtil(0, 0)

  return combinations
}

export const formatCountSystemCombinations = ({
  combinations,
  t
}: IFormatCountSystemCombinations) => {
  return combinations.map((combination) => ({
    value: combination.value,
    label: `${combination.value} ${t('of')} ${combination.outcomesLength} (${
      combination.combinationsCount
    } ${t('options', {
      // TODO find out why "count" does not work
      context: getDeclinationType(combination.combinationsCount)
    })})`
  }))
}
