import loadable from '@loadable/component'

import { fallback } from './fallback'

export const AboutPage = loadable(() => import('../page-about'), {
  resolveComponent: (components) => components.PageAbout,
  fallback
})

export const NotFoundPage = loadable(() => import('../page-404'), {
  resolveComponent: (components) => components.Page404,
  fallback
})

export const MainPage = loadable(() => import('../page-main'), {
  resolveComponent: (components) => components.PageMain,
  fallback
})

export const LinePage = loadable(() => import('../page-line'), {
  resolveComponent: (components) => components.PageLine,
  fallback
})

export const EventPage = loadable(() => import('../page-event'), {
  resolveComponent: (components) => components.PageEvent,
  fallback
})

export const LineTournamentPage = loadable(
  () => import('../page-line-tournament'),
  {
    resolveComponent: (components) => components.PageLineTournament,
    fallback
  }
)

export const LivePage = loadable(() => import('../page-live'), {
  resolveComponent: (components) => components.PageLive,
  fallback
})

export const LiveEventsPage = loadable(() => import('../page-live-events'), {
  resolveComponent: (components) => components.PageLiveEvents,
  fallback
})

export const CalendarPage = loadable(() => import('../page-calendar'), {
  resolveComponent: (components) => components.PageCalendar,
  fallback
})

export const RefundPolicyPage = loadable(
  () => import('../page-refund-policy'),
  {
    resolveComponent: (components) => components.PageRefundPolicy,
    fallback
  }
)

export const BalancePage = loadable(() => import('../page-balance'), {
  resolveComponent: (components) => components.PageBalance,
  fallback
})

export const BetsHistoryPage = loadable(() => import('../page-bets-history'), {
  resolveComponent: (components) => components.PageBetsHistory,
  fallback
})

export const ResultsPage = loadable(() => import('../page-results'), {
  resolveComponent: (components) => components.PageResults,
  fallback
})

export const StatisticsPage = loadable(() => import('../page-statistics'), {
  resolveComponent: (components) => components.PageStatistics,
  fallback
})

export const GamesBetPage = loadable(() => import('../page-games-bet'), {
  resolveComponent: (components) => components.PageGamesBet,
  fallback
})

export const GamesLivePage = loadable(() => import('../page-games-live'), {
  resolveComponent: (components) => components.PageGamesLive,
  fallback
})

export const GamesTurboPage = loadable(() => import('../page-games-turbo'), {
  resolveComponent: (components) => components.PageGamesTurbo,
  fallback
})

export const GamesTurboGamePage = loadable(
  () => import('../page-games-turbo-game'),
  {
    resolveComponent: (components) => components.PageGamesTurboGame,
    fallback
  }
)

export const GamesTwainPage = loadable(() => import('../page-games-twain'), {
  resolveComponent: (components) => components.PageGamesTwain,
  fallback
})

export const GamesCyberbetPage = loadable(
  () => import('../page-games-cyberbet/ui'),
  {
    resolveComponent: (components) => components.PageGamesCyberbet,
    fallback
  }
)

export const GamesVsportPage = loadable(
  () => import('../page-games-vsport/ui'),
  {
    resolveComponent: (components) => components.PageGamesVsport,
    fallback
  }
)

export const GamesVsportGamePage = loadable(
  () => import('../page-games-vsport-game/ui'),
  {
    resolveComponent: (components) => components.PageGamesVsportGame,
    fallback
  }
)

export const SportGamesPage = loadable(() => import('../page-sport-games'), {
  resolveComponent: (components) => components.PageSportGames,
  fallback
})

export const CasinoPage = loadable(() => import('../page-casino'), {
  resolveComponent: (components) => components.PageCasino,
  fallback
})

export const FriggPage = loadable(() => import('../page-frigg'), {
  resolveComponent: (components) => components.FriggPage,
  fallback
})

export const FavouriteEventsPage = loadable(
  () => import('../page-favourite-events'),
  {
    resolveComponent: (components) => components.PageFavouriteEvents,
    fallback
  }
)

export const ProfilePage = loadable(() => import('../page-profile'), {
  resolveComponent: (components) => components.PageProfile,
  fallback
})

export const NotificationPage = loadable(() => import('../page-notification'), {
  resolveComponent: (components) => components.PageNotification,
  fallback
})

export const PromotionPage = loadable(() => import('../page-promotion'), {
  resolveComponent: (components) => components.PagePromotion,
  fallback
})

export const PromotionAndBonusesPage = loadable(
  () => import('../page-promotion-and-bonuses'),
  {
    resolveComponent: (components) => components.PagePromotionAndBonuses,
    fallback
  }
)

export const ResponsibleGamblingPage = loadable(
  () => import('../page-responsible-gambling'),
  {
    resolveComponent: (components) => components.PageResponsibleGambling,
    fallback
  }
)

export const PolicyPrivacyPage = loadable(
  () => import('../page-policy-privacy'),
  {
    resolveComponent: (components) => components.PagePolicyPrivacy,
    fallback
  }
)

export const PolicyAmlPage = loadable(() => import('../page-policy-aml'), {
  resolveComponent: (components) => components.PagePolicyAml,
  fallback
})

export const TermsAndConditionsPage = loadable(
  () => import('../page-terms-and-conditions'),
  {
    resolveComponent: (components) => components.PageTermsAndConditions,
    fallback
  }
)

export const AuthSuccessPage = loadable(() => import('../page-auth-success'), {
  resolveComponent: (components) => components.PageAuthSuccess,
  fallback
})

export const AuthSuccessSignUpPage = loadable(
  () => import('../page-auth-success-sign-up'),
  {
    resolveComponent: (components) => components.PageAuthSuccessSignUp,
    fallback
  }
)

export const HeadToHeadPage = loadable(() => import('../page-head-to-head'), {
  resolveComponent: (components) => components.PageHeadToHead,
  fallback
})

export const SearchPage = loadable(() => import('../page-search'), {
  resolveComponent: (components) => components.PageSearch,
  fallback
})

export const EventCompletedPage = loadable(
  () => import('../page-event-completed'),
  {
    resolveComponent: (components) => components.PageEventCompleted,
    fallback
  }
)
