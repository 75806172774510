import React, { memo } from 'react'
import { useCasinoData } from 'og-core/providers/virtual-games/hooks/useCasinoData'

import { LoaderPoints } from 'shared/ui/LoaderPoints'

import { StyledLoaderPointsWrapper } from './Casino.styled'

export const Casino = () => {
  const { loaded, src } = useCasinoData()

  if (!loaded) {
    return (
      <StyledLoaderPointsWrapper>
        <LoaderPoints />
      </StyledLoaderPointsWrapper>
    )
  }

  return <CasinoIframe src={src} />
}

const CasinoIframe = memo(({ src }: { src: string }) => {
  return (
    <iframe
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
        outline: 'none',
        font: 'inherit',
        verticalAlign: 'baseline'
      }}
      src={src}
      title="casino-iframe"
    />
  )
})
