import { useEffect, useRef } from 'react'

export const useInfiniteScroll = (
  onLoadMore: () => void,
  isLoading: boolean
) => {
  const observerRef = useRef<IntersectionObserver | null>(null)
  const loadingRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!loadingRef.current) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isLoading) {
          onLoadMore()
        }
      },
      { rootMargin: '100px' }
    )

    observer.observe(loadingRef.current)
    observerRef.current = observer

    return () => {
      observer.disconnect()
      observerRef.current = null
    }
  }, [isLoading, onLoadMore])

  return { loadingRef }
}
