import React, { FC, memo } from 'react'
import { getEventStatusType } from 'astra-core/utils/events'
import { EventStatus } from 'betweb-openapi-axios'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { EventStatusBanner } from 'pages/page-event/Event/components/EventStatusBanner/EventStatusBanner'

import { Outcomes, OutcomesComponentType } from '../Outcomes'
import {
  Outcomes as OutcomesV3,
  OutcomesComponentType as OutcomesComponentTypeV3
} from '../OutcomesV3'

import { EventOutcomesProps } from './EventOutcomes.types'

export const EventOutcomes: FC<EventOutcomesProps> = memo(
  ({ event, outcomesRef }) => {
    const { eventStatusType } = getEventStatusType(event)
    const { id } = event

    const isLoadingOutcomes = event?.status === EventStatus.Suspended

    const newOutcomesEnabled = useFeatureFlag(
      EFeatureFlags.NEW_OUTCOMES_ENABLED
    )

    return (
      <>
        {eventStatusType && (
          <EventStatusBanner eventsStatusListType={eventStatusType} />
        )}
        {newOutcomesEnabled ? (
          <OutcomesV3
            eventId={id}
            eventStatusType={eventStatusType}
            isLoading={isLoadingOutcomes}
            outcomesRef={outcomesRef}
            type={OutcomesComponentTypeV3.EVENT_PAGE}
          />
        ) : (
          <Outcomes
            eventId={id}
            eventStatusType={eventStatusType}
            isLoading={isLoadingOutcomes}
            outcomesRef={outcomesRef}
            type={OutcomesComponentType.EVENT_PAGE}
          />
        )}
      </>
    )
  }
)

EventOutcomes.displayName = 'EventOutcomes'
