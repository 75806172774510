import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconBetradarLogo: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="8"
      viewBox="0 0 60 8"
      width="60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0H0.869788V2.80601H0.889994C1.07184 2.27923 1.54811 2.05036 2.53913 2.05036H3.86498C5.95959 2.05036 6.5975 2.62726 6.5975 4.24826V5.14387C6.5975 6.89443 5.49487 7.28218 3.91597 7.28218H2.70173C1.79153 7.28218 1.08242 7.01359 0.829378 6.39697H0.809173V7.20274H0V0ZM5.72771 4.13855C5.72771 2.96489 5.13021 2.74642 3.93618 2.74642H2.76235C1.58852 2.74642 0.869788 2.94503 0.869788 4.15841V5.12306C0.869788 6.048 1.28448 6.58517 2.5805 6.58517H3.99679C5.41405 6.58517 5.72771 6.11798 5.72771 5.13347V4.13855Z"
        fill="#E6F4FF"
      />
      <path
        d="M18.3098 2.82587H17.1764V2.1298H18.3098V0.925878H19.1806V2.1298H22.6309V2.82587H19.1806V5.35193C19.1806 6.29673 19.5144 6.58518 20.466 6.58518H20.7393C21.8929 6.58518 22.0747 6.34685 22.0747 5.28195V4.90365H22.7829V5.46069C22.7829 6.47547 22.6818 7.28124 20.7989 7.28124H20.3236C18.6947 7.28124 18.3098 6.50573 18.3098 5.6801V2.82587Z"
        fill="#E6F4FF"
      />
      <path
        d="M23.8797 2.12886H25.7011V3.09351H25.7213C25.9436 2.28774 26.7335 2.04941 27.6139 2.04941C29.0301 2.04941 29.5872 2.64618 29.5872 4.04871C29.5872 4.17827 29.5766 4.3173 29.567 4.49604H27.8871C27.8871 3.84915 27.7351 3.54084 26.9557 3.54084C26.288 3.54084 25.8223 3.78957 25.8223 4.39674V7.2018H23.8797V2.12886Z"
        fill="#E6F4FF"
      />
      <path
        d="M30.305 5.42097C30.305 4.0383 31.2768 3.95886 33.1588 3.95886C34.221 3.95886 34.8079 3.98912 35.1822 4.36647V3.91914C35.1822 3.42168 35.0302 3.24294 34.271 3.24294H33.128C32.5613 3.24294 32.3996 3.29306 32.3794 3.65055H30.4974C30.4974 2.27828 31.1854 2.04941 32.8249 2.04941H35.0811C36.4166 2.04941 37.1257 2.67644 37.1257 3.86996V7.2018H35.2842V6.6249C34.7473 7.17248 34.5452 7.28124 33.5734 7.28124H32.2476C31.0641 7.2907 30.305 7.03251 30.305 5.83899V5.42097ZM33.7659 6.08772C35.1013 6.08772 35.2235 5.88911 35.2235 5.55054C35.2235 5.17224 35.1119 5.03322 34.0391 5.03322H33.179C32.3592 5.03322 32.187 5.21196 32.187 5.62998C32.187 5.95815 32.4506 6.08772 33.2088 6.08772H33.7659Z"
        fill="#E6F4FF"
      />
      <path
        d="M38.1062 4.20759C38.1062 2.91476 38.4102 2.04941 40.8685 2.04941H41.4448C42.2444 2.04941 43.0641 2.26788 43.1854 3.06419H43.2056V0H45.1482V7.2018H43.3268V6.26646H43.3066C43.094 6.89349 42.6081 7.28124 41.4343 7.28124H40.8675C38.4083 7.28124 38.1052 6.41589 38.1052 5.12306L38.1062 4.20759ZM39.9881 4.96323C39.9881 5.52973 40.0795 5.84844 41.0099 5.84844H42.153C42.9217 5.84844 43.3268 5.67916 43.3268 4.91406V4.4166C43.3268 3.65055 42.9217 3.48126 42.153 3.48126H41.0099C40.0786 3.48126 39.9881 3.79998 39.9881 4.36647V4.96323Z"
        fill="#E6F4FF"
      />
      <path
        d="M46.2277 5.42097C46.2277 4.0383 47.1995 3.95886 49.0815 3.95886C50.1437 3.95886 50.7306 3.98912 51.1049 4.36647V3.91914C51.1049 3.42168 50.9529 3.24294 50.1947 3.24294H49.0507C48.484 3.24294 48.3223 3.29306 48.3021 3.65055H46.4201C46.4201 2.27828 47.1081 2.04941 48.7476 2.04941H51.0038C52.3393 2.04941 53.0475 2.67644 53.0475 3.86996V7.2018H51.2059V6.6249C50.67 7.17248 50.467 7.28124 49.4962 7.28124H48.1703C46.9859 7.2907 46.2277 7.03251 46.2277 5.83899V5.42097ZM49.6886 6.08772C51.0241 6.08772 51.1453 5.88911 51.1453 5.55054C51.1453 5.17224 51.0337 5.03322 49.9609 5.03322H49.1007C48.281 5.03322 48.1087 5.21196 48.1087 5.62998C48.1087 5.95815 48.3714 6.08772 49.1305 6.08772H49.6886Z"
        fill="#E6F4FF"
      />
      <path
        d="M54.2925 2.12886H56.1139V3.09351H56.1341C56.3563 2.28774 57.1462 2.04941 58.0266 2.04941C59.4429 2.04941 60 2.64618 60 4.04871C60 4.17827 59.9894 4.3173 59.9798 4.49604H58.2999C58.2999 3.84915 58.1479 3.54084 57.3685 3.54084C56.7008 3.54084 56.2351 3.78957 56.2351 4.39674V7.2018H54.2925V2.12886Z"
        fill="#E6F4FF"
      />
      <path
        d="M12.5908 2.73508H11.3159C9.94003 2.73508 9.61578 2.95354 9.53496 4.10734H14.4025V3.76877C14.4025 3.25145 14.008 2.73413 12.5917 2.73413L12.5908 2.73508Z"
        fill="#E6F4FF"
      />
      <path
        d="M12.7938 1.30985H11.2024C9.09333 1.30985 7.55388 1.80636 7.55388 3.94278V5.36707C7.55388 7.50349 9.09333 8 11.2024 8H12.7938C14.9028 8 16.4423 7.50443 16.4423 5.36707V3.94278C16.4423 1.80636 14.9028 1.30985 12.7938 1.30985ZM15.2723 4.80435H9.53496V5.12306C9.53496 6.27687 10.1017 6.57572 11.62 6.57572H12.814C13.907 6.57572 14.3525 6.4367 14.4025 5.66024H15.2733V5.75955C15.2733 6.92281 14.2919 7.27178 12.6725 7.27178H11.0937C9.96023 7.27178 8.66517 7.06277 8.66517 5.19305V4.08937C8.66517 2.24897 9.96023 2.03996 11.1947 2.03996H12.5908C14.1495 2.03996 15.2723 2.42771 15.2723 4.23785V4.80435Z"
        fill="#E6F4FF"
      />
    </svg>
  </Icon>
)
