import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutcomeInfo } from 'og-web-core/providers/basket/quick-bet/hooks/useOutcomeInfo'
import { useHandleMakeQuickBet } from 'og-web-core/providers/basket/quick-bet/hooks/useHandleMakeQuickBet'
import { getBasketOutcomeKey } from 'astra-core/containers/BasketProvider'

import { ETestData } from 'shared/lib/testData'
import { Button, EButtonViews } from 'shared/ui/Button'
import { useNotifyMakeQuickBet } from 'hooks/useNotifyMakeQuickBet'

import { BasketQuickBetSubmitButton } from '../../../right-container/Basket/BasketQuickBet/components/BasketQuickBetSubmitButton/BasketQuickBetSubmitButton'

import { CoefficientChangedModalContentProps } from './CoefficientChangedContent.types'
import {
  StyledButtonWrapper,
  StyledCoefficient,
  StyledCoefficientChangedModalDescription,
  StyledCoefficientText,
  StyledCoefficientTitle,
  StyledCoefficientWrapper
} from './CoefficientChangedContent.styled'

export const CoefficientChangedContent: FC<
  CoefficientChangedModalContentProps
> = ({ outcome, onClickClose }) => {
  const { t } = useTranslation()
  const { basketOutcomeName, competitorsNames, currentOdd } = useOutcomeInfo(
    outcome.outcomeCompositeId
  )

  const { eventId, outcomeTypeId, parameters } = outcome.outcomeCompositeId
  const outcomeKey = getBasketOutcomeKey({
    event: { id: eventId },
    probability: {
      outcomeTypeId,
      parameters
    }
  })

  const notifyCallback = useNotifyMakeQuickBet()
  const { makeBet, isLoading } = useHandleMakeQuickBet(
    notifyCallback,
    outcomeKey
  )

  const handleSubmit = () => {
    makeBet({ ...outcome, coefficient: currentOdd })
  }

  return (
    <>
      <StyledCoefficientWrapper>
        <StyledCoefficient>
          <StyledCoefficientTitle>{t('was')}</StyledCoefficientTitle>
          <StyledCoefficientText>{`${basketOutcomeName} ${outcome.coefficient}`}</StyledCoefficientText>
        </StyledCoefficient>
        <StyledCoefficient>
          <StyledCoefficientTitle>{t('became')}</StyledCoefficientTitle>
          <StyledCoefficientText>{`${basketOutcomeName} ${currentOdd}`}</StyledCoefficientText>
        </StyledCoefficient>
      </StyledCoefficientWrapper>
      <StyledCoefficientChangedModalDescription>
        {`${t('you can make bet')} "${competitorsNames}"`}
      </StyledCoefficientChangedModalDescription>
      <StyledButtonWrapper>
        <BasketQuickBetSubmitButton
          disabled={isLoading}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          testData={ETestData.TestQuickBetModalMakeBetButton}
          title={t('make a bet')}
        />
        <Button
          testData={ETestData.TestQuickBetModalCloseButton}
          view={EButtonViews.SECONDARY}
          onClick={onClickClose}
        >
          {t('сancel')}
        </Button>
      </StyledButtonWrapper>
    </>
  )
}
