import styled, { css } from 'styled-components'
import { ComponentProps } from 'react'

import { Cyberbet } from './cyberbet'

export const CyberbetStyled = styled.div<
  Required<Pick<ComponentProps<typeof Cyberbet>, 'isLoading'>>
>`
  ${(props) => css`
    height: 100%;
    width: 100%;

    ${props.isLoading &&
    css`
      position: absolute;
      visibility: hidden;
      z-index: 0;
    `}

    iframe {
      border: none;
    }
  `}
`
