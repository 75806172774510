import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  getCyberbetIsLoading,
  useCyberbetProvider,
  useSetCyberbetConfig
} from 'astra-core/containers/CyberbetProvider'
import { useCyberbetData } from 'astra-core/containers/VirtualGamesProvider/hooks/useCyberbetData'

import { Cyberbet as CyberbetEntity } from 'entities/cyberbet'
import { LoaderPoints } from 'shared/ui/LoaderPoints'

import { useReset, useSetCyberbetConfigParams } from '../../lib'

import { CyberbetFrameLoadingStyled } from './cyberbet-frame.styled'

export const CyberbetFrame: FC<{ frameId: string }> = ({ frameId }) => {
  useCyberbetProvider()
  useCyberbetData()
  const isLoading = useSelector(getCyberbetIsLoading)
  useSetCyberbetConfigParams({ frameId })
  useSetCyberbetConfig()
  useReset()

  return (
    <>
      {isLoading && (
        <CyberbetFrameLoadingStyled>
          <LoaderPoints />
        </CyberbetFrameLoadingStyled>
      )}
      <CyberbetEntity frameId={frameId} isLoading={isLoading} />
    </>
  )
}
