import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { IconChevronLeft } from 'shared/ui/Icon/General/IconChevronLeft'
import { ERoutes } from 'shared/types/routes'

import {
  StyledPromotion,
  StyledPromotionHead,
  StyledPromotionTitle
} from './Promotion.styled'
import { BonusCoupons } from './components'

export const Promotion = memo(() => {
  const [t] = useTranslation()

  return (
    <StyledPromotion>
      <StyledPromotionHead to={ERoutes.PromotionsAndBonuses}>
        <IconChevronLeft />
        <StyledPromotionTitle>{t('bonus coupons')}</StyledPromotionTitle>
      </StyledPromotionHead>

      <BonusCoupons />
    </StyledPromotion>
  )
})
