import React, { FC } from 'react'

import { getOutcomeTestDataId } from 'shared/lib/testData'
import { useFontOptions } from 'hooks/useFontOptions'

import {
  CoefficientTableCellStyled,
  StyledTextCell
} from './OutcomesTable.styled'
import { TableCellProps } from './OutcomesTable.types'

export const OutcomesTableCell: FC<TableCellProps> = ({
  data,
  renderOdd,
  ...props
}) => {
  const marketOddTestDataId = getOutcomeTestDataId(data)

  if (!data) {
    return <CoefficientTableCellStyled {...props} />
  }

  if (props.isOdd && typeof data !== 'string') {
    const { labelInButton } = props
    return (
      <CoefficientTableCellStyled data-test-id={marketOddTestDataId} {...props}>
        {renderOdd?.({ ...data, labelInButton })}
      </CoefficientTableCellStyled>
    )
  }

  if (typeof data === 'string') {
    return <OutcomeNameWithoutOdd data={data} {...props} />
  }
  return null
}

const OutcomeNameWithoutOdd: FC<TableCellProps> = ({ data, ...props }) => {
  const { options, stringRef, containerRef } = useFontOptions()
  return (
    <CoefficientTableCellStyled {...props} ref={containerRef}>
      <StyledTextCell
        style={{
          fontSize: options.fontSize,
          lineClamp: options.lineClamp,
          whiteSpace: options.whiteSpace
        }}
        ref={stringRef}
      >
        {data}
      </StyledTextCell>
    </CoefficientTableCellStyled>
  )
}
