import React, { memo, useCallback, useMemo, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'
import { useTranslation } from 'react-i18next'
import { ClientVerificationStatusEnum } from 'betweb-openapi-axios'
import { verificationActions } from 'og-core/providers/verification/slice'
import { EVerificationSteps } from 'og-core/providers/verification/types'
import { selectCurrentStep } from 'og-core/providers/verification/selectors'

import { ETestData } from 'shared/lib/testData'
import { Notification } from 'widgets/notification'
import { EColorsNames } from 'shared/types/theme'
import { FullRegistration } from 'widgets/FullRegistration'
import { EButtonViews } from 'shared/ui/Button'
import { Modal, ViewComponentType } from 'shared/ui/modal'
import { RootState } from 'shared/types/store'

import { SectionPassword, Section } from './components'
import { getSectionNameData, getSectionPersonalData } from './constants'
import {
  StyledChangePasswordButton,
  StyledNoticeProfile,
  StyledProfile
} from './Profile.styled'

export const Profile = memo(() => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const [isShownChangePasswordModal, setIsShownChangePasswordModal] =
    useReducer((_, type) => type, false)

  const user = useSelector(selectAccountUser)

  const verificationModalShown = useSelector(
    (state: RootState) => !!selectCurrentStep(state)
  )

  const sectionData = useMemo(
    () =>
      user && {
        fio: getSectionNameData(user),
        personal: getSectionPersonalData(user)
      },
    [user]
  )

  const showAuthenticationModal = useCallback(() => {
    dispatch(
      verificationActions.changeCurrentVerificationStep({
        step: EVerificationSteps.ONE
      })
    )
  }, [dispatch])

  const closeAuthenticationModal = useCallback(() => {
    dispatch(
      verificationActions.changeCurrentVerificationStep({
        step: null
      })
    )
  }, [dispatch])

  return (
    <StyledProfile>
      {user?.verification_status !== ClientVerificationStatusEnum.Verified && (
        <>
          <StyledNoticeProfile>
            <Notification
              colors={{
                iconTutorialColor: { name: EColorsNames.Blue, value: 1 },
                linkColor: { name: EColorsNames.Primary, value: 90 }
              }}
              linkText="go through full registration"
              modalWindowAction={showAuthenticationModal}
              testData={ETestData.TestProfileOpenVerificationForm}
              text="full access"
            />
          </StyledNoticeProfile>
        </>
      )}
      <Section data={sectionData?.fio} title="full name short" />
      <Section data={sectionData?.personal} title="data" />
      <SectionPassword
        isShownChangePasswordModal={isShownChangePasswordModal}
        setIsShownChangePasswordModal={setIsShownChangePasswordModal}
      />
      <Section title={t('user password')}>
        <StyledChangePasswordButton
          data-test-id={ETestData.TestChangeUserPassword}
          view={EButtonViews.SECONDARY}
          onClick={() => setIsShownChangePasswordModal(true)}
        >
          {t('change user password')}
        </StyledChangePasswordButton>
      </Section>
      <Modal
        isOpen={verificationModalShown}
        view={ViewComponentType.PAYMENT}
        onModalClose={closeAuthenticationModal}
      >
        <FullRegistration />
      </Modal>
    </StyledProfile>
  )
})
