import React from 'react'

import { ELayoutsList, Layout } from 'widgets/Layout'

import { TwainGames } from './TwainGames'

export const PageGamesTwain = () => {
  return (
    <Layout type={ELayoutsList.GAME}>
      <TwainGames />
    </Layout>
  )
}
