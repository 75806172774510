import React, { FC, Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { useSSR } from 'react-i18next'
import './app/lib/i18n'

import { routes } from 'pages/model'

import App from './app/app'

export const AppContainer: FC<{ store }> = ({ store }) => {
  useSSR(window.initialI18nStore, window.initialLanguage)

  return (
    <Suspense fallback={<div>Still loading i18n...</div>}>
      <Provider store={store}>
        <BrowserRouter>
          <App routes={routes} />
        </BrowserRouter>
      </Provider>
    </Suspense>
  )
}
