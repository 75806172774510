import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { TurboGames } from './TurboGames'

export const PageGamesTurbo = () => {
  return (
    <Layout type={ELayoutsList.GAME}>
      <TurboGames />
    </Layout>
  )
}
