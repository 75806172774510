import React from 'react'

import { StyledPromotions } from './PromotionsAndBonuses.styled'
import { BonusCouponsBody } from './components/BonusCouponsBody'

export const PromotionsAndBonuses = () => {
  return (
    <StyledPromotions>
      <BonusCouponsBody />
    </StyledPromotions>
  )
}
