import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { TurboGamesGame } from 'pages/page-games-turbo/TurboGames/components/TurboGamesGame/TurboGamesGame'

export const PageGamesTurboGame = () => {
  return (
    <Layout type={ELayoutsList.GAME}>
      <TurboGamesGame />
    </Layout>
  )
}
