import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'

const MIN_HEIGHT = '16px'

export const StyledEventsWrapper = styled.div``

export const StyledEventWrapper = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.custom.primary[2]};
  border-bottom: 1px solid ${(props) => props.theme.colors.custom.primary[4]};
  display: flex;
  justify-content: space-between;
  line-height: 16px;
  min-height: 31px;
  padding: 0 16px;
  width: 100%;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.xs};
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.default.primary[90]};
    `}
`

export const StyledLeftSide = styled.div`
  display: flex;
  width: 50%;
`

export const StyledEventDate = styled.div`
  align-items: center;
  display: flex;
  height: ${MIN_HEIGHT};
  justify-content: center;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
    `}
  margin-right: 16px;
`

export const StyledEventCompetitorsByStatus = styled(Link)`
  align-items: center;
  display: flex;
  height: ${MIN_HEIGHT};
  justify-content: center;
  position: relative;
  ${({ theme }) =>
    `
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.s};
    `}
`
export const StyledEventCompetitors = styled.div`
  align-items: center;
  display: flex;
  height: ${MIN_HEIGHT};
  justify-content: center;
  position: relative;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
    `}
`

export const StyledRightSide = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
`

export const StyledEventStatisticsWrapper = styled.div`
  display: flex;
`

export const StyledEventScoreWrapper = styled.div`
  display: flex;
`

export const StyledEventStatus = styled.div`
  align-items: center;
  display: flex;
  height: ${MIN_HEIGHT};
  justify-content: center;
  margin-right: 10px;
  min-width: 24px;

  ${({ theme }) =>
    `
      color: ${theme.colors.default.red[50]};
    `}
`

// export const StyledEventCurrentScore = styled.div`
//   display: flex;
//   align-items: center;
//   height: ${MIN_HEIGHT};
//   padding: 0 4px;
//   ${({ theme }) =>
//     `
//       background-color: ${theme.colors.default.primary[5]};
//       font-weight: ${theme.fonts.weight.bold};
//     `};
// `

export const StyledIconChevronDown = styled(IconChevronDown)`
  align-items: center;
  display: flex;
  height: ${MIN_HEIGHT};
  justify-content: center;
`
