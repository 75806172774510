import styled from 'styled-components'

export const StyledBannerStatsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const StyledBannerStatsItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  width: 100%;
`
export const StyledStatsItemTitle = styled.div`
  align-items: center;
  color: ${(props) => props.theme.colors.custom.primary[16]};
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.m};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 20px;
  white-space: nowrap;
`

export const StyledBannerStatsItemScore = styled.div`
  color: ${(props) => props.theme.colors.default.primary[90]};
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.l};
  font-weight: ${(props) => props.theme.fonts.weight.bold};

  line-height: 20px;
`

export const StyledBannerStatsBarWrapper = styled.div`
  display: flex;
  gap: 8px;
  height: 6px;
  width: 100%;
`

export const StyledBannerStatsBarContainer = styled.div`
  background-color: ${(props) => props.theme.colors.custom.primary[18]};
  border-radius: 3px;
  display: flex;
  position: relative;
  width: 50%;
`

export const StyledBannerStatsBarAwayItem = styled.div<{
  width: number
}>`
  background-color: ${(props) => props.theme.colors.fixed.blue[1]};
  border-radius: 3px;
  height: 100%;
  left: 0;
  position: absolute;
  width: ${(props) => props.width}%;
`

export const StyledBannerStatsBarHomeItem = styled.div<{
  width: number
}>`
  background-color: ${(props) => props.theme.colors.fixed.green[1]};
  border-radius: 3px;
  height: 100%;
  position: absolute;
  right: 0;
  width: ${(props) => props.width}%;
`
