import styled from 'styled-components'

export const StyledChampionshipsWrapper = styled.div``

export const StyledChampionshipWrapper = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.custom.primary[31]};
  display: flex;
  height: 24px;
  padding-left: 16px;
  position: sticky;
  top: -1px;
  width: 100%;
  z-index: 1;
`

export const StyledChampionshipName = styled.div`
  grid-area: text;
  line-height: 16px;
  margin-left: 4px;
  margin-right: auto;

  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.bold};
      color: ${theme.colors.default.primary[90]};
    `}
`

export const StyledChampionshipNameWrapper = styled.div`
  display: grid;
  grid-template-areas: 'text';
  grid-template-columns: 1fr;
  overflow: hidden;
  position: relative;
`
