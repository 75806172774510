import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'
import {
  getSelectedPeriodOptionValue,
  useLineFilterPeriodTimesComparison
} from 'astra-core'
import { useGroupedTournaments } from 'astra-core/hooks/useEvents'
import { selectSports } from 'astra-core/containers/CommonDataProvider'

import { useInterval, usePrevious } from 'hooks'
import {
  OPTIONS_SELECT_PERIOD,
  getScheduleTime
} from 'pages/page-live-events/Line/components/ui/LineHeader/components/LineHeaderFilters/constants'
import { RootState } from 'shared/types/store'

import {
  selectFilterIsTop,
  selectFilterPeriod,
  selectFilterSportId,
  selectLayoutIsLoading,
  selectLineTournaments
} from './selectors'
import {
  getFetchLineTournamentsReqData,
  LINE_TOURNAMENTS_POLLING_INTERVAL
} from './utils'
import {
  LineTournamentsParams,
  UseTournamentsGroupedBySubSportProps
} from './types'
import { lineTournamentsContainerActions } from './slice'

export const useLineTournamentsContainerData = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { sportId } = useParams<LineTournamentsParams>()
  const sportIdPrev = usePrevious(sportId)
  const top = useSelector(selectFilterIsTop)
  const filterPeriod = useSelector(selectFilterPeriod)
  const isLayoutLoading = useSelector(selectLayoutIsLoading)
  const periodOptions = OPTIONS_SELECT_PERIOD(t)
  const selectedPeriodValue = useMemo(
    () => getSelectedPeriodOptionValue(periodOptions, filterPeriod),
    [filterPeriod, periodOptions]
  )

  const setFilterPeriod = useCallback(() => {
    if (selectedPeriodValue) {
      dispatch(
        lineTournamentsContainerActions.setFilterPeriod({
          ...getScheduleTime(selectedPeriodValue)
        })
      )
    }
  }, [dispatch, selectedPeriodValue])

  const filters = useMemo(() => ({ top, filterPeriod }), [filterPeriod, top])
  const filtersPrev = usePrevious(filters)

  const fetchLineTournaments = useCallback(() => {
    if (sportId) {
      dispatch(
        lineTournamentsContainerActions.fetchLineTournaments(
          getFetchLineTournamentsReqData({ sportId: +sportId })
        )
      )
    }
  }, [dispatch, sportId])

  useEffect(() => {
    /* To handle fetch on changed filters */

    if (!isEqual(filtersPrev, filters)) {
      fetchLineTournaments()
    }
  }, [fetchLineTournaments, filters, filtersPrev])

  useEffect(() => {
    /* To handle fetch on sport id change */

    if (!!sportIdPrev && sportIdPrev !== sportId) {
      fetchLineTournaments()
    }
  }, [sportIdPrev, sportId, fetchLineTournaments])

  /* Update Line if the current time does not match the filter */
  useLineFilterPeriodTimesComparison({
    filterPeriod,
    callback: setFilterPeriod,
    selectedPeriodValue
  })

  const intervalDelay = isLayoutLoading
    ? null
    : LINE_TOURNAMENTS_POLLING_INTERVAL

  useInterval(fetchLineTournaments, intervalDelay, true)
}

export const useTournamentsGroupedBySubSport = ({
  sportId
}: UseTournamentsGroupedBySubSportProps) => {
  const allTournaments = useSelector(
    (state: RootState) =>
      selectLineTournaments(state, { sportId: `${sportId}` }) // TODO: selector waits sportId as string
  )

  const sportIdFilter = useSelector(selectFilterSportId)
  const sports = useSelector(selectSports)
  const allGroupedTournaments = useGroupedTournaments(allTournaments, sports)

  const isGenericSport = useMemo(() => {
    return !!(
      allGroupedTournaments.length &&
      allGroupedTournaments.every((t) => t.genericSportId === sportId)
    )
  }, [allGroupedTournaments, sportId])

  let groupedTournaments = allGroupedTournaments
  if (isGenericSport && sportIdFilter) {
    groupedTournaments = groupedTournaments.filter(
      ({ id: sportId }) => sportId === sportIdFilter
    )
  }

  return { isGenericSport, allGroupedTournaments, groupedTournaments }
}
