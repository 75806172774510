import styled, { css } from 'styled-components'

import { SportIcon } from 'shared/ui/Icon/SportIcon'

import { StyledSportsItemItemProps } from './SearchSportList.types'

export const StyledSportList = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
  padding: 8px 16px;
`

export const StyledSportsItem = styled.button<StyledSportsItemItemProps>`
  align-items: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  justify-content: center;
  line-height: 16px;
  outline: none;
  padding: 4px 8px;
  text-decoration: none;
  ${({ theme, $active, backgroundColor }) =>
    $active
      ? css`
          background-color: ${backgroundColor};
          color: ${({ theme }) => theme.colors.default.primary[90]};
        `
      : css`
          background-color: initial;
          color: ${theme.colors.fixed.primary[50]};
        `}
`

export const StyledSportIcon = styled(SportIcon)`
  margin: 4px;
`
