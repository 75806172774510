import styled, { css } from 'styled-components'

import { StyledLineDivider } from 'pages/page-live-events/Line/components/ui/LineDivider'

export const TournamentGroupDivider = styled(StyledLineDivider)``

export const StyledLineTournamentGroup = styled.div`
  display: flex;
  flex-direction: column;

  &:last-child ${TournamentGroupDivider} {
    display: none;
  }
`

export const StyledLineTournamentGroupSport = styled.div`
  align-items: center;
  column-gap: 8px;
  display: flex;
  grid-area: text;
  padding: 8px 16px 4px;
`

export const StyledLineTournamentGroupSportText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.default.primary[90]};
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.bold};
  `}
`
