import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectStepTwoData,
  selectMappedControlQuestions
} from 'og-core/providers/verification/selectors'
import { verificationActions } from 'og-core/providers/verification/slice'
import { EVerificationSteps } from 'og-core/providers/verification/types'

import { ETestData } from 'shared/lib/testData'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { ESelectView } from 'shared/ui/Select'

import {
  StyledFullRegistrationButton,
  StyledSlideControl
} from '../FullRegistration.styled'
import { StyledLabel } from '../Input/Input.styled'

import {
  StyledFullRegistrationHeaderExit,
  StyledFullRegistrationHeaderTitle,
  StyledFullRegistrationHeaderWrapper,
  StyledFullRegistrationSelect,
  StyledFullRegistrationWrapper,
  StyledInput,
  StyledUserDataTitle
} from './FullRegistrationStepTwo.styled'
import { SLIDE_TWO_FORMIK_SCHEMA_CONTROL_QUESTION } from './constants'
import { ControlQuestion } from './FullRegistrationStepTwo.types'

export const FullRegistrationStepTwo = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const authenticationDataStepTwo = useSelector(selectStepTwoData)
  const controlQuestions = useSelector(selectMappedControlQuestions)
  const [controlQuestion, setControlQuestions] = useState<
    ControlQuestion | undefined
  >(undefined)

  useEffect(() => {
    if (authenticationDataStepTwo.controlQuestionId) {
      const selectedQuestion = controlQuestions.find(
        (q) => q.id === authenticationDataStepTwo.controlQuestionId
      )
      setControlQuestions(selectedQuestion)
    }
  }, [authenticationDataStepTwo.controlQuestionId, controlQuestions])

  const closeAuthenticationModal = useCallback(() => {
    dispatch(
      verificationActions.changeCurrentVerificationStep({
        step: null
      })
    )
  }, [dispatch])

  const changeAuthenticationCurrentStep = useCallback(() => {
    dispatch(
      verificationActions.changeCurrentVerificationStep({
        step: EVerificationSteps.ONE
      })
    )
  }, [dispatch])

  const handleFormikSubmit = useCallback(
    (data) => {
      dispatch(verificationActions.verificationStepTwo(data))
      dispatch(verificationActions.verificateUser())
    },
    [dispatch]
  )

  const handleSelectQuestion = useCallback((selectedOption, propsFormik) => {
    setControlQuestions(selectedOption)
    propsFormik.setValues({
      ...propsFormik.values,
      controlQuestionId: selectedOption.id
    })
  }, [])

  return (
    <StyledFullRegistrationWrapper
      data-test-id={ETestData.TestFullRegistrationWrapperTwo}
    >
      <StyledFullRegistrationHeaderWrapper>
        <StyledFullRegistrationHeaderTitle>
          {t('verification')}
        </StyledFullRegistrationHeaderTitle>
        <StyledFullRegistrationHeaderExit
          colorProps={{
            name: EColorsNames.Primary,
            value: 63,
            type: EColorsTypes.CUSTOM
          }}
          size={10}
          onClick={closeAuthenticationModal}
        />
      </StyledFullRegistrationHeaderWrapper>

      <StyledUserDataTitle>{t('safety')}</StyledUserDataTitle>

      <Formik
        initialValues={authenticationDataStepTwo}
        isInitialValid={false}
        validationSchema={SLIDE_TWO_FORMIK_SCHEMA_CONTROL_QUESTION}
        onSubmit={handleFormikSubmit}
      >
        {(propsFormik) => (
          <Form>
            <StyledLabel>{t('secret question')}</StyledLabel>
            <StyledFullRegistrationSelect
              isSearchable={false}
              name="controlQuestionId"
              options={controlQuestions}
              placeholder={t('сhoose the question')}
              value={controlQuestion}
              view={ESelectView.Registration}
              onChange={(selectedOption) =>
                handleSelectQuestion(selectedOption, propsFormik)
              }
            />
            <StyledInput
              label="answer to the question"
              name="controlAnswer"
              placeholder="enter your answer"
            />

            <StyledSlideControl
              activeItem={1}
              pointCount={2}
              onChange={changeAuthenticationCurrentStep}
            />

            <StyledFullRegistrationButton
              disabled={
                !propsFormik.values.controlQuestionId.length ||
                !propsFormik.values.controlAnswer.length
              }
              testData={ETestData.TestFullRegistrationButton}
              type="submit"
            >
              {t('register')}
            </StyledFullRegistrationButton>
          </Form>
        )}
      </Formik>
    </StyledFullRegistrationWrapper>
  )
}
