import React, {
  FC,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  ESportsCounterType,
  selectSportsCountersList,
  selectSportsCountersListV2
} from 'astra-core/containers/SportCountersProvider'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import range from 'lodash/range'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'

import { ETestData } from 'shared/lib/testData'
import { SportIcon } from 'shared/ui/Icon/SportIcon'
import { RootState } from 'shared/types/store'
import { lineTournamentsContainerActions } from 'pages/page-line-tournament/LineTournamentsContainer/slice'
import { liveContainerActions } from 'pages/page-live-events/LiveContainer/slice'

import { SportsMenuListSkeleton } from '../SportsMenuListSkeleton/SportsMenuListSkeleton'

import {
  ISportsMenuListProps,
  SportsMenuItemProps
} from './SportMenuList.types.ts'
import {
  StyledSportMenuListSeparateTopLine,
  StyledSportsMenuItem,
  StyledSportsMenuItemCount,
  StyledSportsMenuItemText,
  StyledSportsMenuList
} from './SportsMenuList.styled'
import { getMenuTabSportsCounterType, isActiveSport } from './utils'

export const SportsMenuList: FC<ISportsMenuListProps> = ({
  currentTabMenu
}) => {
  const [hasDataLoadedOnce, setHasDataLoadedOnce] = useState(false)
  const { pathname } = useLocation()

  const isNewCountersEndpoint = useFeatureFlag(
    EFeatureFlags.NEW_COUNTERS_ENDPOINT
  )
  const key =
    currentTabMenu === 'live'
      ? ESportsCounterType.LIVE
      : ESportsCounterType.LINE

  const sportsCountersList = useSelector((state: RootState) =>
    isNewCountersEndpoint
      ? selectSportsCountersListV2(state, key)
      : selectSportsCountersList(
          state,
          getMenuTabSportsCounterType(currentTabMenu)
        )
  )

  useEffect(() => {
    if (sportsCountersList.length > 0) {
      setHasDataLoadedOnce(true)
    }
  }, [sportsCountersList])

  return (
    <StyledSportsMenuList data-test-id={ETestData.TestSportsMenuList}>
      {!hasDataLoadedOnce ? (
        <>
          {range(8).map((item) => (
            <SportsMenuListSkeleton key={item} />
          ))}
        </>
      ) : (
        sportsCountersList.map((sport, index) => {
          const isLastTopSport =
            sport.top &&
            !!sportsCountersList[index + 1] &&
            !sportsCountersList[index + 1].top

          return (
            <Fragment key={sport.id}>
              <SportsMenuItem
                active={isActiveSport(pathname, currentTabMenu, sport.id)}
                currentTabMenu={currentTabMenu}
                key={sport.id}
                sport={sport}
              >
                {sport.name}
              </SportsMenuItem>

              {isLastTopSport && <StyledSportMenuListSeparateTopLine />}
            </Fragment>
          )
        })
      )}
    </StyledSportsMenuList>
  )
}

const SportsMenuItem: FC<SportsMenuItemProps> = memo(
  ({ sport, currentTabMenu, active }) => {
    const dispatch = useDispatch()

    const handleClick = useCallback(() => {
      dispatch(lineTournamentsContainerActions.resetLineFilter())
      dispatch(liveContainerActions.resetLiveFilter({ isResetFilter: false }))
    }, [dispatch])

    return (
      <StyledSportsMenuItem
        $active={active}
        data-test-id={`${ETestData.TestSportsMenuItem}-${sport.id}`}
        to={`/${currentTabMenu}/${sport.id}`}
        onClick={handleClick}
      >
        <SportIcon
          isActive={sport.top}
          sportCode={sport.code as ESportsCodes}
          withBackground
        />

        <StyledSportsMenuItemText>{sport.name}</StyledSportsMenuItemText>

        <StyledSportsMenuItemCount $active={active}>
          {sport.count}
        </StyledSportsMenuItemCount>
      </StyledSportsMenuItem>
    )
  }
)
