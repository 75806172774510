import React from 'react'

import { ELayoutsList, Layout } from 'widgets/Layout'
import { getRouteParamAtIndex } from 'pages/lib/getRouteParamAtIndex'
import { ELineHeaderTypes } from 'pages/page-live-events/Line/components/ui/LineHeader/LineHeader.types'
import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'

import {
  lineTournamentsContainerActions,
  reducerLine,
  sliceKeyLine
} from './LineTournamentsContainer/slice'
import { lineTournamentsContainerSaga } from './LineTournamentsContainer/saga'
import { LineTournamentsContainer } from './LineTournamentsContainer'
import { getFetchLineTournamentsReqData } from './LineTournamentsContainer/utils'

export const PageLineTournament = () => {
  useInjectReducer({ key: sliceKeyLine, reducer: reducerLine })
  useInjectSaga({ key: sliceKeyLine, saga: lineTournamentsContainerSaga })

  return (
    <Layout type={ELayoutsList.SPORT} typeHeader={ELineHeaderTypes.TOURNAMENTS}>
      <LineTournamentsContainer />
    </Layout>
  )
}

export const pageLineTournamentPrefetch = ({ store, req }) => {
  store.dispatch(
    lineTournamentsContainerActions.fetchLineTournaments(
      getFetchLineTournamentsReqData({
        sportId: getRouteParamAtIndex(req.params, 1)
      })
    )
  )
}
