import styled, { css } from 'styled-components'

import { StyledRadioLabel } from 'shared/ui/Radio/styled'
import { StyledButton } from 'shared/ui/Button'

export const QuickAmountSettingWrapper = styled.div`
  ${StyledRadioLabel} {
    ${(props) => css`
      font-size: ${props.theme.fonts.size.s};
      font-weight: ${props.theme.fonts.weight.semiBold};
    `}
  }

  & > * {
    width: 100%;
  }

  align-items: start;

  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

export const QuickAmountSettingDescription = styled.div`
  ${(props) => css`
    color: ${props.theme.colors.custom.primary[17]};
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.regular};
  `}
`

export const QuickAmountSettingItem = styled.div`
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  gap: 4px;
  padding: 4px;

  ${(props) => css`
    background-color: ${props.theme.colors.custom.primary[4]};
    border: 1px solid ${props.theme.colors.custom.primary[45]};
    color: ${props.theme.colors.custom.primary[7]};
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.normal};

    &:hover {
      background-color: ${props.theme.colors.custom.primary[45]};
    }
  `}
`

export const QuickAmountSettingItemWrapper = styled.div`
  display: flex;
  gap: 4px;
`

export const QuickAmountInput = styled.div<{
  error: string | null
}>`
  ${({ theme, error }) => css`
    align-items: center;
    background-color: ${theme.colors.custom.primary[4]};
    border: 1px solid
      ${error ? theme.colors.fixed.red[2] : theme.colors.default.primary[20]};
    border-radius: 4px;

    &:hover {
      background-color: ${theme.colors.custom.primary[2]};
      border-color: ${theme.colors.custom.primary[14]};
    }

    &:focus {
      background-color: ${theme.colors.custom.primary[2]};
      border-color: ${error
        ? theme.colors.fixed.red[2]
        : theme.colors.default.primary[90]};
    }

    cursor: pointer;
    display: flex;
    font-size: 16px;
    justify-content: end;
    overflow: hidden;
    padding: 7px 8px;
    position: relative;
    width: 100%;

    &:disabled {
      border: 1px solid ${theme.colors.default.primary[5]};

      &::placeholder {
        color: ${theme.colors.custom.primary[34]};
      }
    }

    &:focus-within {
      border: 1px solid
        ${error ? theme.colors.fixed.red[2] : theme.colors.default.primary[90]};
    }
  `}
`

export const QuickAmountSettingInput = styled.div`
  ${StyledButton} {
    align-items: center;
    justify-content: center;
    min-width: 82px;
  }
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  max-width: 240px;
`

export const QuickAmountSettingInputWrapper = styled.div``

export const QuickAmountInputMessage = styled.div<{ isError: boolean }>`
  ${(props) => css`
    color: ${props.isError
      ? props.theme.colors.default.red[50]
      : props.theme.colors.custom.primary[17]};
    font-size: ${props.theme.fonts.size.xxs};
    font-weight: ${props.theme.fonts.weight.normal};
    line-height: 16px;
  `}
`
