import { useTranslation } from 'react-i18next'
import React, { FC, useCallback, useState } from 'react'

import { useCarouselScroll } from 'hooks/useCarouselScroll'
import { IconChevronLeft } from 'shared/ui/Icon/General/IconChevronLeft'
import { IconChevronRight } from 'shared/ui/Icon/General/IconChevronRight'

import {
  StyledGenericSportsList,
  StyledCarousel,
  StyledGenericSportsListControlLeft,
  StyledGenericSportsListControlRight
} from './GenericSportList.styled'
import { GenericSportsListProps } from './GenericSportList.types'
import GenericSportListItem from './item'

export const GenericSportsList: FC<GenericSportsListProps> = ({
  parentSport,
  childrenSports,
  onClickItem,
  activeSportId
}) => {
  const [t] = useTranslation()
  const { carouselApi } = useCarouselScroll(childrenSports)
  const scrollToNext = useCallback(() => {
    carouselApi.current?.scrollToNext()
  }, [carouselApi])

  const [isScrollable, setIsScrollable] = useState(false)

  const scrollToPrevious = useCallback(() => {
    carouselApi.current?.scrollToPrevious()
  }, [carouselApi])

  const controlAttributes = {
    iconProps: {
      size: 16
    }
  }

  return (
    <StyledGenericSportsList>
      <StyledCarousel
        onCarouselMounted={(api) => {
          carouselApi.current = api
          setIsScrollable(carouselApi.current.isScrollable)
        }}
      >
        <GenericSportListItem
          customLabel={t('all games')}
          isActive={!activeSportId}
          sport={parentSport}
          onClickItem={() => onClickItem()}
        />
        {childrenSports.map((sport) => (
          <GenericSportListItem
            isActive={activeSportId === sport.id}
            key={sport.id}
            sport={sport}
            onClickItem={() => onClickItem(sport.id)}
          />
        ))}
      </StyledCarousel>
      {isScrollable && (
        <>
          <StyledGenericSportsListControlLeft
            {...controlAttributes}
            icon={IconChevronLeft}
            onClick={scrollToPrevious}
          />
          <StyledGenericSportsListControlRight
            {...controlAttributes}
            icon={IconChevronRight}
            onClick={scrollToNext}
          />
        </>
      )}
    </StyledGenericSportsList>
  )
}
