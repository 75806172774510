import React, { FC, memo, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  adjustAmountForBackend,
  adjustAmountForFrontend,
  formatAmountForBackend,
  formatValue
} from 'astra-core'
import { QuickAmountsType } from 'betweb-openapi-axios'
import { useUpdateQuickAmount } from 'astra-core/containers/SettingsProvider/useUpdateQuickAmount'

import { ETestData } from 'shared/lib/testData'
import { CurrencyDynamicInput } from 'shared/ui/DynamicInput'
import { EColorsNames } from 'shared/types/theme'
import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { Button, EButtonViews } from 'shared/ui/Button'
import { Radio } from 'shared/ui/Radio'

import {
  StyledBetCurrency,
  StyledBetInputWrapper
} from '../../../BasketCommon/components/BasketInput/BasketInput.styled'

import * as S from './QuickAmountSetting.styled'
import { QuickAmountSettingProps } from './QuickAmountSetting.types'

export const QuickAmountSetting: FC<QuickAmountSettingProps> = memo((props) => {
  const {
    description,
    type,
    title,
    settings,
    minValue,
    maxValue,
    currencyIcon,
    validationMessages,
    inputConfig
  } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const [messageVisible, setMessageVisible] = useState(false)
  const [amount, setAmount] = useState('')
  const { quickAmountsType } = settings

  const amountValues = useMemo(() => {
    if (type === QuickAmountsType.FixedAmounts) {
      return settings?.fixedAmounts?.map((item) =>
        adjustAmountForFrontend(item)
      )
    }

    return settings.balancePercents
  }, [settings.balancePercents, settings?.fixedAmounts, type])

  const { removeAmount, setAmountType, addAmount, error } =
    useUpdateQuickAmount({
      type,
      settings,
      minValue,
      maxValue
    })

  const handleChangeInput = (value: string) => {
    setMessageVisible(false)
    setAmount(value)
  }

  const focusOnInput = useCallback(() => {
    inputRef.current?.focus()
  }, [])

  const checked = quickAmountsType === type

  const onAddItem = () => {
    if (!amount) {
      setMessageVisible(true)
      return
    }

    const formatedAmount = formatAmountForBackend(amount)
    const value =
      type === QuickAmountsType.FixedAmounts
        ? adjustAmountForBackend(formatedAmount)
        : +formatedAmount
    addAmount(value)
  }

  return (
    <S.QuickAmountSettingWrapper>
      <Radio
        checked={checked}
        label={t(title)}
        value={type}
        onChange={setAmountType}
      />

      <S.QuickAmountSettingDescription>
        {t(description)}
      </S.QuickAmountSettingDescription>
      <S.QuickAmountSettingItemWrapper>
        {amountValues?.map((value, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <S.QuickAmountSettingItem key={`${value} - ${index}`}>
            {formatValue(value ?? '')} {currencyIcon}
            <IconClose
              colorProps={{ name: EColorsNames.Primary, value: 30 }}
              size={10}
              onClick={() => removeAmount(index)}
            />
          </S.QuickAmountSettingItem>
        ))}
      </S.QuickAmountSettingItemWrapper>
      <S.QuickAmountSettingInputWrapper>
        <S.QuickAmountSettingInput>
          <S.QuickAmountInput
            data-test-id={ETestData.TestQuickBetInput}
            error={error}
            onClick={focusOnInput}
          >
            <StyledBetInputWrapper>
              <CurrencyDynamicInput
                inputConfig={inputConfig}
                inputValue={String(amount)}
                placeholder=" "
                ref={inputRef}
                onAmoutChange={handleChangeInput}
              />
              <StyledBetCurrency>{currencyIcon}</StyledBetCurrency>
            </StyledBetInputWrapper>
          </S.QuickAmountInput>
          <Button view={EButtonViews.SECONDARY} onClick={onAddItem}>
            {t('add')}
          </Button>
        </S.QuickAmountSettingInput>
        {error ? (
          <S.QuickAmountInputMessage isError>
            {validationMessages[error]}
          </S.QuickAmountInputMessage>
        ) : (
          <S.QuickAmountInputMessage isError={false}>
            {messageVisible
              ? t('enter value')
              : `${t('from to', {
                  from: minValue,
                  to: formatValue(maxValue)
                })} ${currencyIcon}`}
          </S.QuickAmountInputMessage>
        )}
      </S.QuickAmountSettingInputWrapper>
    </S.QuickAmountSettingWrapper>
  )
})
