import React, { forwardRef, memo, useMemo } from 'react'
import isEqual from 'react-fast-compare'

import { Market } from '../Market'

import { CategoryProps, PanelHandle } from './Category.types'
import { StyledTableRadius } from './Category.styled'
import { CategoryPanel } from './CategoryPanel'

const MERGED_TEMPLATES = ['G2Template']

export const Category = memo(
  forwardRef<PanelHandle, CategoryProps>(
    ({ eventId, category }, forwardedRef) => {
      const { virtualMarkets: markets, categoryName } = category

      const Markets = useMemo(
        () =>
          markets.map((market, index, array) => (
            <Market
              isMerged={
                index > 0 &&
                MERGED_TEMPLATES.includes(
                  market.outcomesGroup.renderTemplateId
                ) &&
                MERGED_TEMPLATES.includes(
                  array[index - 1].outcomesGroup.renderTemplateId
                )
              }
              eventId={eventId}
              isFirst={index === 0}
              isLast={index === array.length - 1}
              key={market.id}
              market={market}
            />
          )),
        [markets, eventId]
      )

      return (
        <CategoryPanel ref={forwardedRef} title={categoryName}>
          <StyledTableRadius>{Markets}</StyledTableRadius>
        </CategoryPanel>
      )
    }
  ),
  isEqual
)

Category.displayName = 'Category'
