import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider/slice'
import { selectFavouriteEventsFetchItemsData } from 'astra-core/containers/FavouritesProvider/selectors'

import { useInterval } from 'hooks'

const FAVOURITE_POLLING_INTERVAL = 5000

export const useFavouriteContainerData = () => {
  const dispatch = useDispatch()
  const { loading } = useSelector(selectFavouriteEventsFetchItemsData)

  const fetchFavourites = useCallback(() => {
    dispatch(
      eventsProviderActions.fetchFavouriteEvents({ withLinkedEvents: true })
    )
  }, [dispatch])

  const intervalDelay = loading ? null : FAVOURITE_POLLING_INTERVAL

  useInterval(fetchFavourites, intervalDelay, true)
}
