import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Tabs, TabsItem } from 'shared/ui/Tabs'
import { ERoutes } from 'shared/types/routes'
import { ETestData } from 'shared/lib/testData'

import { SectionSelectProps } from './SectionSelect.types'
import { TABS } from './constants'

export const SectionSelect: FC<SectionSelectProps> = ({
  onChange,
  current
}) => {
  const [t] = useTranslation()

  return (
    <Tabs variant={5}>
      {TABS.map((tab) => (
        <TabsItem
          active={`${ERoutes.ProfileBalance}${tab.name}` === current}
          handleTabChange={() => onChange(tab.name)}
          key={tab.title}
          testData={`${ETestData.TestProfileBalanceTab}-${tab.name}`}
        >
          {t(tab.title)}
        </TabsItem>
      ))}
    </Tabs>
  )
}
