import { Client } from 'betweb-openapi-axios'

import { IProfileSectionData } from './components/Section/Section.types'

export const getSectionNameData = (data: Client): IProfileSectionData[] => [
  { title: 'first name', value: data.firstName || 'have not' },
  {
    title: 'second name',
    value: data.secondName || 'have not'
  },
  {
    title: 'middle name',
    value: data.middleName
  }
]

export const getSectionPersonalData = (data: Client): IProfileSectionData[] => [
  { title: 'login', value: data.login },
  {
    title: 'phone',
    value: data.phone || ''
  }
]
