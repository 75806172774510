import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  balanceProviderActions,
  selectCurrentWithdrawalMethod,
  selectPaymentAccountsById,
  selectWithdrawalMethods,
  selectWithdrawalMethodsFetchItemsLoading
} from 'astra-core/containers/BalanceProvider'
import { selectIsUserWithdrawalBlocked } from 'astra-core/containers/AccountProvider'
import { MethodParameterType } from 'betweb-openapi-axios'
import { useCheckActiveWithdrawalParam } from 'astra-core'
import { selectVerifiedWithdrawalMethodsByGroupId } from 'og-web-core/providers/balance/selectors'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { Notification } from 'widgets/notification'
import {
  ModalFullRegistration,
  ModalSmsConfirmation,
  ModalWithdrawal,
  NotificationAbleWithdrawal
} from 'pages/page-balance/Balance/components/Withdrawal/components'
import { ETestData } from 'shared/lib/testData'

import {
  LoadingIndicatorContainer,
  StyledFinanceContent,
  StyledFinanceTitle,
  StyledFinanceWithdrawal,
  StyledFinanceWrapper
} from '../../Balance.styled'
import { CardInput } from '../CardInput'
import {
  BalancePageNotification,
  EBalanceNotification
} from '../BalancePageNotification'
import { WithdrawalForm } from '../Form'
import { Menu } from '../Menu'
import { BankCardsMethod } from '../BankCardsMethod'
import { EmptyPaymentList } from '../Payment/components/EmptyPaymentList'

export const Withdrawal = () => {
  const dispatch = useDispatch()
  const methods = useSelector(selectWithdrawalMethods)
  const withdrawalMethodsByGroupId = useSelector(
    selectVerifiedWithdrawalMethodsByGroupId
  )
  const currentWithdrawal = useSelector(selectCurrentWithdrawalMethod)

  const isUserWithdrawalBlocked = useSelector(selectIsUserWithdrawalBlocked)
  const isLoadingWithdrawMethods = useSelector(
    selectWithdrawalMethodsFetchItemsLoading
  )

  const withdrawalMethodsVisible = withdrawalMethodsByGroupId.length > 0

  const paymentAccounts = useSelector(selectPaymentAccountsById)

  const handleChangeMethod = useCallback(
    (id) => {
      dispatch(balanceProviderActions.handleChangeWithdrawalMethod(id))
    },
    [dispatch]
  )

  const isActiveParamAccountKey = useCheckActiveWithdrawalParam(
    MethodParameterType.AccountKey
  )
  const isActiveParamCardNumber = useCheckActiveWithdrawalParam(
    MethodParameterType.CardNumber
  )
  if (isUserWithdrawalBlocked) {
    return (
      <BalancePageNotification type={EBalanceNotification.ACCOUNT_BLOCKED} />
    )
  }

  const isDisplayWithdrawInfo =
    paymentAccounts && !!paymentAccounts.length && isActiveParamAccountKey

  return (
    <StyledFinanceWithdrawal>
      <NotificationAbleWithdrawal />

      <ModalFullRegistration />
      <ModalWithdrawal />
      <ModalSmsConfirmation />

      {isLoadingWithdrawMethods && (
        <LoadingIndicatorContainer>
          <LoaderSpinner />
        </LoadingIndicatorContainer>
      )}

      {withdrawalMethodsVisible ? (
        <>
          <StyledFinanceWrapper
            data-test-id={ETestData.TestProfileBalanceWithdrawal}
          >
            <Menu
              // @ts-ignore
              groups={withdrawalMethodsByGroupId}
              selectedId={methods.selectedId}
              onChange={handleChangeMethod}
            />

            <StyledFinanceContent
              data-test-id={ETestData.TestProfileBalanceWithdrawalFinance}
            >
              <StyledFinanceTitle>{currentWithdrawal?.name}</StyledFinanceTitle>

              {isDisplayWithdrawInfo && <Notification text="withdraw info" />}

              {isActiveParamCardNumber && <BankCardsMethod />}

              <CardInput />

              <WithdrawalForm />
            </StyledFinanceContent>
          </StyledFinanceWrapper>
        </>
      ) : (
        <EmptyPaymentList text="there are no available withdrawal" />
      )}
      {/* TODO use it when backend will be ready and fix dark theme */}
    </StyledFinanceWithdrawal>
  )
}
