import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DefaultCountersReq } from 'astra-core/containers/SportCountersProvider/constants'
import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider/slice'
import { ESportsCounterType } from 'astra-core/containers/SportCountersProvider/types'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { resultsProviderActions } from 'astra-core/containers/ResultsProvider/slice'

import { ResultsMenuList } from './components'
import {
  StyledResultsMenuTitle,
  StyledSportsMenu,
  StyledTabsWrapper,
  StyledWrapper
} from './ResultsMenu.styled'

export const ResultsMenu = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const isNewCountersEndpoint = useFeatureFlag(
    EFeatureFlags.NEW_COUNTERS_ENDPOINT
  )

  useEffect(() => {
    if (isNewCountersEndpoint) {
      const reqData = DefaultCountersReq.RESULTS

      dispatch(
        sportCountersProviderActions.fetchEventResultCounters({
          key: ESportsCounterType.RESULTS,
          // @ts-ignore
          reqData
        })
      )
    } else {
      dispatch(resultsProviderActions.fetchResultCounters({}))
    }
  }, [dispatch, isNewCountersEndpoint])

  return (
    <StyledSportsMenu>
      <StyledWrapper>
        <StyledTabsWrapper>
          <StyledResultsMenuTitle>{t('kind of sport')}</StyledResultsMenuTitle>
        </StyledTabsWrapper>
        <ResultsMenuList />
      </StyledWrapper>
    </StyledSportsMenu>
  )
}
