import { useContext } from 'react'
import { useEventTimeTimer } from 'astra-core'

import { getEventStatisticsByCode } from 'shared/lib/statistics'
import { EEventStatisticsCodes } from 'widgets/event-statistics'
import {
  EventContext,
  IEventContext
} from 'pages/page-event/Event/Event.context'

export const useEventTimer = () => {
  const { event } = useContext(EventContext) as IEventContext

  const currentTime = getEventStatisticsByCode(
    event,
    EEventStatisticsCodes.CurrentTime
  )

  const remainingTime = getEventStatisticsByCode(
    event,
    EEventStatisticsCodes.RemainingTime
  )

  return useEventTimeTimer({ currentTime, remainingTime })
}
