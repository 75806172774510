import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { broadcastProviderActions } from 'astra-core/containers/BroadcastProvider'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { ENV, getEnv } from 'astra-core/utils/env'

import { useInterval, usePrevious } from 'hooks'
import { useAppSelector } from 'app/lib/store'

import {
  selectFilterIsTop,
  selectLayoutIsLoading,
  getLiveEventsIdsBySport
} from './selectors'
import {
  BROADCAST_CHUNK_SIZE,
  BROADCAST_POLLING_INTERVAL,
  getFetchLiveReqData,
  LIVE_POLLING_INTERVAL
} from './utils'
import { LiveParams } from './types'
import { liveContainerActions } from './slice'

export const useLiveContainerData = () => {
  const { sportId } = useParams<LiveParams>()
  const dispatch = useDispatch()
  const top = useSelector(selectFilterIsTop)
  const isLayoutLoading = useSelector(selectLayoutIsLoading)
  const sportIdPrev = usePrevious(sportId)
  const topPrev = usePrevious(top)

  const fetchLive = useCallback(() => {
    if (sportId) {
      dispatch(
        liveContainerActions.fetchLive(getFetchLiveReqData({ sportId, top }))
      )
    }
  }, [dispatch, sportId, top])

  const eventIds = useAppSelector((state) =>
    getLiveEventsIdsBySport(state, +sportId)
  )

  const isBroadcastFeatureFlagEnabled = useFeatureFlag(
    EFeatureFlags.BROADCAST_ENABLED
  )
  const env = getEnv()
  const isBroadcastsEnabled =
    isBroadcastFeatureFlagEnabled && [ENV.STAGE, ENV.PROD].includes(env)
  const fetchBroadcastsAvailability = useCallback(() => {
    if (isBroadcastsEnabled && eventIds.length > 0) {
      for (let i = 0; i < eventIds.length; i += BROADCAST_CHUNK_SIZE) {
        dispatch(
          broadcastProviderActions.fetchBroadcastsAvailability({
            reqData: {
              eventIds: eventIds.slice(i, i + BROADCAST_CHUNK_SIZE),
              platform: 'd_web'
            }
          })
        )
      }
    }
  }, [dispatch, eventIds, isBroadcastsEnabled])

  useEffect(() => {
    fetchBroadcastsAvailability()
  }, [fetchBroadcastsAvailability])

  useInterval(fetchBroadcastsAvailability, BROADCAST_POLLING_INTERVAL, false)

  useEffect(() => {
    /* To handle fetch on toggle TOP */

    if (top !== topPrev) {
      fetchLive()
    }
  }, [fetchLive, top, topPrev])

  useEffect(() => {
    /* To handle fetch on sport id change */

    if (!!sportIdPrev && sportIdPrev !== sportId) {
      fetchLive()
    }
  }, [sportIdPrev, sportId, fetchLive, top, topPrev])

  const intervalDelay = isLayoutLoading ? null : LIVE_POLLING_INTERVAL

  useInterval(fetchLive, intervalDelay, true)
}

export const useLiveData = ({ sportId }) => {
  const dispatch = useDispatch()

  const fetchLive = useCallback(() => {
    if (sportId) {
      dispatch(liveContainerActions.fetchLive(getFetchLiveReqData({ sportId })))
    }
  }, [dispatch, sportId])

  useInterval(fetchLive, LIVE_POLLING_INTERVAL, true)
}
