import React, { useContext } from 'react'
import { useMainPageFetchData as ogCoreMainPageFetchData } from 'og-core/providers/main-page/useMainPageFetchData'
import loadable from '@loadable/component'
import { BasketQuickBetContext } from 'og-web-core/providers/basket/quick-bet'
import { useSelector } from 'react-redux'
import { selectAccount } from 'astra-core/containers/AccountProvider/selectors'

import { EMainPageEventsList } from 'pages/page-main/Main/components/MainPageEvents/MainPageEvents.types'
import { BasketButtonSmallWindow } from 'widgets/right-container/Basket/BasketButtonSmallWindow'
import { BasketQuickBetButton } from 'widgets/right-container/Basket/BasketQuickBetButton/BasketQuickBetButton'

import { MainPageEvents, OnboardingNotification, Search } from './components'
import {
  StyledEventsSectionWrapper,
  StyledMainSearchBlockWrapper,
  StyledMainWrapper
} from './Main.styled'

const Banners = loadable(() => import('./components/Banners'), {
  resolveComponent: (c) => c.Banners,
  fallback: <></>
})

const Sponsors = loadable(() => import('./components/Sponsors'), {
  resolveComponent: (c) => c.Sponsors,
  fallback: <></>
})

export const Main = () => {
  ogCoreMainPageFetchData()

  return <MainPageContent />
}

const MainPageContent = () => {
  const { isQuickBetActive } = useContext(BasketQuickBetContext)
  const account = useSelector(selectAccount)

  const isQuickBetButtonVisible = isQuickBetActive && account

  return (
    <StyledMainWrapper>
      <StyledMainSearchBlockWrapper>
        <Search isMainPage />

        {isQuickBetButtonVisible ? (
          <BasketQuickBetButton />
        ) : (
          <BasketButtonSmallWindow />
        )}
      </StyledMainSearchBlockWrapper>

      <OnboardingNotification />

      <Banners />

      <Sponsors />

      <StyledEventsSectionWrapper>
        <MainPageEvents mainPageEventsListType={EMainPageEventsList.TOP_LIVE} />
        <MainPageEvents mainPageEventsListType={EMainPageEventsList.TOP_LINE} />
        <MainPageEvents mainPageEventsListType={EMainPageEventsList.UPCOMING} />
      </StyledEventsSectionWrapper>
    </StyledMainWrapper>
  )
}
