import React, { FC, memo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { VirtualGameVendor } from 'betweb-openapi-axios'
import { logger } from 'astra-core/utils/logger'
import { useTurboGamesData } from 'og-core/providers/virtual-games/hooks'

import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { StyledLoaderPointsWrapper } from 'pages/page-games-turbo/TurboGames/components/TurboGamesGame/TurboGamesGame.styled'
import { ServiceAvailability } from 'shared/ui/ServiceAvailability'

export const TurboGamesGame: FC = () => {
  logger('TurboGamesGameNew')

  const { gameNameId } = useParams<{ gameNameId: string }>()
  const { src, loaded, resetToken } = useTurboGamesData({ gameNameId })

  useEffect(() => {
    return () => {
      resetToken()
    }
  }, [resetToken])

  if (!loaded) {
    return (
      <StyledLoaderPointsWrapper>
        <LoaderPoints />
      </StyledLoaderPointsWrapper>
    )
  }

  return (
    <ServiceAvailability vendor={VirtualGameVendor.TurboGames}>
      <TurboGamesIframe src={src} />
    </ServiceAvailability>
  )
}

const TurboGamesIframe = memo(({ src }: { src: string }) => {
  logger({ TurboGamesIframe: src })

  return (
    <iframe
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
        outline: 'none'
      }}
      src={src}
      title="turbo-games-iframe"
    />
  )
})
