import groupBy from 'lodash/groupBy'
import React, { FC } from 'react'

import { ETestData } from 'shared/lib/testData'

import { LineGroupedTournamentsProps } from './TournamentGroup.types'
import {
  StyledGroupedLine,
  StyledLineTable,
  StyledLineTableTop,
  StyledTopDivider
} from './TournamentGroup.styled'
import { LineTournament } from './components'

export const LineTournamentsGroup: FC<LineGroupedTournamentsProps> = ({
  tournamentsGroup
}) => {
  const tournaments = groupBy(tournamentsGroup.tournaments, 'top')

  const topTournaments = tournaments.true || []
  const hasTopTournaments = topTournaments.length > 0
  const otherTournaments = tournaments.false || []

  return (
    <StyledGroupedLine>
      <StyledLineTable data-test-id={ETestData.TestLineTable}>
        {hasTopTournaments && (
          <StyledLineTableTop>
            {topTournaments.map((tournament) => (
              <LineTournament
                key={tournament.id}
                testData={`${ETestData.TestLineTopTournament}-${tournament.id}`}
                tournament={tournament}
              />
            ))}
            <StyledTopDivider />
          </StyledLineTableTop>
        )}

        {otherTournaments.map((tournament) => (
          <LineTournament
            key={tournament.id}
            testData={`${ETestData.TestLineTournament}-${tournament.id}`}
            tournament={tournament}
          />
        ))}
      </StyledLineTable>
    </StyledGroupedLine>
  )
}
