import styled, { css } from 'styled-components'
import { ColumnType } from 'astrabet-templates-kit'

import { EColorsNames } from 'shared/types/theme'

import {
  StyledTableCellProps,
  StyledHeaderCellProps
} from './OutcomesTable.types'

interface OutcomesTableProps {
  columns: ColumnType[]
  isPlain?: boolean
  labelInButton?: boolean
  isFirst?: boolean
  isLast?: boolean
}

const columnTypeToWidth: Partial<Record<ColumnType, '1fr' | '68px'>> = {
  label: '1fr',
  odd: '68px'
}

export const StyledTableWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`

export const StyledOutcomesTableHeader = styled.div<OutcomesTableProps>`
  display: grid;

  ${({ columns, labelInButton }) =>
    css`
      ${labelInButton &&
      css`
        margin-bottom: -8px;
      `}
      grid-template-columns: ${columns
        .map((c, index) => (index === 0 ? '1fr' : '68px'))
        .join(' ')};
    `}
`
export const StyledOutcomesTable = styled.div<OutcomesTableProps>`
  ${(p) =>
    !p.isPlain &&
    css`
      display: grid;
      ${p.labelInButton
        ? css`
            gap: 8px;
            grid-template-columns: ${p.columns
              .map((c) => (c === 'odd' ? '1fr' : ''))
              .join(' ')};
            padding: 8px;
          `
        : css`
            grid-template-columns: ${p.columns
              .map((c) => columnTypeToWidth[c])
              .join(' ')};
          `}
    `}
`

export const CoefficientTableCellStyled = styled.div<StyledTableCellProps>`
  align-items: center;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 12px;
  overflow: hidden;
  transition: background-color 0.5s ease;

  ${({ isPlain, hideBorder, hideBorderBottom, labelInButton }) =>
    !isPlain &&
    css`
      border-color: ${(props) =>
        props.theme.colors.custom[EColorsNames.Primary][4]};
      border-style: solid;
      border-width: 1px 0;
      margin: -1px 0 0 -1px;
      ${hideBorderBottom &&
      css`
        border-bottom: none;
      `}
      ${labelInButton || hideBorder
        ? css`
            border: none;
          `
        : ''}
    `}
  ${({ isOdd }) =>
    isOdd &&
    css`
      justify-content: center;
    `}
    
  ${({ labelInButton }) =>
    !labelInButton &&
    css`
      padding: 8px 0;
    `}
`

export const StyledTextCell = styled.div`
  color: ${(props) => props.theme.colors.fixed.primary[50]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 16px;
  padding: 0.5rem;
`

export const StyledPlainHeaderCell = styled.div`
  font-weight: 500;
  padding: 0;
`

export const StyledHeaderCell = styled.div<StyledHeaderCellProps>`
  color: ${(p) => p.theme.colors.default[EColorsNames.Primary][90]};
  font-family: Inter, sans-serif;
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  letter-spacing: 0;
  line-height: 16px;
  padding: 0.25rem 0.5rem;

  ${({ isFirstInRow }) =>
    !isFirstInRow &&
    css`
      color: ${(props) => props.theme.colors.fixed[EColorsNames.Primary][50]};
      display: flex;
      font-weight: ${(props) => props.theme.fonts.weight.normal};
      justify-content: center;
    `}

  span {
    background-color: ${({ theme }) => theme.colors.default.yellow[30]};
    color: ${(props) => props.theme.colors.fixed.primary[90]};
  }

  ${(p) =>
    p.columns &&
    css`
      grid-column: 1 / ${p.columns + 1};
    `};
`

export const StyledTablePlainRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 16px;
`

export const StyledPlainWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 16px;
`

export const StyledOddsDivider = styled.div`
  background-color: ${({ theme }) => theme.colors.custom.primary[36]};
`

export const StyledOddsDividerCenter = styled.div`
  background-color: ${({ theme }) => theme.colors.custom.primary[36]};
  height: 8px;
`

export const StyledOddsDividerTop = styled.div`
  background-color: ${({ theme }) => theme.colors.default.primary[0]};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 8px;
  margin-top: -8px;
`

export const StyledOddsDividerBottom = styled.div`
  background-color: ${({ theme }) => theme.colors.default.primary[0]};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 8px;
  margin-bottom: -8px;
`
