import React, { FC, memo } from 'react'
import { VirtualGameVendor } from 'betweb-openapi-axios'
import { useSportGamesData } from 'astra-core/containers/VirtualGamesProvider/hooks'

import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { ServiceAvailability } from 'shared/ui/ServiceAvailability'

import { StyledLoaderPointsWrapper } from './SportGames.styled'

export const SportGames: FC = () => {
  const { loaded, src } = useSportGamesData()

  if (!loaded) {
    return (
      <StyledLoaderPointsWrapper>
        <LoaderPoints />
      </StyledLoaderPointsWrapper>
    )
  }

  return (
    <ServiceAvailability vendor={VirtualGameVendor.SportGames}>
      <SportGamesIframe src={src} />
    </ServiceAvailability>
  )
}

const SportGamesIframe = memo(({ src }: { src: string }) => {
  return (
    <iframe
      style={{
        width: '100%',
        height: '100vh',
        border: 'none',
        outline: 'none',
        font: 'inherit',
        verticalAlign: 'baseline'
      }}
      src={src}
      title="sportgames-iframe"
    />
  )
})
