import React from 'react'
import { useParams } from 'react-router-dom'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider/slice'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { getRouteParamAtIndex } from 'pages/lib/getRouteParamAtIndex'
import { useAppSelector } from 'app/lib/store'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { eventContainerActions } from 'containers/EventContainer/slice'
import { getFetchEventReqData } from 'containers/EventContainer/utils'
import { useEventContainer } from 'containers/EventContainer/useEventContainer'
import { EventParams } from 'containers/EventContainer/types'
import { selectEventEnriched } from 'containers/EventContainer/selectors'

import { Event } from './Event/Event'

export const PageEvent = () => {
  useEventContainer()

  const { eventId } = useParams<EventParams>()
  const { event, isEmpty } = useAppSelector((state) =>
    selectEventEnriched(state, { eventId: +eventId })
  )
  return (
    <Layout type={ELayoutsList.EVENT}>
      {isEmpty ? <LoaderSpinner /> : <Event event={event!} />}
    </Layout>
  )
}

export const pageLiveEventPrefetch = ({ store, req }) => {
  const eventId = getRouteParamAtIndex(req.params, 2)

  if (eventId) {
    store.dispatch(
      eventContainerActions.fetchEvent(
        getFetchEventReqData({ eventId: getRouteParamAtIndex(req.params, 2) })
      )
    )
    store.dispatch(
      eventsProviderActions.fetchEventStatistics(
        getFetchEventReqData({ eventId: getRouteParamAtIndex(req.params, 2) })
      )
    )
  }
}

export const pageLineEventPrefetch = ({ store, req }) => {
  const eventId = getRouteParamAtIndex(req.params, 3)
  if (eventId) {
    store.dispatch(
      eventContainerActions.fetchEvent(
        getFetchEventReqData({ eventId: getRouteParamAtIndex(req.params, 3) })
      )
    )
  }
}
