import { ProbabilityWithOutcome } from 'astrabet-templates-kit'
import { EventProbability } from 'betweb-openapi-axios'
import sortBy from 'lodash/sortBy'

const getOutcomeId = ({ outcomeTypeId, parameters = [] }: EventProbability) =>
  `${outcomeTypeId}-${sortBy(parameters, ['type'])
    .map((p) => p.value)
    .join('-')}`

export const getCellKey = (
  cellValue: string | ProbabilityWithOutcome,
  index: number
) => {
  if (typeof cellValue === 'string') return cellValue
  if (cellValue) return getOutcomeId(cellValue)
  return index
}

export const getRowKey = (
  row: (string | ProbabilityWithOutcome)[],
  rowIndex: number
) => {
  return row.map((item) => getCellKey(item, rowIndex)).join('-')
}
