import React, { forwardRef, memo } from 'react'
import { useSelector } from 'react-redux'
import isEqual from 'react-fast-compare'
import {
  CategoryMarketInfoTradingStatus,
  selectMarketCategoriesInfosByEventFiltered
} from 'astra-core/containers/EventsProvider'
import { selectInputEventsSearch } from 'astra-core/containers/SearchProvider/selectors'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'

import { RootState } from 'shared/types/store'
import { useIsTablet } from 'shared/lib/styled'
import { getSelectedOutcomesGroupId } from 'containers/OutcomesContainer/selectors'
import { ClearResultSearch } from 'pages/page-live-events/Line/components/ui'

import { OutcomeCategoriesHandle, CategoriesProps } from './Categories.types'
import { StyledClearResultSearch } from './Categories.styled'
import { CategoriesMasonry } from './CategoriesMasonry'

const DESKTOP_COLUMNS_COUNT = 2
const TABLET_COLUMNS_COUNT = 1

export const Categories = memo(
  forwardRef<OutcomeCategoriesHandle, CategoriesProps>(
    ({ eventId }, forwardedRef) => {
      const isShowAllEventProbs = useFeatureFlag(
        EFeatureFlags.SHOW_ALL_EVENT_PROBS
      )

      const isTablet = useIsTablet()

      const valueSearch =
        useSelector((state: RootState) =>
          selectInputEventsSearch(state, eventId)
        ) || ''

      const selectedMarketGroupId = useSelector((state: RootState) =>
        getSelectedOutcomesGroupId(state, eventId)
      )
      const categories = useSelector((state: RootState) =>
        selectMarketCategoriesInfosByEventFiltered(
          state,
          eventId,
          selectedMarketGroupId,
          valueSearch,
          isShowAllEventProbs
            ? undefined
            : CategoryMarketInfoTradingStatus.Trading
        )
      )

      if (categories.length === 0) {
        if (valueSearch !== '') {
          return (
            <StyledClearResultSearch>
              <ClearResultSearch />
            </StyledClearResultSearch>
          )
        }
        return null
      }

      return (
        <CategoriesMasonry
          categories={categories}
          columnsCount={isTablet ? TABLET_COLUMNS_COUNT : DESKTOP_COLUMNS_COUNT}
          eventId={eventId}
          ref={forwardedRef}
        />
      )
    }
  ),
  isEqual
)

Categories.displayName = 'Categories'
