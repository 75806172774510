import React from 'react'
import { useSelector } from 'react-redux'
import { selectVerificationError } from 'og-core/providers/verification/selectors'

import { FullRegistrationStepThreeError } from './FullRegistrationStepThreeError'
import { FullRegistrationStepThreeSuccess } from './FullRegistrationStepThreeSuccess'

export const FullRegistrationStepThree = () => {
  const currentError = useSelector(selectVerificationError) // TODO understand where I went wrong
  return currentError ? (
    <FullRegistrationStepThreeError />
  ) : (
    <FullRegistrationStepThreeSuccess />
  )
}
