import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { SportGames } from './SportGames'

export const PageSportGames = () => {
  return (
    <Layout type={ELayoutsList.GAME}>
      <SportGames />
    </Layout>
  )
}
