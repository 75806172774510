import {
  isGenericSportEnabled,
  isNewEventsEndpointEnabled
} from 'astra-core/containers/ConfigProvider/saga'
import { put, takeLatest, call, select } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  eventsProviderActions,
  FetchEventsPayload
} from 'astra-core/containers/EventsProvider'
import { limitPageSize } from 'astra-core/containers/EventsProvider/utils'
import dayjs from 'dayjs'
import { DATE_FORMAT } from 'astra-core/containers/SportCountersProvider/constants'

import { eventsProviderSideEffects } from 'shared/lib/eventsProviderSideEffects'

import { liveContainerActions } from './slice'
import { FetchLivePayload, FetchLiveSuccessPayload } from './types'
import {
  selectLiveEventsPageSize,
  selectLiveEventsPaginationKey
} from './selectors'

const fetchLineSort = ['groupId.asc', 'scheduledAt.asc']
const fetchLineStatuses = ['SUSPENDED', 'TRADING', 'PLANNING', 'OPEN']

export function* fetchLiveEventsSaga(payload: FetchEventsPayload) {
  const { reqData, sideEffects } = payload
  const isGenericSportsEnabled = yield call(isGenericSportEnabled)

  yield put(
    eventsProviderActions.fetchEvents({
      reqData: {
        ...reqData,
        sort: fetchLineSort,
        scheduledTo: dayjs().format(DATE_FORMAT),
        // @ts-ignore
        statuses: fetchLineStatuses
      },
      sideEffects,
      params: {
        withCompetitorsLogo: false,
        withLinkedEvents: true,
        includeSubsports: isGenericSportsEnabled || undefined,
        withNewRequest: true
      }
    })
  )
}

export function* fetchLiveSaga(action: PayloadAction<FetchLivePayload>) {
  const { sportId } = action.payload
  const pageSize = yield select(selectLiveEventsPageSize, +sportId!)

  const reqData = {
    ...action.payload,
    pageSize
  }

  const isResponseOfEventEnabled = yield call(isNewEventsEndpointEnabled)

  const sideEffects = isResponseOfEventEnabled
    ? eventsProviderSideEffects.liveEventsV2
    : eventsProviderSideEffects.liveEvents

  yield call(fetchLiveEventsSaga, { reqData, sideEffects })
}

export function* fetchLivePaginationSaga(
  action: PayloadAction<FetchLivePayload>
) {
  const { sportId } = action.payload

  const pageKey = yield select(selectLiveEventsPaginationKey, +sportId!)

  const reqData = {
    ...action.payload,
    pageKey
  }

  const sideEffects = eventsProviderSideEffects.liveEventsPagination

  yield call(fetchLiveEventsSaga, { reqData, sideEffects })
}

export function* fetchLiveEventsSuccessSaga(
  action: PayloadAction<FetchLiveSuccessPayload>
) {
  const { reqData, resData } = action.payload

  const { pageSize } = reqData

  const { remainder } = limitPageSize(pageSize)

  if (remainder > 0 && resData.paginationKey) {
    const reqData = {
      ...action.payload.reqData,
      pageKey: resData.paginationKey,
      pageSize: remainder
    }

    yield call(fetchLiveEventsSaga, {
      reqData,
      sideEffects: eventsProviderSideEffects.liveEventsV2
    })
  }
}

export function* liveContainerSaga() {
  yield takeLatest(liveContainerActions.fetchLive.type, fetchLiveSaga)
  yield takeLatest(
    liveContainerActions.fetchLiveSuccessV2.type,
    fetchLiveEventsSuccessSaga
  )
  yield takeLatest(
    liveContainerActions.fetchLivePagination.type,
    fetchLivePaginationSaga
  )
}
