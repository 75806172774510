import React, { FC, memo, useMemo, useState } from 'react'
import { Event, LinkedEvent } from 'betweb-openapi-axios'
import { selectEventById } from 'astra-core/containers/EventsProvider/selectors'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectSearchEventsItem } from 'astra-core/containers/SearchProvider'
import isEqual from 'react-fast-compare'

import { RootState } from 'shared/types/store'

import { EventRow } from '../event-row'

import {
  StyledExpandButton,
  StyledLinkedEventsWrapper,
  StyledRowWrapper
} from './linked-events.styled'
import { LinkedEventProps, LinkedEventsProps } from './linked-events.types'

const firstExpandMaxCount = 5

const getLinkedEventsData = (events: LinkedEvent[]) => {
  return events.reduce<{
    expandData: Event['id'][]
    overflowData: Event['id'][]
  }>(
    (acc, event, index) => {
      if (index < firstExpandMaxCount) {
        acc?.expandData?.push(event.eventId)
        return acc
      }
      acc?.overflowData?.push(event.eventId)
      return acc
    },
    { expandData: [], overflowData: [] }
  )
}

const linkedEventsPropsComparator = (
  prevProps: LinkedEventsProps,
  nextProps: LinkedEventsProps
) => {
  const prevIds = prevProps.linkedEvents.map((event) => event.eventId)
  const nextIds = nextProps.linkedEvents.map((event) => event.eventId)

  return (
    prevIds.length === nextIds.length &&
    isEqual(new Set(prevIds), new Set(nextIds))
  )
}

const LinkedEventRow: FC<LinkedEventProps> = memo(({ id, parentEventId }) => {
  const event = useSelector(
    (state: RootState) =>
      selectEventById(state, id) ?? selectSearchEventsItem(state, id)
  )

  if (!event) {
    return null
  }

  return (
    <StyledRowWrapper>
      <EventRow event={event} key={event.id} parentEventId={parentEventId} />
    </StyledRowWrapper>
  )
})

export const LinkedEvents: FC<LinkedEventsProps> = memo((props) => {
  const { linkedEvents, parentEventId } = props
  const [isExpandMoreOpened, setIsExpandMoreOpened] = useState(false)
  const { t } = useTranslation()

  const handleSecondExpand = () => {
    setIsExpandMoreOpened(true)
  }

  const linkedEventsData = useMemo(() => {
    return getLinkedEventsData(linkedEvents)
  }, [linkedEvents])

  const { expandData, overflowData } = linkedEventsData

  const showMore = overflowData.length > 0
  return (
    <StyledLinkedEventsWrapper>
      {expandData.map((eventId) => (
        <LinkedEventRow
          id={eventId}
          key={eventId}
          parentEventId={parentEventId}
        />
      ))}
      {showMore && (
        <StyledExpandButton
          hidden={isExpandMoreOpened}
          onClick={handleSecondExpand}
        >
          {t('show more events', {
            count: overflowData.length
          })}
        </StyledExpandButton>
      )}
      {isExpandMoreOpened &&
        overflowData.map((eventId) => (
          <LinkedEventRow
            id={eventId}
            key={eventId}
            parentEventId={parentEventId}
          />
        ))}
    </StyledLinkedEventsWrapper>
  )
}, linkedEventsPropsComparator)
