import styled from 'styled-components'

import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { Button } from 'shared/ui/Button'

export const StyledFullRegistrationWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 312px;
  justify-content: center;
  padding: 26px 0;
  position: relative;
  width: 400px;
`

export const StyledFullRegistrationHeaderWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;
`

export const StyledFullRegistrationHeaderExit = styled(IconClose)`
  align-self: center;
  cursor: pointer;
`

export const StyledText = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 168px;
  justify-content: center;
`
export const StyledTextSuccessTitle = styled.div`
  line-height: 20px;
  margin-top: 16px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.bold};
    color: ${theme.colors.default.green[50]};
  `}
`
export const StyledTextSuccessDescription = styled.div`
  line-height: 16px;
  margin-top: 4px;
  text-align: center;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.fixed.primary[50]};
  `}
`

export const StyledTextErrorTitle = styled.div`
  line-height: 20px;
  margin-top: 16px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.bold};
    color: ${theme.colors.custom.yellow[10]};
  `}
`
export const StyledTextErrorDescription = styled.div`
  line-height: 16px;
  margin-top: 4px;
  text-align: center;
  width: 70%;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.custom.primary[40]};
  `}
`
export const StyledTextErrorButton = styled(Button)`
  margin-top: 16px;

  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.custom.primary[7]};
  `}
`
