import React, { FC, memo } from 'react'
import { useParams } from 'react-router-dom'
import { useVSportGamesData } from 'og-core/providers/virtual-games/hooks'

import { LoaderPoints } from 'shared/ui/LoaderPoints/LoaderPoints'

import { REF_ID } from './constants'

export const VsportGame: FC = () => {
  const { gameNameId } = useParams<{ gameNameId: string }>()
  const { src, loaded } = useVSportGamesData({ gameNameId, refId: REF_ID })

  if (!loaded) {
    return <LoaderPoints />
  }
  return <VsportFrame src={src} />
}

const VsportFrame: FC<{ src: string }> = memo(({ src }: { src: string }) => {
  return (
    <iframe
      style={{
        height: '100%'
      }}
      src={src}
      title="vsport-game"
    />
  )
})
