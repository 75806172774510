import React, { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectInputSearchLine } from 'astra-core/containers/SearchProvider'
import { selectSports } from 'astra-core/containers/CommonDataProvider'
import { useGroupedTournaments } from 'astra-core'
import groupBy from 'lodash/groupBy'

import { ETestData } from 'shared/lib/testData'
import { RootState } from 'shared/types/store'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { lineTournamentsContainerActions } from 'pages/page-line-tournament/LineTournamentsContainer/slice'
import {
  selectLayoutIsLoading,
  selectLineTournaments,
  selectLineTournamentsLength
} from 'pages/page-line-tournament/LineTournamentsContainer/selectors'
import { LineTournamentsParams } from 'pages/page-line-tournament/LineTournamentsContainer/types'
import { useTournamentsGroupedBySubSport } from 'pages/page-line-tournament/LineTournamentsContainer/hooks'

import { ClearResultSearch } from '../ui'
import { EmptyPageFilter } from '../EmptyPageFilter'
import { LineDivider } from '../ui/LineDivider'

import { LineTournamentSportList, LineTournamentsGroupList } from './components'
import {
  StyledLineWrapper,
  StyledLoadingIndicatorWrapper
} from './LineTournaments.styled'
import { LineGroupedTournamentsProps } from './LineTournaments.types'
import {
  StyledGroupedLine,
  StyledLineTable,
  StyledLineTableTop,
  StyledTopDivider
} from './components/TournamentsGroupList/components/TournamentsGroup/TournamentGroup.styled'
import { LineTournament } from './components/TournamentsGroupList/components/TournamentsGroup/components'

// TODO: OL-552 Reneame to LineTournament
export const GenericLineTournaments: FC = () => {
  const dispatch = useDispatch()
  const { sportId } = useParams<LineTournamentsParams>()

  const searchText = useSelector(selectInputSearchLine)

  const isLayoutLoading = useSelector(selectLayoutIsLoading)

  const { isGenericSport, allGroupedTournaments, groupedTournaments } =
    useTournamentsGroupedBySubSport({
      sportId: +sportId
    })

  const resetLineFilter = useCallback(() => {
    dispatch(lineTournamentsContainerActions.resetLineFilter())
  }, [dispatch])

  const hasManySubsports = isGenericSport && allGroupedTournaments.length > 1
  const hasResults = groupedTournaments.length > 0

  return (
    <StyledLineWrapper $hideTopDivider={isGenericSport} $isEmpty={!hasResults}>
      <>
        {hasManySubsports && (
          <LineTournamentSportList sports={allGroupedTournaments} />
        )}
        {isGenericSport && <LineDivider />}
      </>
      {isLayoutLoading && !hasResults ? (
        // Show loading indicator if layout is loading and no line tournaments
        <StyledLoadingIndicatorWrapper>
          <LoaderSpinner />
        </StyledLoadingIndicatorWrapper>
      ) : hasResults ? (
        // Display line tournament group list if line tournaments exist
        <LineTournamentsGroupList
          groupedTournaments={groupedTournaments}
          showHeader={isGenericSport}
        />
      ) : searchText ? (
        // Show clear search result component if there's a search text
        <ClearResultSearch />
      ) : (
        // If none of the above conditions match, display empty page filter
        <EmptyPageFilter onButtonClickResetFilter={resetLineFilter} />
      )}
    </StyledLineWrapper>
  )
}

// TODO: OL-552 remove after release
export const LineTournaments: FC = () => {
  const { sportId } = useParams<LineTournamentsParams>()
  const sports = useSelector(selectSports)
  const dispatch = useDispatch()
  const searchText = useSelector(selectInputSearchLine)
  const tournaments = useSelector((state: RootState) =>
    selectLineTournaments(state, { sportId })
  )
  const isLayoutLoading = useSelector(selectLayoutIsLoading)
  const lineTournamentsLength = useSelector((state: RootState) =>
    selectLineTournamentsLength(state, { sportId })
  )

  const groupedTournaments = useGroupedTournaments(tournaments, sports)

  const resetLineFilter = useCallback(() => {
    dispatch(lineTournamentsContainerActions.resetLineFilter())
  }, [dispatch])

  return (
    <StyledLineWrapper>
      {isLayoutLoading && !lineTournamentsLength ? (
        <StyledLoadingIndicatorWrapper>
          <LoaderSpinner />
        </StyledLoadingIndicatorWrapper>
      ) : lineTournamentsLength ? (
        groupedTournaments.map((group) => (
          <LineGroupedTournaments key={group.name} tournamentsGroup={group} />
        ))
      ) : searchText ? (
        <ClearResultSearch />
      ) : (
        <EmptyPageFilter onButtonClickResetFilter={resetLineFilter} />
      )}
    </StyledLineWrapper>
  )
}

export const LineGroupedTournaments: FC<LineGroupedTournamentsProps> = ({
  tournamentsGroup,
  showSport
}) => {
  const tournaments = groupBy(tournamentsGroup.tournaments, 'top')

  const topTournaments = tournaments.true || []
  const hasTopTournaments = topTournaments.length > 0
  const otherTournaments = tournaments.false || []

  return (
    <StyledGroupedLine>
      <StyledLineTable data-test-id={ETestData.TestLineTable}>
        {hasTopTournaments && (
          <>
            <StyledLineTableTop>
              {topTournaments.map((tournament) => (
                <LineTournament
                  key={tournament.id}
                  showSport={showSport}
                  testData={`${ETestData.TestLineTopTournament}-${tournament.id}`}
                  tournament={tournament}
                />
              ))}
              <StyledTopDivider />
            </StyledLineTableTop>
          </>
        )}

        {otherTournaments.map((tournament) => (
          <LineTournament
            key={tournament.id}
            showSport={showSport}
            testData={`${ETestData.TestLineTournament}-${tournament.id}`}
            tournament={tournament}
          />
        ))}
      </StyledLineTable>
    </StyledGroupedLine>
  )
}
