import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import {
  ENotificationLayouts,
  INotification,
  messagesProviderActions,
  selectMessages
} from 'astra-core/containers/MessagesProvider'
import { formatDate } from 'astra-core'

import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'
import { Accordion } from 'shared/ui/Accordion'
import { useAppSelector } from 'app/lib/store'

import { StyledMessagessContainer } from './MessagesAccordion.styled'

export const MessagesAccordion: FC = () => {
  const dispatch = useDispatch()
  const notifications = useAppSelector((state) =>
    selectMessages(state, ENotificationLayouts.NOTIFICATIONS_PAGE)
  )

  const handleClickAccordion = ({
    id,
    isRead
  }: {
    id: string
    isRead: INotification['isRead']
  }) => {
    if (!isRead) {
      dispatch(
        messagesProviderActions.setReadMessage({
          messageId: id
        })
      )
    }
  }

  return (
    <StyledMessagessContainer>
      {notifications?.map((item: INotification) => (
        <Accordion
          accordionDate={formatDate(item.created_at)}
          accordionDescr={item.rendered_text}
          accordionTitle={item.header}
          id={item.id}
          isRead={item.isRead}
          key={item.id}
          padding={14}
          color
          colorDescr
          onClick={(id) => handleClickAccordion({ id, isRead: item.isRead })}
        >
          <IconChevronDown size={10} />
        </Accordion>
      ))}
    </StyledMessagessContainer>
  )
}
