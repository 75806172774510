import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { OutcomesGroupsTabs } from 'pages/page-event/Event/components/Outcomes/Filters/components/OutcomesFilterTabs'
import { InputSearch } from 'pages/page-event/Event/components'
import { LinkedEventsTabs } from 'pages/page-event/Event/components/Outcomes/Filters/components/OutcomesFilterTabs/LinkedEventsTabs'
import { selectActiveLinkedEventId } from 'containers/OutcomesContainer/selectors'

import { OutcomesHeadProps } from './outcomes-head.types'
import {
  StyledOutcomesHead,
  StyledOutcomesHeadRight,
  StyledOutcomesToggleDisplay
} from './outcomes-head.styled'

export const OutcomesHead: FC<OutcomesHeadProps> = ({
  event,
  withBorder,
  inputTestData,
  toggleTestData,
  outcomesRef,
  isLinkedEvent
}) => {
  const [t] = useTranslation()
  const [areAllOpened, setAllOpened] = useState(true)
  const selectedLinkedEvent = useSelector(selectActiveLinkedEventId)

  const searchEventId =
    isLinkedEvent && selectedLinkedEvent ? selectedLinkedEvent : event.id

  const openAll = () => {
    setAllOpened(true)
    outcomesRef.current?.openAll()
  }

  const closeAll = () => {
    setAllOpened(false)
    outcomesRef.current?.closeAll()
  }

  const testId = areAllOpened ? toggleTestData.collapsed : toggleTestData.expand

  return (
    <StyledOutcomesHead withBorder={withBorder}>
      {isLinkedEvent ? (
        <LinkedEventsTabs linkedEvents={event.linkedEvents!} />
      ) : (
        <OutcomesGroupsTabs event={event} />
      )}

      <StyledOutcomesHeadRight isLinkedEvent={isLinkedEvent}>
        <InputSearch eventId={searchEventId} testData={inputTestData} />

        <StyledOutcomesToggleDisplay
          data-test-id={testId}
          onClick={() => (areAllOpened ? closeAll() : openAll())}
        >
          {t(areAllOpened ? 'collapse all' : 'expand all')}
        </StyledOutcomesToggleDisplay>
      </StyledOutcomesHeadRight>
    </StyledOutcomesHead>
  )
}
