import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'
import {
  getSelectedPeriodOptionValue,
  useLineFilterPeriodTimesComparison
} from 'astra-core'

import { useInterval, usePrevious } from 'hooks'
import {
  selectEventsLayoutIsLoading,
  selectFilterIsTop,
  selectFilterPeriod
} from 'pages/page-line-tournament/LineTournamentsContainer/selectors'
import { lineTournamentsContainerActions } from 'pages/page-line-tournament/LineTournamentsContainer/slice'
import {
  getScheduleTime,
  OPTIONS_SELECT_PERIOD
} from 'pages/page-live-events/Line/components/ui/LineHeader/components/LineHeaderFilters/constants'

import { getFetchLineReqData, LINE_POLLING_INTERVAL } from './utils'
import { LineParams } from './types'

export const useLineContainerData = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { tournamentId } = useParams<LineParams>()
  const tournamentIdPrev = usePrevious(tournamentId)
  const isLayoutLoading = useSelector(selectEventsLayoutIsLoading)
  const top = useSelector(selectFilterIsTop)
  const filterPeriod = useSelector(selectFilterPeriod)
  const periodOptions = OPTIONS_SELECT_PERIOD(t)
  const selectedPeriodValue = useMemo(
    () => getSelectedPeriodOptionValue(periodOptions, filterPeriod),
    [filterPeriod, periodOptions]
  )

  const setFilterPeriod = useCallback(() => {
    if (selectedPeriodValue) {
      dispatch(
        lineTournamentsContainerActions.setFilterPeriod({
          ...getScheduleTime(selectedPeriodValue)
        })
      )
    }
  }, [dispatch, selectedPeriodValue])

  const filters = useMemo(() => ({ top, filterPeriod }), [filterPeriod, top])
  const filtersPrev = usePrevious(filters)

  const fetchLine = useCallback(() => {
    if (tournamentId) {
      dispatch(
        lineTournamentsContainerActions.fetchLine({
          reqData: getFetchLineReqData({ tournamentId }),
          params: {
            withLinkedEvents: true,
            withCompetitorsLogo: true,
            withNewRequest: true
          }
        })
      )
    }
  }, [dispatch, tournamentId])

  useEffect(() => {
    /* To handle fetch on changed filters */

    if (!isEqual(filtersPrev, filters)) {
      fetchLine()
    }
  }, [fetchLine, filters, filtersPrev])

  useEffect(() => {
    /* To handle fetch on sport id change */

    if (!!tournamentIdPrev && tournamentIdPrev !== tournamentId) {
      fetchLine()
    }
  }, [tournamentIdPrev, tournamentId, fetchLine])

  /* Update Line if the current time does not match the filter */
  useLineFilterPeriodTimesComparison({
    filterPeriod,
    callback: setFilterPeriod,
    selectedPeriodValue
  })

  const intervalDelay = isLayoutLoading ? null : LINE_POLLING_INTERVAL

  useInterval(fetchLine, intervalDelay, true)
}

export const useLineTournamentContainerData = ({ tournamentId }) => {
  const dispatch = useDispatch()
  const isLayoutLoading = useSelector(selectEventsLayoutIsLoading)

  const fetchLine = useCallback(() => {
    if (tournamentId) {
      dispatch(
        lineTournamentsContainerActions.fetchLine({
          reqData: getFetchLineReqData({ tournamentId })
        })
      )
    }
  }, [dispatch, tournamentId])

  const intervalDelay = isLayoutLoading ? null : LINE_POLLING_INTERVAL

  useInterval(fetchLine, intervalDelay, true)
}
