import React, { FC, memo, useMemo } from 'react'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider/types'
import { useTranslation } from 'react-i18next'
import {
  formatDate,
  getEventHomeAwayCompetitors,
  formatScoresByPeriod
} from 'astra-core/utils/events'

import { ETeamLogo } from 'shared/ui/Icon/TeamLogo/TeamLogo.types'
import { TeamLogo } from 'shared/ui/Icon/TeamLogo'

import {
  EventBannerCompletedProps,
  EventCompetitorsProps
} from './EventBannerCompleted.types'
import {
  StyledCompetitorName,
  StyledTitle,
  StyledActions,
  StyledBannerCompetitor,
  StyledDateTimeWrapepr,
  StyledEventBanner,
  StyledEventBannerWrapper,
  StyledEventDateTime,
  StyledEventInfo,
  StyledEventTitle,
  StyledMatchStatus,
  StyledMatchStatusScore,
  StyledMatchStatusScoreItems,
  StyledMatchStatusTitle,
  StyledNameTeamWrapper,
  StyledTeamsSection
} from './EventBannerCompleted.styled'

export const EventBannerCompleted: FC<EventBannerCompletedProps> = memo(
  ({ eventResult, sportData }) => {
    const competitors = useMemo(
      () => getEventHomeAwayCompetitors(eventResult),
      [eventResult]
    )

    const scoresByPeriod = formatScoresByPeriod(eventResult)
    return (
      <StyledEventBanner>
        <StyledEventBannerWrapper sportCode={sportData.code as ESportsCodes}>
          <StyledEventInfo>
            <StyledEventTitle>
              <StyledTitle>
                {sportData.name}. {eventResult.tournament.name}
              </StyledTitle>
            </StyledEventTitle>
            <StyledDateTimeWrapepr>
              <StyledEventDateTime>
                {formatDate(eventResult.eventDate)}
              </StyledEventDateTime>
            </StyledDateTimeWrapepr>
          </StyledEventInfo>
          <EventCompetitors
            competitors={competitors}
            eventResult={eventResult}
          />
          <StyledActions sportCode={sportData.code as ESportsCodes}>
            {scoresByPeriod}
          </StyledActions>
        </StyledEventBannerWrapper>
      </StyledEventBanner>
    )
  }
)

const EventCompetitors: FC<EventCompetitorsProps> = ({
  eventResult,
  competitors
}) => {
  const [t] = useTranslation()
  const competitorsArr = Object.values(competitors)
  const { homeScore, awayScore } =
    eventResult.basicStatistics.currentScore ?? {}

  return (
    <StyledTeamsSection>
      {competitorsArr?.map((competitor, index) => (
        <StyledNameTeamWrapper
          area={`team${index + 1}`}
          key={competitor[0]?.id}
        >
          <TeamLogo
            isPlace={ETeamLogo.eventCompleted}
            logoUrl={competitor[0]?.competitorLogo?.logoUrl}
          />
          <StyledBannerCompetitor>
            <StyledCompetitorName>{competitor[0]?.name}</StyledCompetitorName>
          </StyledBannerCompetitor>
        </StyledNameTeamWrapper>
      ))}
      <StyledMatchStatus>
        <StyledMatchStatusTitle> {t('match is over')}</StyledMatchStatusTitle>
        {eventResult.basicStatistics.currentScore && (
          <StyledMatchStatusScore>
            <StyledMatchStatusScoreItems>
              {homeScore}
            </StyledMatchStatusScoreItems>
            <StyledMatchStatusScoreItems>:</StyledMatchStatusScoreItems>
            <StyledMatchStatusScoreItems>
              {awayScore}
            </StyledMatchStatusScoreItems>
          </StyledMatchStatusScore>
        )}
      </StyledMatchStatus>
    </StyledTeamsSection>
  )
}
