import React, { FC, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dayjsCore as dayjs,
  dayOfYearCore,
  getIsToday
} from 'astra-core/utils/dayjs'
import { selectCurrentCashout } from 'astra-core/containers/BetsHistoryProvider'
import { useSelector } from 'react-redux'

import { Outcomes } from '../Outcome'
import { TableMethodsContext } from '../../context'

import { ICustomRow, ICustomRows, IRowDate } from './Row.types'
import { BET_DATE, ROW_TITLE_DATE } from './constants'
import {
  StyledRowBet,
  StyledRowDateColumn,
  StyledRowDateRow
} from './Row.styled'

dayjs.extend(dayOfYearCore)

export const Rows: FC<ICustomRows> = ({ rows, prepareRow, visibleColumns }) => {
  let currentDate = ''

  return (
    <>
      {rows.map((row) => {
        prepareRow(row)
        const formattingDate = dayjs(row.cells[1].value).format(BET_DATE)

        const checkRenderDate = () => {
          if (formattingDate !== currentDate) {
            currentDate = formattingDate

            return {
              isDifferentDay: true,
              colCount: visibleColumns.length
            }
          }

          return {}
        }

        return <Row key={row.id} row={row} {...checkRenderDate()} />
      })}
    </>
  )
}

const Row: FC<ICustomRow> = ({ colCount, isDifferentDay, row }) => {
  const { collapsedRows, onRowCollapse } = useContext(TableMethodsContext)
  const currentCashout = useSelector(selectCurrentCashout)

  const isCollapsedOutcomes = useMemo(
    () => !!collapsedRows?.includes(row.original.id),
    [collapsedRows, row.original.id]
  )

  const handleOnClick = useCallback(
    () => onRowCollapse?.(row.original.id),
    [onRowCollapse, row.original]
  )

  return (
    <>
      {isDifferentDay && (
        <RowDate colCount={colCount} date={row.cells[1].value} />
      )}

      <StyledRowBet
        isCollapsed={isCollapsedOutcomes}
        isCurrentCashoutExist={!!currentCashout}
        onClick={handleOnClick}
        {...row.getRowProps()}
      >
        {row.cells.map((cell) => cell.render('Cell', cell.getCellProps()))}
      </StyledRowBet>

      {isCollapsedOutcomes || <Outcomes outcomes={row.original.outcomes} />}
    </>
  )
}

const RowDate: FC<IRowDate> = ({ date, colCount }) => {
  const [t] = useTranslation()

  const dateFormatted = useMemo(
    () => dayjs(date).format(ROW_TITLE_DATE),
    [date]
  )

  const dayName = useMemo(() => {
    const dateTitle = getIsToday(date)

    return dateTitle && `${t(dateTitle).toUpperCase()}, `
  }, [date, t])

  return (
    <StyledRowDateRow>
      <StyledRowDateColumn colSpan={colCount}>
        {dayName}
        {dateFormatted}
      </StyledRowDateColumn>
    </StyledRowDateRow>
  )
}
