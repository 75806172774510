import styled, { css } from 'styled-components'

export const StyledPanel = styled.div<{ opened: boolean }>`
  ${({ opened, theme }) =>
    !opened
      ? css`
          border-bottom: 1px solid ${theme.colors.custom.primary[8]};
        `
      : css`
          margin-bottom: 8px;
        `}
`

export const StyledPanelHead = styled.div`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-column-gap: 4px;
  grid-template-columns: 1fr 16px;
  min-height: 32px;
  padding: 6px 5px;
`

export const StyledPanelTitle = styled.span`
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-size: ${({ theme }) => theme.fonts.size.m};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  line-height: 20px;

  span {
    background-color: ${({ theme }) => theme.colors.default.yellow[30]};
    color: ${(props) => props.theme.colors.fixed.primary[90]};
  }
`

export const StyledTableRadius = styled.div`
  background-color: ${({ theme }) => theme.colors.default.primary[0]};
  border-radius: 8px;
  overflow: hidden;
`
