import { Event } from 'betweb-openapi-axios'
import { EEventsStatusList } from 'astra-core/utils/events/types'

export interface IEventProps {
  event: Event
}

export enum EventsTabs {
  mainEvent = 'mainEvent',
  linkedEvents = 'linkedEvents'
}

export interface EventTabProps extends IEventProps {
  eventStatusType: EEventsStatusList | null
}

export interface EventTab {
  title: string
  type: EventsTabs
}
