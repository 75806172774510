import React from 'react'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { useHistory } from 'react-router-dom'

import { ELayoutsList, Layout } from 'widgets/Layout'
import { ERoutes } from 'shared/types/routes'

import { Frigg } from './Frigg'
import { FriggPageStyled } from './page.styled'

export const FriggPage = () => {
  // TODO: OL-614 Remove after release
  const isFriggEnabled = useFeatureFlag(EFeatureFlags.FRIGG_ENABLED)

  const history = useHistory()

  if (!isFriggEnabled) {
    console.error('Frigg not enabled')
    history.push(ERoutes.Error404)
  }

  return (
    <Layout type={ELayoutsList.DEFAULT}>
      <FriggPageStyled>
        <Frigg />
      </FriggPageStyled>
    </Layout>
  )
}
