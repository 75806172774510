import React, { useEffect, useState } from 'react'
import CurrencyInput, {
  CurrencyInputOnChangeValues,
  formatValue
} from 'react-currency-input-field'

import { DynamicInput } from './DynamicInput'
import { DynamicInputProps } from './DynamicInput.types'

type InputModeConfig = {
  inputMode: DynamicInputProps['inputMode']
  decimalSeparator?: string
}

const decimalInputConfig: InputModeConfig = {
  inputMode: 'decimal',
  decimalSeparator: ','
}

export const numericInputConfig: InputModeConfig = {
  inputMode: 'numeric'
}

export type TCurrencyDynamicInput = DynamicInputProps & {
  onAmoutChange: (amount: string) => void
  inputConfig?: typeof decimalInputConfig | typeof numericInputConfig
}

const format = (value: string) => {
  return formatValue({
    value,
    decimalSeparator: ',',
    groupSeparator: ' '
  })
}

export const CurrencyDynamicInput = React.forwardRef<
  HTMLInputElement,
  TCurrencyDynamicInput
>((props: TCurrencyDynamicInput, ref) => {
  const { inputConfig = decimalInputConfig } = props

  const [inputValue, setInputValue] = useState<string>(
    format(props.inputValue ?? '')
  )

  useEffect(() => {
    setInputValue(format(props.inputValue ?? ''))
  }, [props.inputValue])

  const onChangeValues = (
    value: string | undefined,
    name?: string,
    values?: CurrencyInputOnChangeValues
  ) => {
    setInputValue(values?.formatted ?? '')
    props.onAmoutChange(value ?? '')
  }

  return (
    <CurrencyInput
      transformRawValue={(raw) => {
        return raw.replace('.', ',')
      }}
      allowNegativeValue={false}
      customInput={DynamicInput}
      decimalSeparator={inputConfig.decimalSeparator}
      groupSeparator=" "
      inputMode={inputConfig.inputMode}
      // @ts-ignore CurrencyInput cant type custom input
      inputValue={inputValue}
      placeholder={props.placeholder}
      ref={ref}
      disableAbbreviations
      onValueChange={onChangeValues}
    />
  )
})
