import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EVerificationSteps } from 'og-core/providers/verification/types'
import { selectCurrentStep } from 'og-core/providers/verification/selectors'
import { verificationActions } from 'og-core/providers/verification/slice'

import { FullRegistrationStepOne } from './FullRegistrationStepOne'
import { FullRegistrationStepTwo } from './FullRegistrationStepTwo'
import { FullRegistrationStepThree } from './FullRegistrationStepThree'

const VERIFICATION_STEPS = {
  [EVerificationSteps.ONE]: <FullRegistrationStepOne />,
  [EVerificationSteps.TWO]: <FullRegistrationStepTwo />,
  [EVerificationSteps.THREE]: <FullRegistrationStepThree />
} as const

export const FullRegistration: FC = () => {
  const dispatch = useDispatch()
  const currentStep = useSelector(selectCurrentStep)

  useEffect(() => {
    dispatch(verificationActions.fetchControlQuestions())
  }, [dispatch])

  return useMemo(() => {
    return currentStep ? VERIFICATION_STEPS[currentStep] : null
  }, [currentStep])
}
