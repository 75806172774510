import { Event } from 'betweb-openapi-axios'
import { EEventsStatusList } from 'astra-core/utils/events/types'

import { OutcomeCategoriesHandle } from './Categories/Categories.types'

export enum OutcomesComponentType {
  EVENT_PAGE = 'event page',
  EVENTS_LIST = 'events list'
}

export interface OutcomesProps {
  eventId: Event['id']
  type: OutcomesComponentType
  isLoading?: boolean
  outcomesRef?: React.RefObject<OutcomeCategoriesHandle>
  eventStatusType: EEventsStatusList | null
}
