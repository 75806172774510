import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsAuthenticationModalShown } from 'astra-core/containers/AccountProvider'
import { selectCurrentStep } from 'og-core/providers/verification/selectors'
import { verificationActions } from 'og-core/providers/verification/slice'

import { FullRegistration } from 'widgets/FullRegistration'
import { Modal, ViewComponentType } from 'shared/ui/modal'
import { useAppSelector } from 'app/lib/store'

export const ModalFullRegistration = () => {
  const dispatch = useDispatch()
  const isAuthenticationModalShown = useSelector(
    selectIsAuthenticationModalShown
  )
  const isVerifictionModalShown = useAppSelector(
    (state) => !!selectCurrentStep(state)
  )

  const isModalShown = isAuthenticationModalShown || isVerifictionModalShown

  const closeAuthenticationModal = useCallback(() => {
    dispatch(
      verificationActions.changeCurrentVerificationStep({
        step: null
      })
    )
  }, [dispatch])

  return (
    <Modal
      isOpen={isModalShown}
      view={ViewComponentType.PAYMENT}
      onModalClose={closeAuthenticationModal}
    >
      <FullRegistration />
    </Modal>
  )
}
