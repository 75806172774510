import styled, { css } from 'styled-components'
import { BetStatus } from 'betweb-openapi-axios'

import { StyledCell } from '../Cell.styled'

const getTextColorOfStatus = (props, betStatus?: BetStatus) => {
  const data = {
    [BetStatus.Won]: (props) => props.theme.colors.custom.green[3],
    [BetStatus.Returned]: (props) => props.theme.colors.default.yellow[50],
    [BetStatus.Lost]: (props) => props.theme.colors.custom.primary[17],
    [BetStatus.Sold]: (props) => props.theme.colors.default.blue[50]
  }

  return data[betStatus || ''] || props.theme.colors.default.primary[90]
}

export const StyledCellResultText = styled(StyledCell)<{
  betStatus?: BetStatus
}>`
  ${(props) =>
    css`
      color: ${getTextColorOfStatus(props, props.betStatus)};
      font-weight: ${props.theme.fonts.weight.regular};
      width: 110px;
    `}
`
