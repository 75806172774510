import React, { FC, memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  resultsProviderActions,
  selectResultCountersList
} from 'astra-core/containers/ResultsProvider'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { useResultsActiveItem } from 'astra-core/hooks/useResultsActiveItem'
import { ESportsCounterType } from 'astra-core/containers/SportCountersProvider/types'
import { selectResultCountersListV2 } from 'astra-core/containers/SportCountersProvider/selectors'
import {
  isFeatureFlagEnabled,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { defaultResultsStatuses } from 'astra-core/containers/ResultsProvider/constants'

import { SportIcon } from 'shared/ui/Icon/SportIcon'
import { ERoutes } from 'shared/types/routes'
import { RootState } from 'shared/types/store'

import { ResultsMenuItemProps } from './ResultsMenuList.types.ts'
import {
  StyledSportsMenuItemCount,
  StyledSportsMenuItemLink,
  StyledSportsMenuItemText,
  StyledSportsMenuList
} from './ResultsMenuList.styled'

export const ResultsMenuList = () => {
  const isNewCountersEndpoint = useFeatureFlag(
    EFeatureFlags.NEW_COUNTERS_ENDPOINT
  )
  const resultsCountersList = useSelector((state: RootState) =>
    isNewCountersEndpoint
      ? selectResultCountersListV2(state, ESportsCounterType.RESULTS)
      : selectResultCountersList(state)
  )

  useResultsActiveItem()

  return (
    <StyledSportsMenuList>
      {resultsCountersList.map((sport) => (
        <ResultsMenuItem key={sport.id} sport={sport as Required<typeof sport>}>
          {sport.name}
        </ResultsMenuItem>
      ))}
    </StyledSportsMenuList>
  )
}

const ResultsMenuItem: FC<ResultsMenuItemProps> = memo(({ sport }) => {
  const dispatch = useDispatch()

  const isNewResultsEndpoint = isFeatureFlagEnabled(
    EFeatureFlags.NEW_RESULTS_ENDPOINT
  )

  const changeCurrentSportId = useCallback(() => {
    if (isNewResultsEndpoint) {
      dispatch(
        resultsProviderActions.setCurrentSportIdFilter({
          sportId: sport.id,
          withoutCounters: true,
          reqData: {
            // @ts-ignore
            statuses: defaultResultsStatuses
          }
        })
      )
    } else {
      dispatch(
        resultsProviderActions.setCurrentSportIdFilter({ sportId: sport.id })
      )
    }
  }, [dispatch, sport.id, isNewResultsEndpoint])

  return (
    <StyledSportsMenuItemLink
      // @ts-ignore
      $active={sport.isActive}
      to={`${ERoutes.Results}`}
      onClick={changeCurrentSportId}
    >
      <SportIcon
        isActive={sport.top}
        sportCode={sport.code as ESportsCodes}
        withBackground
      />
      <StyledSportsMenuItemText>{sport.name}</StyledSportsMenuItemText>
      <StyledSportsMenuItemCount>{sport.count}</StyledSportsMenuItemCount>
    </StyledSportsMenuItemLink>
  )
})
