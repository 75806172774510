import styled, { css } from 'styled-components'

import { StyledIcon } from 'shared/ui/Icon'
import { IconStarWithoutBackground } from 'shared/ui/Icon/General/IconStarWithoutBackground'
import { StyledFooterWrapper } from 'entities/footer-of-page/ui/footer-of-page/footer-of-page.styled'
import { DataListDefault } from 'shared/ui/DataList'

export const StyledGroupedLineHeading = styled.div`
  align-items: center;
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.l};
  ${StyledIcon} {
    display: inline-block;
    margin-right: 8px;
  }

  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 20px;
  padding: 4px 16px;
`

export const StyledIconStarWithoutBackground = styled(
  IconStarWithoutBackground
)`
  grid-area: fav;
`

// Get rid of isEmpty if returned to grid layout system
export const StyledLineEvents = styled.div<{ $isEmpty?: boolean }>`
  flex: 1;
  ${({ $isEmpty }) => {
    if ($isEmpty) {
      return css`
        margin: auto;

        & + ${StyledFooterWrapper} {
          margin-top: initial;
        }
      `
    }
  }}
`
export const StyledLiveTable = styled.div`
  flex: 1;
`

export const StyledDataList = styled(DataListDefault)`
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
`
