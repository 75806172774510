import React, {
  createRef,
  forwardRef,
  memo,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import isEqual from 'react-fast-compare'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import { MarketCategoriesInfosByEventItem } from 'astra-core/containers/EventsProvider'

import { Masonry } from 'shared/ui/Masonry'

import { Category } from '../Category'

import {
  CategoriesMasonryProps,
  OutcomeCategoriesHandle
} from './Categories.types'

const TABLES_GAP = 8

export const CategoriesMasonry = memo(
  forwardRef<OutcomeCategoriesHandle, CategoriesMasonryProps>(
    ({ categories, eventId, columnsCount = 2 }, forwardedRef) => {
      const [panelRefs, setPanelRefs] = useState<
        Record<string, RefObject<React.ElementRef<typeof Category>>>
      >({})

      const MasonryRender = useCallback(
        ({ data: category }) => (
          <Category
            category={category}
            eventId={eventId}
            ref={panelRefs[category.marketCategoryId]}
          />
        ),
        [eventId, panelRefs]
      )

      const MasonryItemKey = useCallback(
        ({ marketCategoryId }: MarketCategoriesInfosByEventItem) =>
          marketCategoryId,
        []
      )

      useEffect(() => {
        setPanelRefs((elRefs) =>
          mapValues(
            keyBy(categories, 'marketCategoryId'),
            (value) => elRefs[value.marketCategoryId] ?? createRef()
          )
        )
      }, [categories])

      useImperativeHandle(
        forwardedRef,
        () => ({
          openAll: () => {
            Object.values(panelRefs).forEach((panelRef) => {
              panelRef.current?.open()
            })
          },
          closeAll: () => {
            Object.values(panelRefs).forEach((panelRef) => {
              panelRef.current?.close()
            })
          }
        }),
        [panelRefs]
      )

      return (
        <Masonry<MarketCategoriesInfosByEventItem>
          columnCount={columnsCount}
          columnGutter={TABLES_GAP}
          itemKey={MasonryItemKey}
          items={categories}
          key={eventId}
          overscanBy={Infinity}
          render={MasonryRender}
          rowGutter={TABLES_GAP}
          tabIndex={-1}
        />
      )
    }
  ),
  isEqual
)

CategoriesMasonry.displayName = 'CategoriesMasonry'
