import React, { FC, useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getEventStatusType } from 'astra-core/utils/events'
import { useLocation } from 'react-router-dom'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { ETestData } from 'og-web-core/utils/test-data'

import { EventContext } from 'pages/page-event/Event/Event.context'
import { LayoutEventContext } from 'widgets/Layout/components/Event/context'
import { EventCompleted } from 'pages/page-event-completed/EventCompleted/EventCompleted'
import { TabsItem } from 'shared/ui/Tabs'
import { OutcomesHead } from 'widgets/event-row/ui/outcomes-head'
import { EventOutcomes } from 'pages/page-event/Event/components/EventOutcomes'
import { LinkedEventOutcomes } from 'pages/page-event/Event/components/LinkedEventOutcomes'

import { EventBanner } from './components'
import { EventsTabs, EventTab, IEventProps } from './Event.types'
import {
  StyledEventWrapper,
  StyledHeadWrapper,
  StyledTabs
} from './Event.styled'

const eventsTabsTranslates = {
  [EventsTabs.mainEvent]: 'main event',
  [EventsTabs.linkedEvents]: 'linked events'
}

const eventTabs: EventTab[] = [
  {
    title: eventsTabsTranslates[EventsTabs.mainEvent],
    type: EventsTabs.mainEvent
  },
  {
    title: eventsTabsTranslates[EventsTabs.linkedEvents],
    type: EventsTabs.linkedEvents
  }
]

const toggleTestData = {
  expand: ETestData.TestEventFilterCollapseAll,
  collapsed: ETestData.TestEventFilterCollapseAll
}

export const Event: FC<IEventProps> = ({ event }) => {
  const [t] = useTranslation()
  const isLinkedEventsEnabled = useFeatureFlag(
    EFeatureFlags.LINKED_EVENTS_ENABLED
  )

  const isEventWithLinkedEvents = !!event.linkedEvents?.length
  const shouldShowTabs = isEventWithLinkedEvents && isLinkedEventsEnabled
  const { state } = useLocation<{ linkedEventId: number }>()

  const { isEventBannerScrolled } = useContext(LayoutEventContext) || {}

  const { completedStatus, isSpecialStatus, eventStatusType } =
    getEventStatusType(event)

  const outcomesRef = useRef(null)

  const [activeTab, setActiveTab] = useState<EventsTabs>(() =>
    state?.linkedEventId && shouldShowTabs
      ? EventsTabs.linkedEvents
      : EventsTabs.mainEvent
  )

  const isLinkedEventTab =
    activeTab === EventsTabs.linkedEvents && isLinkedEventsEnabled

  const showOutcomesHead = !eventStatusType || isLinkedEventTab

  if (completedStatus) {
    return <EventCompleted event={event} />
  }

  return (
    <EventContext.Provider value={{ event, isSpecialStatus }}>
      <StyledEventWrapper>
        <StyledHeadWrapper isFixed={isEventBannerScrolled} deleteBorder>
          <EventBanner />

          {shouldShowTabs && (
            <StyledTabs variant={5}>
              {eventTabs.map((tab) => (
                <TabsItem
                  active={tab.type === activeTab}
                  handleTabChange={setActiveTab as (tab: EventsTabs) => void}
                  key={tab.title}
                  type={tab.type}
                >
                  {t(tab.title)}
                </TabsItem>
              ))}
            </StyledTabs>
          )}

          {showOutcomesHead && (
            <OutcomesHead
              event={event}
              inputTestData={ETestData.TestEventInputSearch}
              isLinkedEvent={isLinkedEventTab}
              outcomesRef={outcomesRef}
              toggleTestData={toggleTestData}
              withBorder
            />
          )}
        </StyledHeadWrapper>

        {isLinkedEventTab ? (
          <LinkedEventOutcomes event={event} outcomesRef={outcomesRef} />
        ) : (
          <EventOutcomes event={event} outcomesRef={outcomesRef} />
        )}
      </StyledEventWrapper>
    </EventContext.Provider>
  )
}
