import {
  FIO_VALIDATION_REGEX,
  EMAIL_VALIDATION_REGEX,
  PASSPORT_VALIDATION_REGEX
} from 'astra-core'
import * as Yup from 'yup'

export const SLIDE_ONE_FORMIK_SCHEMA = () =>
  Yup.object().shape({
    secondName: Yup.string()
      .required('required')
      .matches(FIO_VALIDATION_REGEX, 'invalid data format'),
    firstName: Yup.string()
      .required('required')
      .matches(FIO_VALIDATION_REGEX, 'invalid data format'),
    middleName: Yup.string().matches(
      FIO_VALIDATION_REGEX,
      'invalid data format'
    ),
    email: Yup.string()
      .trim()
      .email('invalid email format')
      .required('required')
      .matches(EMAIL_VALIDATION_REGEX, 'invalid data format'),
    iddata: Yup.string()
      .trim()
      .required('required')
      .matches(PASSPORT_VALIDATION_REGEX, 'invalid data format')
      .min(3, 'minimum of 3 characters')
      .max(15, 'maximum of 15 characters')
  })

export const INPUT_DATA_LIST_STEP_ONE = [
  {
    label: 'second name',
    name: 'secondName',
    placeholder: 'enter surname'
  },
  {
    label: 'first name',
    name: 'firstName',
    placeholder: 'enter your name'
  },
  {
    label: 'middle name',
    name: 'middleName',
    placeholder: 'enter patronymic'
  },
  {
    label: 'email',
    name: 'email',
    placeholder: 'enter email address',
    type: ''
  },
  {
    label: 'passport or id number',
    name: 'iddata',
    placeholder: 'enter id number'
  }
]

export const INPUT_TYPE_VERIFICATION = {
  email: 'email'
}
