import React, { FC } from 'react'

import { IconProps } from '../../types'
import { Icon } from '../../Icon'

export const IconEventInterrupted: FC<IconProps> = (props) => (
  <Icon {...props} size={64}>
    <svg
      fill="none"
      height="64"
      viewBox="0 0 64 64"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="currentColor" height="64" rx="8" width="64" />
      <path
        clipRule="evenodd"
        d="M51.7085 16.3027C50.8507 15.9694 49.8733 15.8078 48.816 15.8078C48.4669 15.8078 48.1079 15.828 47.7488 15.8583C47.6491 12.6362 40.6773 10.0404 32.0399 10H31.9402C23.3127 10.0404 16.3409 12.6362 16.2412 15.8684C15.8722 15.8381 15.5231 15.8179 15.174 15.8179C14.1168 15.8179 13.1393 15.9795 12.2816 16.3129C7.11507 18.3027 8.16233 25.8478 14.6254 33.1606C17.0092 35.8473 19.7819 38.1301 22.6046 39.8472C24.5544 42.3269 26.8593 44.3485 29.4194 45.7757C29.9135 46.0511 30.2286 46.5715 30.2096 47.1368C30.1353 49.3445 28.3521 51.0717 26.2265 50.9946L26.2234 50.9945V51.0001H25.6458C24.3697 51.0001 23.3352 52.0746 23.3352 53.4C23.3352 53.7314 23.5938 54 23.9128 54H38.411C34.6273 52.2608 32 48.4372 32 44C32 37.9249 36.9249 33 43 33C43.7779 33 44.5369 33.0807 45.2691 33.2343C46.9942 29.1888 47.9782 24.5462 47.9782 19.6157C47.9782 19.0299 47.9583 18.4542 47.9283 17.8784C48.2276 17.8481 48.5268 17.838 48.816 17.838C49.6538 17.838 50.3819 17.9592 51.0003 18.1916C52.0276 18.5855 52.6261 19.2622 52.8754 20.3127C53.5437 23.1105 51.5888 27.6356 47.8885 31.8172H47.8785C47.3673 32.3966 46.8297 32.9559 46.2729 33.4951C46.9742 33.7134 47.6454 33.9999 48.2787 34.347C48.6524 33.9612 49.0181 33.5656 49.3746 33.1606C55.8377 25.8478 56.8849 18.3027 51.7184 16.3027H51.7085ZM16.1115 31.8172C12.4112 27.6255 10.4464 23.1105 11.1246 20.3127C11.3739 19.2622 11.9724 18.5956 12.9997 18.1916C13.6081 17.9592 14.3462 17.838 15.184 17.838C15.4732 17.838 15.7724 17.8582 16.0716 17.8784C16.0417 18.4542 16.0218 19.0299 16.0218 19.6157C16.0218 25.3933 17.3683 30.7567 19.6722 35.2413C18.4155 34.1908 17.2087 33.0394 16.1215 31.8172H16.1115Z"
        fill="#808080"
        fillRule="evenodd"
      />
      <path
        d="M53 44C53 49.5228 48.5228 54 43 54C37.4772 54 33 49.5228 33 44C33 38.4772 37.4772 34 43 34C48.5228 34 53 38.4772 53 44Z"
        fill="#FF9E00"
      />
      <path
        d="M40.5 41.75C40.5 41.3358 40.8358 41 41.25 41C41.6642 41 42 41.3358 42 41.75V46.25C42 46.6642 41.6642 47 41.25 47C40.8358 47 40.5 46.6642 40.5 46.25V41.75Z"
        fill="#333333"
      />
      <path
        d="M44 41.75C44 41.3358 44.3358 41 44.75 41C45.1642 41 45.5 41.3358 45.5 41.75V46.25C45.5 46.6642 45.1642 47 44.75 47C44.3358 47 44 46.6642 44 46.25V41.75Z"
        fill="#333333"
      />
    </svg>
  </Icon>
)
