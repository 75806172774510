import React, { FC, memo, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useGroupedEventsByGroupId } from 'astra-core'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { selectTournamentCountByKeyAndId } from 'astra-core/containers/SportCountersProvider/selectors'
import { ESportsCounterType } from 'astra-core/containers/SportCountersProvider/types'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { RootState } from 'shared/types/store'
import { LineTournamentProps } from 'pages/page-live-events/Line/components/LineTournaments/LineTournaments.types'
import { useLiveData } from 'pages/page-live-events/LiveContainer/hooks'
import { useLineTournamentContainerData } from 'pages/page-line/LineContainer/hooks'
import { selectLineEvents } from 'pages/page-line-tournament/LineTournamentsContainer/selectors'
import { selectLiveEvents } from 'pages/page-live-events/LiveContainer/selectors'
import { ETableHeadVariant, TableBetsHead } from 'widgets/event-row-head'
import { EventRow } from 'widgets/event-row'

import {
  StyledTournamentShow,
  StyledTournamentTableWrapper
} from './LineTournament.styled'

const LineTournamentList: FC<LineTournamentProps> = memo(({ tournament }) => {
  useLiveData({ sportId: tournament.sportId.toString() })
  useLineTournamentContainerData({
    tournamentId: tournament.id.toString()
  })
  const isNewCountersEndpoint = useFeatureFlag(
    EFeatureFlags.NEW_COUNTERS_ENDPOINT
  )
  const count = useSelector((state) =>
    selectTournamentCountByKeyAndId(
      state,
      ESportsCounterType.LINE,
      tournament.id
    )
  )
  const tournamentCount = isNewCountersEndpoint
    ? count
    : tournament.lineEventCount

  const events = useSelector((state: RootState) => {
    if (tournamentCount) {
      return selectLineEvents(state, {
        tournamentId: tournament.id.toString()
      })
    } else {
      return selectLiveEvents(state, tournament.sportId)
    }
  })

  const eventsGroups = useGroupedEventsByGroupId(events).filter(
    (eventsGroups) => eventsGroups.id === tournament.id
  )

  return !eventsGroups.length ? (
    <LoaderSpinner />
  ) : (
    <>
      {eventsGroups.map((groups) =>
        groups.events.map((event) => <EventRow event={event} key={event.id} />)
      )}
    </>
  )
})

export const LineTournament: FC<LineTournamentProps> = memo(
  ({ tournament }) => {
    const [isOutcomesOpened, setIsOutcomesOpened] = useState(false)

    const toggleOutcomesOpen = useCallback(
      (e) => {
        e.preventDefault()
        setIsOutcomesOpened(!isOutcomesOpened)
      },
      [isOutcomesOpened, setIsOutcomesOpened]
    )

    return (
      <StyledTournamentTableWrapper>
        <StyledTournamentShow
          isCursor={!!tournament?.totalEventCount}
          onClick={toggleOutcomesOpen}
        >
          <TableBetsHead
            isOutcomesOpened={isOutcomesOpened}
            isShowCount={tournament?.totalEventCount}
            isShowOdds={!isOutcomesOpened}
            tournament={tournament}
            variant={ETableHeadVariant.TWO}
          />
        </StyledTournamentShow>
        {!!isOutcomesOpened && <LineTournamentList tournament={tournament} />}
      </StyledTournamentTableWrapper>
    )
  }
)
