import React, { FC, useMemo } from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { getBetOutcomeId, getEventTitle } from 'astra-core'
import { useGetEventFromHistoryLink } from 'og-web-core/utils/events'

import { getHintAttrNameFormatted } from 'constants/styles/components/hintAttr/utils'
import {
  getEventStatisticScoreByPeriod,
  getEventStatisticScoreCurrent
} from 'shared/lib/statistics'
import { IconEventInfo } from 'shared/ui/Icon/Event'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { routesWithoutPrevPath } from 'features/event/row/open-event-page/lib'

import { Mark } from '../Mark'

import { BetHistoryStatistic } from './components'
import { OUTCOME_EVENT_DATE_FORMAT } from './constants'
import { IOutcomeItem, IOutcomes } from './Outcome.types'
import {
  StyledBetContentItem,
  StyledCellOutcome,
  StyledCellOutcomeLink,
  StyledIconInfoCircle,
  StyledIconWrapper,
  StyledInfoIconWrapper,
  StyledInfoIconWrapperContainer,
  StyledOutcomeResultIndicator
} from './Outcome.styled'
import { OutcomeName } from './components/OutcomeName'

export const Outcomes: FC<IOutcomes> = ({ outcomes }) => (
  <>
    {outcomes.map((outcome, index) => {
      const { event, outcomeTypeId, parameters, coefficient } = outcome

      const eventProbability = {
        eventId: event?.id || coefficient,
        outcomeTypeId,
        parameters
      }

      return (
        <OutcomeItem
          isLastItem={index === outcomes.length - 1}
          key={getBetOutcomeId(eventProbability)}
          outcome={outcome}
        />
      )
    })}
  </>
)

export const OutcomeItem: FC<IOutcomeItem> = ({ outcome, isLastItem }) => {
  const date = useMemo(
    () =>
      outcome.event?.eventDate &&
      dayjs(outcome.event?.eventDate).format(OUTCOME_EVENT_DATE_FORMAT),
    [outcome.event?.eventDate]
  )
  const hintAttr = useMemo(
    () => getHintAttrNameFormatted(outcome.event?.tournamentName || '') || {},
    [outcome.event?.tournamentName]
  )

  const hintAttrDescription = useMemo(
    () =>
      getHintAttrNameFormatted(outcome.recalculationReason?.message || '') ||
      {},
    [outcome.recalculationReason?.message]
  )

  const currentScore = getEventStatisticScoreCurrent(
    outcome.event?.basicStatistics?.currentScore
  )

  const scoresByPeriod = getEventStatisticScoreByPeriod(
    outcome.event?.basicStatistics?.scoresByPeriod
  )

  const link = useGetEventFromHistoryLink({
    event: outcome.event,
    routesWithoutPrevPath
  })

  const hasRecalculationWarning = Boolean(outcome.recalculationReason?.message)

  return (
    <StyledBetContentItem isLastItem={isLastItem}>
      <StyledCellOutcome />

      <StyledCellOutcome>{date}</StyledCellOutcome>

      <StyledCellOutcome colSpan={4} {...hintAttr}>
        <StyledIconWrapper>
          <IconEventInfo
            colorProps={{
              name: EColorsNames.Primary,
              type: EColorsTypes.CUSTOM,
              value: 9
            }}
          />
        </StyledIconWrapper>
        <StyledCellOutcomeLink to={link}>
          {outcome.event && getEventTitle(outcome.event, true)}
        </StyledCellOutcomeLink>

        {outcome.event?.live && <Mark float="right" type="live" />}
      </StyledCellOutcome>

      <OutcomeName outcome={outcome} />

      <StyledInfoIconWrapper colSpan={1}>
        <StyledInfoIconWrapperContainer>
          {hasRecalculationWarning && (
            <div {...hintAttrDescription}>
              <StyledIconInfoCircle
                colorProps={{
                  type: EColorsTypes.FIXED,
                  name: EColorsNames.Yellow,
                  value: 49
                }}
              />
            </div>
          )}
          {outcome.coefficient}
        </StyledInfoIconWrapperContainer>
      </StyledInfoIconWrapper>

      <StyledCellOutcome colSpan={3}>
        <StyledOutcomeResultIndicator outcomeStatus={outcome.status} />
        <BetHistoryStatistic
          currentScore={currentScore}
          scoresByPeriod={scoresByPeriod}
        />
      </StyledCellOutcome>
    </StyledBetContentItem>
  )
}
