import React from 'react'

import { IVsportListItem } from './page.types'
import {
  IconVsportGreen,
  IconVsportRed,
  IconVsportBlue,
  IconVsportOrange,
  IconVsportBrown
} from './SvgImages' // TODO move these assets to CDN

export const VSPORT_GAMES_TITLE = 'V-SPORT'

export const VSPORT_GAMES_LIST: Array<IVsportListItem> = [
  {
    id: 'VFEL',
    title: 'english league',
    backgroundSrc: require('assets/images/vsportGames/english-league.png'),
    iconCard: <IconVsportGreen />
  },
  {
    id: 'VFLM',
    title: 'league mode',
    backgroundSrc: require('assets/images/vsportGames/league-mode.png'),
    iconCard: <IconVsportGreen />
  },
  {
    id: 'VFFL',
    title: 'french league',
    backgroundSrc: require('assets/images/vsportGames/french-league.png'),
    iconCard: <IconVsportGreen />
  },
  {
    id: 'VFIL',
    title: 'italian league',
    backgroundSrc: require('assets/images/vsportGames/italian-league.png'),
    iconCard: <IconVsportGreen />
  },
  {
    id: 'VFSL',
    title: 'spanish league',
    backgroundSrc: require('assets/images/vsportGames/spanish-league.png'),
    iconCard: <IconVsportGreen />
  },
  {
    id: 'VFB',
    title: 'bundesliga',
    backgroundSrc: require('assets/images/vsportGames/bundesliga.png'),
    iconCard: <IconVsportGreen />
  },
  {
    id: 'VBL',
    title: 'virtual basketball',
    backgroundSrc: require('assets/images/vsportGames/basketball.png'),
    iconCard: <IconVsportOrange />
  },
  {
    id: 'VTI',
    title: 'tennis',
    backgroundSrc: require('assets/images/vsportGames/tennis.png'),
    iconCard: <IconVsportBlue />
  },
  {
    id: 'VFWC',
    title: 'world cup',
    backgroundSrc: require('assets/images/vsportGames/world-cup.png'),
    iconCard: <IconVsportGreen />
  },
  {
    id: 'VFCC',
    title: 'champions cup',
    backgroundSrc: require('assets/images/vsportGames/champions-cup.png'),
    iconCard: <IconVsportGreen />
  },
  {
    id: 'VDR',
    title: 'dogs kiron',
    backgroundSrc: require('assets/images/vsportGames/dogs-kiron.png'),
    iconCard: <IconVsportBrown />
  },
  {
    id: 'VHC',
    title: 'horses kiron',
    backgroundSrc: require('assets/images/vsportGames/horses-kiron.png'),
    iconCard: <IconVsportBrown />
  },
  {
    id: 'VFEC',
    title: 'euro cup',
    backgroundSrc: require('assets/images/vsportGames/euro-cup.png'),
    iconCard: <IconVsportGreen />
  },
  {
    id: 'VFAS',
    title: 'asian cup',
    backgroundSrc: require('assets/images/vsportGames/asian-cup.png'),
    iconCard: <IconVsportGreen />
  },
  {
    id: 'VFNC',
    title: 'nations cup',
    backgroundSrc: require('assets/images/vsportGames/nations-cup.png'),
    iconCard: <IconVsportGreen />
  },
  {
    id: 'VBI',
    title: 'baseball',
    backgroundSrc: require('assets/images/vsportGames/baseball.png'),
    iconCard: <IconVsportRed />
  }
]
