import { EventsApiGetEventsRequest } from 'betweb-openapi-axios'
import dayjs from 'dayjs'
import { DATE_FORMAT } from 'astra-core/containers/SportCountersProvider/constants'

import {
  LineTournamentFilterPeriod,
  TFormatLineTournamentsFiltersParams
} from './types'

interface GetFetchLineTournamentsReqDataParams {
  sportId?: number
  top?: EventsApiGetEventsRequest['top']
  filterPeriod?: LineTournamentFilterPeriod
  includeSubsports?: boolean
}

export const getFetchLineTournamentsReqData = ({
  sportId,
  top,
  filterPeriod
}: GetFetchLineTournamentsReqDataParams) => ({
  top,
  live: false,
  sportId,
  ...filterPeriod
})

export const LINE_TOURNAMENTS_POLLING_INTERVAL = 5000

export const formatLineTournamentsFiltersData = ({
  isTop,
  period
}: TFormatLineTournamentsFiltersParams) => ({
  top: isTop,
  filterPeriod: {
    ...period,
    scheduledFrom: period?.scheduledFrom || dayjs().format(DATE_FORMAT)
  }
})
