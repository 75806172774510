import styled from 'styled-components'

import { IconList } from 'shared/ui/Icon/General/IconList'

export const StyledResultsWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.custom.primary[2]};
`

export const StyledIconPreloaderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`

export const StyledIconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 20px;
`

export const StyledResultDate = styled.div`
  align-items: center;
  display: flex;
  height: 16px;
  margin-bottom: 1px;
  padding-left: 16px;
  width: 100%;
  ${({ theme }) =>
    `
      background-color: ${theme.colors.default.primary[5]};
      font-size: ${theme.fonts.size.xs};
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.default.primary[50]};
    `}
`

export const StyledClearResult = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  line-height: 16px;
`
export const StyledClearResultIcon = styled(IconList)`
  margin-bottom: 16px;
`
export const StyledClearResultIconTitle = styled.div`
  margin-bottom: 4px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.default.primary[90]};
  `}
`
export const StyledClearResultIconDescription = styled.div`
  text-align: center;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.fixed.primary[50]};
  `}
`
