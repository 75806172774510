import React, { useMemo } from 'react'
import { CompletionStatus } from 'betweb-openapi-axios'
import { useTranslation } from 'react-i18next'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { getEventTitle } from 'astra-core'
import { useGetEventCompletedLink } from 'og-web-core/utils/events'

import {
  getEventStatisticScoreByPeriod,
  getEventStatisticScoreCurrent
} from 'shared/lib/statistics'
import {
  EEventStatisticScoreTypes,
  EventStatisticScore
} from 'widgets/event-statistics'
import { StyledEventStatisticScore } from 'pages/page-bets-history/BetsHistoryPageContainer/components/BetsHistory/components/History/components/Table/components/Outcome/components/BetHistoryStatistic/BetHistoryStatistic.styled'
import { Marquee } from 'shared/ui/Marquee'

import { RESULTS_EVENT_DATE_FORMAT } from '../constants'

import {
  StyledEventCompetitorsByStatus,
  StyledEventDate,
  StyledEventScoreWrapper,
  StyledEventStatisticsWrapper,
  StyledEventStatus,
  StyledEventWrapper,
  StyledLeftSide,
  StyledRightSide
} from './Events.styled'
import { TEventProps } from './Events.types'

export const Event = ({ event, sportId }: TEventProps) => {
  const [t] = useTranslation()

  const { basicStatistics, eventDate, completionStatus } = event

  const date = useMemo(
    () => dayjs(eventDate).format(RESULTS_EVENT_DATE_FORMAT(t('at'))),
    [eventDate, t]
  )

  const currentScore = getEventStatisticScoreCurrent(
    event?.basicStatistics?.currentScore
  )

  const scoresByPeriod = useMemo(
    () => getEventStatisticScoreByPeriod(basicStatistics?.scoresByPeriod),
    [basicStatistics?.scoresByPeriod]
  )

  const liveEventStatus = useMemo(
    () => CompletionStatus.Live === completionStatus && completionStatus,
    [completionStatus]
  )

  const competitors = useMemo(() => getEventTitle(event), [event])
  const link = useGetEventCompletedLink({ event, sportId })

  return (
    <StyledEventWrapper>
      <StyledLeftSide>
        <StyledEventDate>{date}</StyledEventDate>
        <Marquee id={event?.id} isMarqueeCustomColors>
          <StyledEventCompetitorsByStatus to={link}>
            {competitors}
          </StyledEventCompetitorsByStatus>
        </Marquee>
      </StyledLeftSide>
      <StyledRightSide>
        <StyledEventStatisticsWrapper>
          <StyledEventStatus>{liveEventStatus}</StyledEventStatus>
          <StyledEventScoreWrapper>
            {currentScore && (
              <StyledEventStatisticScore
                type={EEventStatisticScoreTypes.DEFAULT}
                value={currentScore}
              />
            )}
            {scoresByPeriod && (
              <EventStatisticScore
                separator="&#44;&nbsp;"
                type={EEventStatisticScoreTypes.BY_PERIOD}
                value={scoresByPeriod}
              />
            )}
          </StyledEventScoreWrapper>
        </StyledEventStatisticsWrapper>
      </StyledRightSide>
    </StyledEventWrapper>
  )
}
