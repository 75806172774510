import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ENotificationLayouts,
  getMessagesPanelPagination,
  messagesProviderActions,
  selectMessages
} from 'astra-core/containers/MessagesProvider'

import { Pagination } from 'hooks/usePagination/usePagination.types'
import { useAppSelector } from 'app/lib/store'

import { PAGINATION_SETTINGS } from './constants'
import { StyledDataList } from './NotificationsList.styled'
import { NotificationListItem } from './components/NotificationListItem'

export const NotificationsList = memo(() => {
  const dispatch = useDispatch()
  const pagination: Pagination = useSelector(getMessagesPanelPagination)
  const notifications = useAppSelector((state) =>
    selectMessages(state, ENotificationLayouts.NOTIFICATIONS_PANEL)
  )

  useEffect(() => {
    return () => {
      dispatch(
        messagesProviderActions.clearMessages({
          layoutType: ENotificationLayouts.NOTIFICATIONS_PANEL
        })
      )
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(
      messagesProviderActions.fetchMessages({
        req: {
          offset: pagination.offset,
          limit: pagination.limit,
          from: '',
          to: '',
          direct: true,
          read: false
        },
        layoutType: ENotificationLayouts.NOTIFICATIONS_PANEL
      })
    )
  }, [dispatch, pagination])

  return notifications.length ? (
    <StyledDataList pagination={PAGINATION_SETTINGS} isHidePreloader>
      {notifications.map((item) => (
        <NotificationListItem key={item.id} notification={item} />
      ))}
    </StyledDataList>
  ) : null
})
