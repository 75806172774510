import { CompetitorStatistics } from 'betweb-openapi-axios'

import { StatisticsItems } from './StatsFootball.types'

export const calculatePercentages = (homeValue: number, awayValue: number) => {
  const totalValue = homeValue + awayValue
  const homePercentage = totalValue === 0 ? 0 : (homeValue / totalValue) * 100
  const awayPercentage = totalValue === 0 ? 0 : (awayValue / totalValue) * 100
  return { homePercentage, awayPercentage }
}

export const filterStatisticsItems = (
  statisticsItems: StatisticsItems<CompetitorStatistics>,
  homeStatistics: CompetitorStatistics,
  awayStatistics: CompetitorStatistics
) => {
  if (!statisticsItems) {
    return []
  }

  return statisticsItems.filter(({ field }) => {
    const homeValue = homeStatistics[field] ?? 0
    const awayValue = awayStatistics[field] ?? 0
    return (homeValue || 0) !== 0 || (awayValue || 0) !== 0
  })
}
