import React, { FC, useMemo } from 'react'
import { getLineTournamentLink, isTopEntity } from 'astra-core'
import { selectTopTagId } from 'astra-core/containers/CommonDataProvider'
import {
  useFeatureFlag,
  EFeatureFlags
} from 'astra-core/containers/ConfigProvider'
import { useSelector } from 'react-redux'
import { ESportsCounterType } from 'astra-core/containers/SportCountersProvider/types'
import { selectTournamentCountByKeyAndId } from 'astra-core/containers/SportCountersProvider/selectors'

import { LineTournamentProps } from './LineTournament.types'
import {
  StyledTournament,
  StyledTournamentAdditional,
  StyledTournamentText,
  StyledTournamentTop
} from './LineTournament.styled'
import { LineTournamentSport } from './LineTournamentSport'

export const LineTournament: FC<LineTournamentProps> = ({
  tournament,
  showSport,
  testData
}) => {
  const isEntityTagsEnabled = useFeatureFlag(EFeatureFlags.ENTITY_TAGS_ENABLED)
  const isNewCountersEndpoint = useFeatureFlag(
    EFeatureFlags.NEW_COUNTERS_ENDPOINT
  )

  const tournamentLink = useMemo(
    () => getLineTournamentLink(tournament),
    [tournament]
  )
  const topTagId = useSelector(selectTopTagId)

  const isTopTournament = isEntityTagsEnabled
    ? isTopEntity(tournament, topTagId)
    : tournament.top

  const count = useSelector((state) =>
    selectTournamentCountByKeyAndId(
      state,
      ESportsCounterType.LINE,
      tournament.id
    )
  )

  return (
    <StyledTournament>
      {/* <StyledIconStarWithoutBackground // TODO handle favourite tournaments
          colorProps={{ name: EColorsNames.Primary, value: 40 }}
          size={14}
        /> */}

      <StyledTournamentText data-test-id={testData} to={tournamentLink}>
        {isTopTournament && <StyledTournamentTop>TOP</StyledTournamentTop>}
        {!!showSport && <LineTournamentSport tournament={tournament} />}
        {tournament.name}

        <StyledTournamentAdditional>
          {isNewCountersEndpoint ? count : tournament.lineEventCount}
        </StyledTournamentAdditional>
      </StyledTournamentText>
    </StyledTournament>
  )
}
