import React, { FC } from 'react'

import {
  StyledOddsDivider,
  StyledOddsDividerBottom,
  StyledOddsDividerCenter,
  StyledOddsDividerTop
} from './OutcomesTable.styled'

export const OddsDivider: FC = () => (
  <StyledOddsDivider>
    <StyledOddsDividerTop />
    <StyledOddsDividerCenter />
    <StyledOddsDividerBottom />
  </StyledOddsDivider>
)
