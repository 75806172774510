import React, { FC, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import videojs from 'video.js'
import Player from 'video.js/dist/types/player'
import { getEventTitle } from 'astra-core/utils/events'
import { selectEventById } from 'astra-core/containers/EventsProvider/selectors'
import { selectAccount } from 'astra-core/containers/AccountProvider/selectors'
import {
  selectBroadcastAvailabilityByEventId,
  selectBroadcastByEventId
} from 'astra-core/containers/BroadcastProvider/selectors'
import { usePrevious } from 'astra-core/hooks/usePrevious'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { selectIsDevelopment } from 'astra-core/containers/ConfigProvider/selectors'
import { ENV, getEnv } from 'astra-core/utils/env'
import { BroadcastType } from 'betweb-openapi-axios'
import { broadcastProviderActions } from 'astra-core/containers/BroadcastProvider/slice'

import { IconStreamFilled } from 'shared/ui/Icon/General/IconStreamFilled'
import { matchBroadcastModel } from 'entities/match-broadcast'
import { useInterval } from 'hooks'
import { BROADCAST_POLLING_INTERVAL } from 'containers/EventContainer/utils'
import { useAppSelector } from 'app/lib/store'

import {
  StyledBroadcastWrapper,
  StyledCloseIcon,
  StyledBroadcastHeader,
  StyledCompetitors,
  StyledBroadcastTitle,
  StyledNotAuthorized,
  StyledNotAuthorizedText
} from './live-match-tracker.styled'

import 'video.js/dist/video-js.css'

const getRedirectUrl = (isDevelopment: boolean) => {
  if (isDevelopment) return 'https://astrabet-mobile.astra-stage.betdev.cloud'
  return window?.location?.origin || ''
}

const options = {
  autoplay: false,
  controls: true,
  responsive: true,
  fluid: true
}
export const LiveMatchBroadcast: FC = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const currentBroadcastEvent = useSelector(
    matchBroadcastModel.selectCurrentBroadcast
  )

  const account = useSelector(selectAccount)
  const isDevelopment = useSelector(selectIsDevelopment)
  const isLogged = !!account
  const prevLogged = usePrevious(isLogged)

  const isBroadcastFeatureFlagEnabled = useFeatureFlag(
    EFeatureFlags.BROADCAST_ENABLED
  )
  const env = getEnv()
  const broadcastEnabled =
    currentBroadcastEvent &&
    isBroadcastFeatureFlagEnabled &&
    [ENV.STAGE, ENV.PROD].includes(env)

  const hasBroadcast = useAppSelector((state) => {
    if (!currentBroadcastEvent) return false
    return selectBroadcastAvailabilityByEventId(state, currentBroadcastEvent)
  })
  const prevHasBroadcast = usePrevious(hasBroadcast)

  const fetchEventBroadcast = useCallback(() => {
    if (broadcastEnabled && hasBroadcast && isLogged) {
      dispatch(
        broadcastProviderActions.fetchBroadcast({
          reqData: {
            eventId: currentBroadcastEvent,
            platform: 'd_web',
            preferredBroadcastType: BroadcastType.Hls,
            redirectUrl: getRedirectUrl(isDevelopment)
          }
        })
      )
    }
  }, [
    dispatch,
    isDevelopment,
    hasBroadcast,
    broadcastEnabled,
    isLogged,
    currentBroadcastEvent
  ])

  useEffect(() => {
    if (!prevHasBroadcast && hasBroadcast) {
      fetchEventBroadcast()
    } else if (!prevLogged && isLogged) {
      fetchEventBroadcast()
    }
  }, [
    prevHasBroadcast,
    hasBroadcast,
    fetchEventBroadcast,
    prevLogged,
    isLogged
  ])

  useInterval(fetchEventBroadcast, BROADCAST_POLLING_INTERVAL, true)

  const broadcast = useAppSelector((state) => {
    if (!currentBroadcastEvent) return undefined
    return selectBroadcastByEventId(state, currentBroadcastEvent)
  })
  const event = useAppSelector((state) => {
    if (!currentBroadcastEvent) return null
    return selectEventById(state, currentBroadcastEvent)
  })

  const videoRef = useRef<HTMLDivElement>(null)
  const playerRef = useRef<Player | null>()

  const isBroadcastExist =
    !!currentBroadcastEvent &&
    !!account &&
    !!broadcast?.broadcastUrl &&
    broadcast.broadcastType === BroadcastType.Hls

  useEffect(() => {
    if (isBroadcastExist) {
      if (!playerRef.current) {
        const videoElement = document.createElement('video-js')
        videoElement.classList.add('vjs-big-play-centered')
        if (videoRef.current) {
          videoRef.current.innerHTML = ''
          videoRef.current.appendChild(videoElement)
        }
        playerRef.current = videojs(videoElement, {
          ...options,
          sources: [
            {
              src: broadcast.broadcastUrl,
              type: 'application/x-mpegURL'
            }
          ],
          poster: broadcast?.thumbnailUrl || undefined
        })
      }
    }
  }, [
    videoRef,
    isBroadcastExist,
    broadcast?.broadcastUrl,
    broadcast?.thumbnailUrl
  ])

  const disposePlayer = useCallback(() => {
    const player = playerRef.current
    if (player && !player.isDisposed()) {
      player.dispose()
      playerRef.current = null
    }
  }, [])

  useEffect(() => {
    return () => {
      disposePlayer()
    }
  }, [disposePlayer])

  useEffect(() => {
    if (!isBroadcastExist) {
      disposePlayer()
    }
  }, [disposePlayer, isBroadcastExist])

  const handleClick = () => {
    dispatch(
      matchBroadcastModel.liveMatchBroadcastActions.clearCurrentBroadcast()
    )
  }

  if (!(currentBroadcastEvent && event)) {
    return null
  }

  return (
    <StyledBroadcastWrapper>
      {isBroadcastExist && (
        <StyledBroadcastHeader>
          <StyledBroadcastTitle>
            <IconStreamFilled />
            <StyledCompetitors>{getEventTitle(event, false)}</StyledCompetitors>
          </StyledBroadcastTitle>

          <StyledCloseIcon size={12} onClick={handleClick} />
        </StyledBroadcastHeader>
      )}

      <div data-vjs-player>
        {account ? (
          <div ref={videoRef} />
        ) : (
          <StyledNotAuthorized>
            <IconStreamFilled size={32} />
            <StyledNotAuthorizedText
              dangerouslySetInnerHTML={{
                __html: t('you need to authorize to view translation')
              }}
            />
          </StyledNotAuthorized>
        )}
      </div>
    </StyledBroadcastWrapper>
  )
}
