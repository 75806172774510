import styled, { css } from 'styled-components'

import { IStyledBetHeader } from '../../Table.types'

export const StyledRowDateRow = styled.tr`
  position: sticky;
  top: 29px;
  z-index: 1;
`

export const StyledRowDateColumn = styled.td`
  background: ${(props) => props.theme.colors.default.primary[5]};
  color: ${(props) => props.theme.colors.fixed.primary[50]};
  font-size: ${(props) => props.theme.fonts.size.xs};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  padding: 4px 8px;
`

export const StyledRowBet = styled.tr<IStyledBetHeader>`
  td {
    border-bottom: ${(props) => (props.isCollapsed ? '4px' : '1px')} solid
      ${(props) => props.theme.colors.default.primary[5]};
  }

  ${({ theme, isCurrentCashoutExist }) => css`
    ${!isCurrentCashoutExist &&
    css`
      &:hover:not(:has(.disable_hover_on_child:hover)) {
        background: ${theme.colors.custom.primary[31]};
        cursor: pointer;
      }
    `}
  `}
`
