import styled, { css } from 'styled-components'

export const QuickAmountsSettingsWrapper = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 32px 32px 32px;
`

export const QuickAmountsSettingsTitle = styled.div`
  line-height: 20px;
  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.l};
      font-weight: ${theme.fonts.weight.bold};
    `}
`
