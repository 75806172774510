import React from 'react'
import { useSearchResultsPulling } from 'og-web-core/providers/search/hooks/usePullingSearchResult'

import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'
import {
  reducerLine,
  sliceKeyLine
} from 'pages/page-line-tournament/LineTournamentsContainer/slice'
import {
  reducerLive,
  sliceKeyLive
} from 'pages/page-live-events/LiveContainer/slice'
import { lineTournamentsContainerSaga } from 'pages/page-line-tournament/LineTournamentsContainer/saga'
import { liveContainerSaga } from 'pages/page-live-events/LiveContainer/saga'
import { Search } from 'widgets/FullRegistration/Search'

export const SearchContainer = () => {
  useInjectReducer({ key: sliceKeyLine, reducer: reducerLine })
  useInjectReducer({ key: sliceKeyLive, reducer: reducerLive })
  useInjectSaga({ key: sliceKeyLine, saga: lineTournamentsContainerSaga })
  useInjectSaga({ key: sliceKeyLive, saga: liveContainerSaga })
  useSearchResultsPulling()

  return <Search />
}
