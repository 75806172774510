import React, { FC } from 'react'

import { IconProps } from '../../types'
import { Icon } from '../../Icon'

export const IconStatisticsNotAvailable: FC<IconProps> = (props) => (
  <Icon {...props} size={64}>
    <svg
      fill="none"
      height="64"
      viewBox="0 0 64 64"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="currentColor" height="64" rx="8" width="64" />
      <path
        d="M22 12C20.8954 12 20 12.8954 20 14V50C20 51.1046 20.8954 52 22 52H26C27.1046 52 28 51.1046 28 50V14C28 12.8954 27.1046 12 26 12H22Z"
        fill="#808080"
      />
      <path
        d="M12 36C10.8954 36 10 36.8954 10 38V50C10 51.1046 10.8954 52 12 52H16C17.1046 52 18 51.1046 18 50V38C18 36.8954 17.1046 36 16 36H12Z"
        fill="#808080"
      />
      <path
        d="M30 30C30 28.8954 30.8954 28 32 28H36C37.1046 28 38 28.8954 38 30V32.7789C34.9898 34.7416 33 38.1384 33 42C33 45.7385 34.865 49.0414 37.7153 51.0291C37.3655 51.6108 36.7282 52 36 52H32C30.8954 52 30 51.1046 30 50V30Z"
        fill="#808080"
      />
      <path
        d="M48 31.7499V22C48 20.8954 47.1046 20 46 20H42C40.8954 20 40 20.8954 40 22V31.7499C41.2397 31.2657 42.5888 31 44 31C45.4112 31 46.7603 31.2657 48 31.7499Z"
        fill="#808080"
      />
      <path
        d="M54 42C54 47.5228 49.5228 52 44 52C38.4772 52 34 47.5228 34 42C34 36.4772 38.4772 32 44 32C49.5228 32 54 36.4772 54 42Z"
        fill="#F54D3D"
      />
      <path
        d="M48.7896 37.5962C48.5943 37.4009 48.2777 37.4009 48.0824 37.5962L44.1934 41.4853L40.3043 37.5962C40.109 37.4009 39.7924 37.4009 39.5972 37.5962C39.4019 37.7914 39.4019 38.108 39.5972 38.3033L43.4863 42.1924L39.5972 46.0815C39.4019 46.2767 39.4019 46.5933 39.5972 46.7886C39.7924 46.9839 40.109 46.9838 40.3043 46.7886L44.1934 42.8995L48.0824 46.7886C48.2777 46.9839 48.5943 46.9838 48.7896 46.7886C48.9848 46.5933 48.9848 46.2767 48.7896 46.0815L44.9005 42.1924L48.7896 38.3033C48.9848 38.1081 48.9848 37.7915 48.7896 37.5962Z"
        fill="white"
      />
    </svg>
  </Icon>
)
