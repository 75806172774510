import { OddsTableProps, ProbabilityWithOutcome } from 'astrabet-templates-kit'
import { ParameterType } from 'betweb-openapi-axios'
import keyBy from 'lodash/keyBy'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'

const getArrayDepth = (value) =>
  Array.isArray(value) ? 1 + Math.max(...value.map(getArrayDepth)) : 0

const wrapTitle = (title: OddsTableProps['title']): string[][] =>
  getArrayDepth(title) === 2
    ? (title as string[][])
    : wrapTitle([title] as string | string[] | string[][])

const formatTitle = (
  title: string,
  probabilities: ProbabilityWithOutcome[]
): string => {
  const probsParams = keyBy(
    probabilities.map((p) => p.parameters).flat(),
    'type'
  )

  return Object.values(ParameterType).reduce((res, cur) => {
    res = res.replaceAll(`{$${cur}}`, probsParams[cur]?.value ?? '')
    return res
  }, title ?? '')
}

const replaceCompetitors = (
  title: string,
  competitors: OddsTableProps['competitors']
): string => {
  return title
    .replaceAll(
      '{$competitor1}',
      competitors.homeCompetitors.map((c) => c.name).join(', ')
    )
    .replaceAll(
      '{$competitor2}',
      competitors.awayCompetitors.map((c) => c.name).join(', ')
    )
}

const removeEmptyElements = (arr: string[][]) => {
  const nonEmptyElements = filter(arr, (item) => !isEmpty(item))
  return isEmpty(nonEmptyElements) ? [''] : nonEmptyElements
}

export const formatTitles = (
  title: OddsTableProps['title'],
  probabilities: ProbabilityWithOutcome[],
  competitors: OddsTableProps['competitors']
): string[] | string[][] => {
  const wrappedTitles = wrapTitle(title)
  const result: string[][] = []
  wrappedTitles.forEach((titles) => {
    if (titles !== undefined) {
      const titlesArr: string[] = []
      titles.forEach((t) => {
        if (t !== undefined) {
          const formattedTitle = formatTitle(t, probabilities)
          titlesArr.push(replaceCompetitors(formattedTitle, competitors))
        }
      })
      result.push(titlesArr)
    }
  })
  return removeEmptyElements(result)
}
