import styled, { css } from 'styled-components'

import { IconMoney } from 'shared/ui/Icon/General/IconMoney'
import { Button } from 'shared/ui/Button'
import { HINT_ATTR_DATA } from 'constants/styles/components/hintAttr/constants'

import { StyledCell } from '../Cell.styled'

export const CellEventBetCountIcon = styled(IconMoney)`
  margin-right: 8px;
`
export const StyledCellEventNum = styled(StyledCell)`
  width: 0;
`

export const StyledCellEventTitle = styled(StyledCell)`
  padding-left: 0;
  padding-right: 10px;
  width: 0;
`

export const StyledCellEventCashoutWrapper = styled(StyledCell)`
  padding: 0;
  padding-right: 8px;
  position: relative;
  width: 0;
`

export const StyledCellEventCashoutText = styled(Button)`
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 24px;
  justify-content: center;
  line-height: 16px;
  min-width: 130px;
  padding: 4px 8px;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.custom.primary[4]};
      color: ${theme.colors.custom.primary[7]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.normal};

      &${HINT_ATTR_DATA.body} {
        background-color: ${theme.colors.custom.primary[41]};
        color: ${theme.colors.custom.primary[77]};
        height: fit-content;
        left: -86px;
        margin-bottom: 62px;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        position: absolute;
        right: -86px;
        text-align: left;
        white-space: normal;
      }

      &${HINT_ATTR_DATA.arrow} {
        border-bottom-color: ${theme.colors.custom.primary[41]};
        margin-bottom: 33px;

        margin-left: calc(50% - 8px);
        transform: rotate(180deg);
      }
    `}
`

export const CurrentCashoutModalWholeBackground = styled.div`
  cursor: default;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
`

export const StyledCellEventBetCount = styled(StyledCell)`
  align-items: center;
  box-sizing: initial;
  display: flex;
  padding-left: 0;
  white-space: nowrap;
  width: 100%;
`

export const StyledBetAmount = styled.div`
  align-items: center;
  display: flex;
  gap: 2px;
`
