/**
 * Create the store with dynamic reducers
 */

import { configureStore, StoreEnhancer } from '@reduxjs/toolkit'
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors'
import createSagaMiddleware from 'redux-saga'
import { settingsApiMiddleware } from 'astra-core/containers/SettingsProvider/api'
import { basketApiMiddleware } from 'astra-core/containers/BasketProvider/api'

import { RootState } from 'shared/types/store'

import { createReducer } from './reducers'

type ConfigureAppStoreParams = {
  preloadedState?: RootState
}

export function configureAppStore({ preloadedState }: ConfigureAppStoreParams) {
  const reduxSagaMonitorOptions = {}
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)
  const { run: runSaga } = sagaMiddleware

  // Create the store with saga middleware
  // TODO: ts type error on settingsApiMiddleware basketApiMiddleware
  const middlewares: any = [
    sagaMiddleware,
    settingsApiMiddleware,
    basketApiMiddleware
  ]

  const store = configureStore({
    reducer: () => createReducer(),
    middleware(getDefaultMiddleware) {
      return getDefaultMiddleware({ serializableCheck: false }).concat(
        middlewares
      )
    },
    devTools: process.env.NODE_ENV !== 'production' && {
      trace: true,
      traceLimit: 25
    },
    enhancers(getDefault) {
      return getDefault().concat(
        createInjectorsEnhancer({
          createReducer,
          runSaga
        }) as StoreEnhancer
      )
    },
    ...(!!preloadedState && { preloadedState })
  })

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      forceReducerReload(store)
    })
  }

  return store
}

export type AppStore = ReturnType<typeof configureAppStore>
export type AppDispatch = AppStore['dispatch']
