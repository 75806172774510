import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectEventById } from 'astra-core/containers/EventsProvider/selectors'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { useCompletedEventContainer } from 'containers/EventContainer/useCompletedEventContainer'
import { EventParams } from 'containers/EventContainer/types'
import { RootState } from 'shared/types/store'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'

import { EventCompleted } from './EventCompleted/EventCompleted'
import { LayoutResultsContext } from './EventCompleted.context'

export const PageEventCompleted = () => {
  useCompletedEventContainer()

  const { eventId } = useParams<EventParams>()

  const event = useSelector((state: RootState) =>
    selectEventById(state, +eventId)
  )

  return (
    <LayoutResultsContext.Provider value={{ hideHeaderResults: true }}>
      <Layout type={ELayoutsList.RESULTS}>
        {event ? <EventCompleted event={event} /> : <LoaderSpinner />}
      </Layout>
    </LayoutResultsContext.Provider>
  )
}
