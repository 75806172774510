import { getSportById } from 'astra-core/containers/CommonDataProvider'
import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Sport } from 'betweb-openapi-axios'

import { selectFilterSportId } from 'pages/page-line-tournament/LineTournamentsContainer/selectors'
import { lineTournamentsContainerActions } from 'pages/page-line-tournament/LineTournamentsContainer/slice'
import { LineTournamentsParams } from 'pages/page-line-tournament/LineTournamentsContainer/types'
import { GenericSportsList } from 'widgets/generic-sport'
import { RootState } from 'shared/types/store'

import { LineTournamentSportListProps } from './LineTournamentSportList.types'

export const LineTournamentSportList: FC<LineTournamentSportListProps> = ({
  sports
}) => {
  const sportIdFilter = useSelector(selectFilterSportId)
  const { sportId } = useParams<LineTournamentsParams>()

  const parentSport = useSelector((state: RootState) =>
    getSportById(state, +sportId)
  )

  const dispatch = useDispatch()
  const onFilterClick = useCallback(
    (sportId?: Sport['id']) => {
      dispatch(
        lineTournamentsContainerActions.setFilterSportId({
          sportId
        })
      )
    },
    [dispatch]
  )
  return (
    <GenericSportsList
      activeSportId={sportIdFilter}
      childrenSports={sports}
      parentSport={parentSport!}
      onClickItem={onFilterClick}
    />
  )
}
