import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledTournament = styled.div`
  align-items: center;
  display: grid;
  font-size: ${(props) => props.theme.fonts.size.m};
  grid-template-areas: 'fav text';
  grid-template-columns: 16px 1fr 1fr;
  padding: 7px 0;

  &:hover {
    background-color: ${(props) => props.theme.colors.custom.primary[4]};
  }
`

export const StyledTournamentText = styled(Link)`
  ${(props) => css`
    align-items: center;
    color: ${props.theme.colors.custom.primary[13]};
    display: flex;
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.normal};
    grid-area: text;
    line-height: 18px;
  `}
`

export const StyledTournamentTop = styled.span`
  background-color: ${(props) => props.theme.colors.default.blue[70]};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.custom.primary[15]};
  font-size: ${(props) => props.theme.fonts.size.xxxxs};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  line-height: ${(props) => props.theme.fonts.size.l};
  margin-right: 4px;
  padding: 0 3px;
`

export const StyledTournamentAdditional = styled.span`
  color: ${(props) => props.theme.colors.custom.primary[9]};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  padding-left: 8px;
`
