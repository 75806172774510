import styled, { css } from 'styled-components'

import { StyledTabsItem } from 'shared/ui/Tabs/Tabs.styled'
import { IconChevronLeft } from 'shared/ui/Icon/General/IconChevronLeft'
import { IconChevronRight } from 'shared/ui/Icon/General/IconChevronRight'

export const StyledScrollContainer = styled.div`
  overflow: hidden;
  position: relative;

  &::after {
    background: linear-gradient(
      to right,
      transparent,
      ${({ theme }) => theme.colors.custom.primary[2]}
    );
    content: '';
    height: 100%;
    pointer-events: none;
    position: absolute; /* Ширина тени */
    right: 0;
    top: 0;
    width: 30px;
  }
`

export const StyledTabsWrapper = styled.div`
  overflow-x: scroll;
  padding-right: 25px;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledTabItemWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.custom.primary[4]};
  border-radius: 4px;
  margin-right: 6px;

  ${StyledTabsItem} {
    height: 100%;
    width: max-content;
  }
`

const ScrollButtonStyles = css`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.custom.primary[4]};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 27px;
  justify-content: center;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.3s;
  width: 26px;
  z-index: 10;

  &:hover {
    background-color: ${({ theme }) => theme.colors.custom.primary[81]};
    opacity: 1;
  }

  ${StyledTabsWrapper}:hover & {
    opacity: 1;
  }
`

export const StyledLeftScrollButton = styled(IconChevronLeft)`
  ${ScrollButtonStyles};
  left: 0;
`

export const StyledRightScrollButton = styled(IconChevronRight)`
  ${ScrollButtonStyles};
  right: 0;
`
