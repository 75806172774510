import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DefaultCountersReq } from 'astra-core/containers/SportCountersProvider/constants'
import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider/slice'
import { ESportsCounterType } from 'astra-core/containers/SportCountersProvider/types'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'

import { actions as calendarEventsContainerActions } from 'containers/CalendarEventsContainer/slice'

import { CalendarMenuList } from './components'
import {
  StyledCalendarMenuTitle,
  StyledSportsMenu,
  StyledTabsWrapper,
  StyledWrapper
} from './calendar-menu.styled'

export const CalendarMenu = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const isNewCountersEndpoint = useFeatureFlag(
    EFeatureFlags.NEW_COUNTERS_ENDPOINT
  )

  useEffect(() => {
    if (isNewCountersEndpoint) {
      const reqData = DefaultCountersReq.CALENDAR

      dispatch(
        sportCountersProviderActions.fetchEventsSportCounters({
          key: ESportsCounterType.CALENDAR,
          // @ts-ignore
          reqData
        })
      )
    } else {
      dispatch(calendarEventsContainerActions.fetchScheduledEventsCounters())
    }
  }, [dispatch, isNewCountersEndpoint])

  return (
    <StyledSportsMenu>
      <StyledWrapper>
        <StyledTabsWrapper>
          <StyledCalendarMenuTitle>
            {t('kind of sport')}
          </StyledCalendarMenuTitle>
        </StyledTabsWrapper>
        <CalendarMenuList />
      </StyledWrapper>
    </StyledSportsMenu>
  )
}
