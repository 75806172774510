import styled from 'styled-components'

import { Layout } from 'widgets/Layout'
import { StyledContent } from 'widgets/Layout/components/Default/Default.styled'

export const LayoutStyled = styled(Layout)`
  ${StyledContent} {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    iframe {
      border: none;
      height: 100%;
      outline: none;
      width: 100%;
    }
  }
`
