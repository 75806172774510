import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  accountProviderActions,
  selectChangePassword,
  selectIsShowModalSuccess
} from 'astra-core/containers/AccountProvider'
import {
  EFeatureFlags,
  selectConfig,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { Captcha as CaptchaDeprecated } from 'og-web-core/features/captcha-deprecated'
import { selectHuaweiCaptchaInfo } from 'og-web-core/providers/captcha/selectors'
import { CaptchaProviderType } from 'betweb-openapi-axios'
import { Captcha } from 'og-web-core/features/captcha'

import { ETestData, getCreateTestData } from 'shared/lib/testData'
import { IconHideEye } from 'shared/ui/Icon/General/IconHideEye'
import { IconShowEye } from 'shared/ui/Icon/General/IconShowEye'
import { EInputView } from 'shared/ui/Input'
import { IconSuccess } from 'shared/ui/Icon/General/IconSuccess'
import { EColorsNames } from 'shared/types/theme'
import { Modal, ViewComponentType } from 'shared/ui/modal'

import {
  StyledButtonWrapper,
  StyledSectionPasswordModalWrapper,
  StyledSectionPasswordWrapper
} from '../Section/Section.styled'
import { PasswordConditional } from '../PasswordConditional'

import { REG_PASSWORD_SEPARATED, SECTION_PASSWORD_DATA } from './constants'
import {
  StyledTitle,
  StyledButton,
  StyledIconRightButton,
  StyledInputWrappers,
  StyledPasswordInput,
  StyledInputWrapper,
  StyledInputTextError,
  StyledModalSectionPassword,
  StyledModalSuccessText,
  StyledModalSuccessTextClose,
  StyledModalSectionPasswordHeader,
  StyledModalSectionPasswordHeaderExit,
  StyledPasswordConditionalWrapper,
  StyledModalChangePasswordHeaderWrapper,
  StyledModalChangePasswordHeaderTitle
} from './SectionPassword.styled'
import { ISectionData, ISectionPasswordProps } from './SectionPassword.types'

export const SectionPasswordInput = memo(
  ({ customType, title, input }: ISectionData) => {
    const [t] = useTranslation()
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)

    const { password, errors } = useSelector(selectChangePassword)

    const errorInput = errors.filter((e) => e.type === customType)[0]

    const handleChangeInput = useCallback(
      (e) =>
        dispatch(
          accountProviderActions.setChangePassword({
            [customType]: e.target.value
          })
        ),
      [customType, dispatch]
    )

    useEffect(() => {
      return () => {
        dispatch(accountProviderActions.resetChangePassword())
      }
    }, [dispatch])

    return (
      <>
        <StyledTitle>{t(title)}</StyledTitle>
        <StyledInputWrappers>
          <StyledInputWrapper>
            <StyledPasswordInput
              data-test-id={getCreateTestData(
                ETestData.TestChangeUserPassword,
                title
              )}
              placeholder={t(input.placeholder)}
              type={show ? 'text' : 'password'}
              view={errorInput?.text ? EInputView.ERROR : EInputView.THIRD}
              onChange={(e) => handleChangeInput(e)}
            />
            <StyledIconRightButton onClick={() => setShow(!show)}>
              {password[customType].length ? (
                show ? (
                  <IconShowEye size={14} />
                ) : (
                  <IconHideEye size={14} />
                )
              ) : (
                ''
              )}
            </StyledIconRightButton>
          </StyledInputWrapper>
          {!!errorInput && (
            <StyledInputTextError
              data-test-id={getCreateTestData(
                ETestData.TestChangePasswordError,
                errorInput?.text
              )}
            >
              {t(errorInput?.text || '')}
            </StyledInputTextError>
          )}
        </StyledInputWrappers>
      </>
    )
  }
)

export const SectionPassword: FC<ISectionPasswordProps> = memo(
  ({ isShownChangePasswordModal, setIsShownChangePasswordModal }) => {
    const [t] = useTranslation()
    const dispatch = useDispatch()
    const newCaptchaEnabled = useFeatureFlag(EFeatureFlags.NEW_CAPTCHA_ENABLED)
    const config = useSelector(selectConfig)

    const captchaProvider = config.CAPTCHA_PROVIDER

    const { recaptchaToken, isDisabledBtn, password, errors } =
      useSelector(selectChangePassword)
    const huaweiCaptchaInfo = useSelector(selectHuaweiCaptchaInfo)
    const isShowModal = useSelector(selectIsShowModalSuccess)

    const handleReCaptcha = useCallback(
      (reCaptchaToken) =>
        dispatch(accountProviderActions.setReCaptchaToken(reCaptchaToken)),
      [dispatch]
    )

    const handleSubmit = useCallback(
      () => dispatch(accountProviderActions.fetchChangePassword()),
      [dispatch]
    )

    useEffect(() => {
      if (isShowModal) {
        setIsShownChangePasswordModal(false)
      }
    }, [isShowModal, setIsShownChangePasswordModal])

    const onModalClose = useCallback(() => {
      setIsShownChangePasswordModal(false)
      dispatch(accountProviderActions.fetchChangePasswordSuccess(false))
    }, [dispatch, setIsShownChangePasswordModal])

    const huaweiCaptchaEmpty =
      newCaptchaEnabled &&
      captchaProvider === CaptchaProviderType.Huawei &&
      !huaweiCaptchaInfo

    return (
      <>
        <Modal
          isOpen={isShownChangePasswordModal}
          view={ViewComponentType.FIRST}
          onModalClose={() => setIsShownChangePasswordModal(false)}
        >
          <StyledModalChangePasswordHeaderWrapper>
            <StyledModalChangePasswordHeaderTitle>
              {t('change user password')}
            </StyledModalChangePasswordHeaderTitle>
            <StyledModalSectionPasswordHeaderExit
              colorProps={{ name: EColorsNames.Primary, value: 30 }}
              size={12}
              onClick={() => setIsShownChangePasswordModal(false)}
            />
          </StyledModalChangePasswordHeaderWrapper>
          <StyledSectionPasswordModalWrapper>
            <StyledSectionPasswordWrapper>
              {SECTION_PASSWORD_DATA.map((item) => (
                <SectionPasswordInput key={item.title} {...item} />
              ))}

              <StyledPasswordConditionalWrapper>
                <PasswordConditional
                  afterSubmit={!!errors.length}
                  password={password.newPassword}
                  regExp={REG_PASSWORD_SEPARATED.symbolsCounts}
                  title="at least 6 characters"
                />

                <PasswordConditional
                  afterSubmit={!!errors.length}
                  password={password.newPassword}
                  regExp={REG_PASSWORD_SEPARATED.latinLetter}
                  title="mandatory entry in Latin"
                />
              </StyledPasswordConditionalWrapper>

              {newCaptchaEnabled ? (
                <Captcha />
              ) : (
                <CaptchaDeprecated
                  value={recaptchaToken}
                  onChange={handleReCaptcha}
                />
              )}

              <StyledButtonWrapper>
                <StyledButton
                  data-test-id={ETestData.TestUpdatePasswordButton}
                  disabled={isDisabledBtn || huaweiCaptchaEmpty}
                  onClick={handleSubmit}
                >
                  {t('update password')}
                </StyledButton>
              </StyledButtonWrapper>
            </StyledSectionPasswordWrapper>
          </StyledSectionPasswordModalWrapper>
        </Modal>

        <Modal
          isOpen={isShowModal}
          view={ViewComponentType.NOTIFICATION}
          onModalClose={onModalClose}
        >
          <StyledModalSectionPasswordHeader>
            <StyledModalSectionPasswordHeaderExit
              colorProps={{ name: EColorsNames.Primary, value: 30 }}
              data-test-id={ETestData.TestPasswordUpdatedSuccessfullyClose}
              size={10}
              onClick={onModalClose}
            />
          </StyledModalSectionPasswordHeader>
          <StyledModalSectionPassword>
            <IconSuccess size={40} />
            <StyledModalSuccessText
              data-test-id={ETestData.TestPasswordUpdatedSuccessfully}
            >
              {t('password updated successfully')}
            </StyledModalSuccessText>
            <StyledModalSuccessTextClose
              data-test-id={ETestData.TestBackToProfile}
              onClick={onModalClose}
            >
              {t('back to profile')}
            </StyledModalSuccessTextClose>
          </StyledModalSectionPassword>
        </Modal>
      </>
    )
  }
)
