import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Tabs } from 'shared/ui/Tabs'
import { StyledTabsItem } from 'shared/ui/Tabs/Tabs.styled'

export const StyledEventWrapper = styled.div`
  padding: 8px 0 0;
`

export const StyledEventHead = styled(Link)<{ isFixed?: boolean }>`
  align-items: center;
  color: ${({ theme }) => theme.colors.default.primary[90]};
  cursor: pointer;
  display: grid;
  grid-column-gap: 4px;
  grid-template-columns: 16px 1fr max-content;
  padding: 0px 8px 8px 16px;
  padding-right: ${(props) => (props.isFixed ? '0' : '8px')};
  width: 100%;
`

export const StyledEventTitle = styled.span`
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    90deg,
    #fff 0%,
    #fff 98.05%,
    rgba(255, 255, 255, 0) 100%
  );
  background-clip: text;
  font-size: ${(props) => props.theme.fonts.size.s};
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
`

export const StyledHeadWrapper = styled.div.attrs(
  (props: { isFixed?: boolean; deleteBorder: boolean }) => props
)`
  ${(props) => css`
    background-color: ${props.theme.colors.default.primary[0]};
    ${props.deleteBorder
      ? css``
      : css`
          border-bottom: 1px solid;
          border-color: ${props.theme.colors.default.primary[5]};
        `}

    ${props.isFixed &&
    css`
      padding-top: 8px;
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 99;
    `};
  `}
`

export const StyledTabs = styled(Tabs)`
  ${StyledTabsItem} {
    padding: 6px 11px;
  }
`
