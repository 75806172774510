import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  PAYMENT_METHODS,
  selectCurrentWithdrawalMethod
} from 'astra-core/containers/BalanceProvider'
import { MethodParameterType } from 'betweb-openapi-axios'
import { findTransactionParam } from 'astra-core'

import {
  StyledWithdrawalFormMethodsTitle,
  StyledWithdrawalMethodInput
} from './WithdrawalFormMethods.styled'
import { IWithdrawalFormMethodsProps } from './WithdrawalFormMethods.types'

export const WithdrawalFormMethods: FC<IWithdrawalFormMethodsProps> = ({
  handleChange
}) => {
  const [t] = useTranslation()
  const currentWithdrawal = useSelector(selectCurrentWithdrawalMethod)

  const getCurrentActiveWithdrawParamName = useCallback(
    (parameterType: MethodParameterType) =>
      (currentWithdrawal &&
        findTransactionParam(currentWithdrawal?.withdraw_params, parameterType)
          ?.displayed_name) ??
      '',
    [currentWithdrawal]
  )

  return (
    <>
      {!!getCurrentActiveWithdrawParamName(MethodParameterType.PhoneNumber) && (
        <>
          <StyledWithdrawalFormMethodsTitle>
            {getCurrentActiveWithdrawParamName(MethodParameterType.PhoneNumber)}
          </StyledWithdrawalFormMethodsTitle>
          <StyledWithdrawalMethodInput
            name={PAYMENT_METHODS.MOBILE_PHONE}
            placeholder={t('enter phone number')}
            type="text"
            onBlur={handleChange(PAYMENT_METHODS.MOBILE_PHONE)}
          />
        </>
      )}
      {!!getCurrentActiveWithdrawParamName(
        MethodParameterType.AccountNumber
      ) && (
        <>
          <StyledWithdrawalFormMethodsTitle>
            {getCurrentActiveWithdrawParamName(
              MethodParameterType.AccountNumber
            )}
          </StyledWithdrawalFormMethodsTitle>
          <StyledWithdrawalMethodInput
            name={PAYMENT_METHODS.WEB_MONEY}
            placeholder={t('enter number')}
            type="text"
            onBlur={handleChange(PAYMENT_METHODS.WEB_MONEY)}
          />
        </>
      )}
      {!!getCurrentActiveWithdrawParamName(MethodParameterType.Email) && (
        <>
          <StyledWithdrawalFormMethodsTitle>
            {getCurrentActiveWithdrawParamName(MethodParameterType.Email)}
          </StyledWithdrawalFormMethodsTitle>
          <StyledWithdrawalMethodInput
            name={PAYMENT_METHODS.EMAIL}
            placeholder={t('enter email address')}
            type="text"
            onBlur={handleChange(PAYMENT_METHODS.EMAIL)}
          />
        </>
      )}
      {!!getCurrentActiveWithdrawParamName(MethodParameterType.String) && (
        <>
          <StyledWithdrawalFormMethodsTitle>
            {getCurrentActiveWithdrawParamName(MethodParameterType.String)}
          </StyledWithdrawalFormMethodsTitle>
          <StyledWithdrawalMethodInput
            name={PAYMENT_METHODS.CRYPTOCURRENCY}
            placeholder={t('enter value')}
            type="text"
            onBlur={handleChange(PAYMENT_METHODS.CRYPTOCURRENCY)}
          />
        </>
      )}
    </>
  )
}
