import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectResultsBySportIdAndByTournamentId } from 'astra-core/containers/ResultsProvider'

import { Marquee } from 'shared/ui/Marquee'

import { Event } from '../Events/Events'

import {
  StyledChampionshipName,
  StyledChampionshipNameWrapper,
  StyledChampionshipWrapper
} from './Championships.styled'
import { TChampionshipProps } from './Championships.types'

export const Championships = () => {
  const resultsBySportIdAndByTournamentIdList = useSelector(
    selectResultsBySportIdAndByTournamentId
  )

  return (
    <>
      {resultsBySportIdAndByTournamentIdList.map((championship) => (
        <Championship
          championship={championship}
          key={championship.tournament.id}
        />
      ))}
    </>
  )
}

export const Championship = ({ championship }: TChampionshipProps) => {
  const {
    tournament: { name, sportId },
    events
  } = championship
  const [t] = useTranslation()

  // TODO remove it in the future if we will not use it
  // const isTournamentInExpandedTournamentsStat = useSelector(
  //   (state: RootState) => selectIsEventInExpandedTournamentsStat(state, id)
  // )

  // const iconChevronDownColorProps = useMemo(
  //   () => ({
  //     name: EColorsNames.Primary,
  //     value: isTournamentInExpandedTournamentsStat ? 90 : 30
  //   }),
  //   [isTournamentInExpandedTournamentsStat]
  // )

  // const toggleTournamentOpen = useCallback(
  //   () =>
  //     dispatch(
  //       resultsProviderActions.toggleExpandedTournamentStatFilter({
  //         id
  //       })
  //     ),
  //   [id, dispatch]
  // )

  return (
    <>
      <StyledChampionshipWrapper>
        <StyledChampionshipNameWrapper>
          <Marquee id={championship?.tournament?.id}>
            <StyledChampionshipName>
              {name || t('event')}
            </StyledChampionshipName>
          </Marquee>
        </StyledChampionshipNameWrapper>
        {/* <StyledIconChevronDown
          colorProps={iconChevronDownColorProps}
          size={10}
          twist={isTournamentInExpandedTournamentsStat ? 180 : 0}
        /> */}
      </StyledChampionshipWrapper>
      {events.map((event) => (
        <Event event={event} key={event.id} sportId={sportId} />
      ))}
    </>
  )
}
