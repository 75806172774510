import { Sport } from 'betweb-openapi-axios'
import React from 'react'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider/types'

import { SportIcon } from '../Icon/SportIcon/SportIcon'

import { StyledSportHeader, StyledSportName } from './SportHeader.styled'

type ISportHeader = { sport: Sport }
const SportHeader: React.FC<ISportHeader> = ({ sport }) => {
  return (
    <StyledSportHeader>
      <SportIcon size={16} sportCode={sport.code as ESportsCodes} isActive />
      <StyledSportName>{sport.name}</StyledSportName>
    </StyledSportHeader>
  )
}

export default SportHeader
