import styled from 'styled-components'

export const StyledEventCompletedWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.custom.primary[36]};
  height: 100%;
`

export const StyledBannerStats = styled.div`
  background-color: ${(props) => props.theme.colors.default.primary[0]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  justify-content: center;
  margin: 8px;
  padding: 16px;
`
