import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleMakeQuickBet } from 'og-web-core/providers/basket/quick-bet/hooks/useHandleMakeQuickBet'
import {
  getBasketOutcomeKey,
  QuickBetOutcome
} from 'astra-core/containers/BasketProvider'
import { useOutcomeInfo } from 'og-web-core/providers/basket/quick-bet/hooks/useOutcomeInfo'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { setLocalStorageItem } from 'og-web-core/utils/storage/local-storage'
import { StorageKeys } from 'og-web-core/utils/storage/constants'

import { ETestData } from 'shared/lib/testData'
import { Button, EButtonViews } from 'shared/ui/Button'
import { Checkbox } from 'shared/ui/checkbox'
import { BasketQuickBetSubmitButton } from 'widgets/right-container/Basket/BasketQuickBet/components/BasketQuickBetSubmitButton/BasketQuickBetSubmitButton'
import { useNotifyMakeQuickBet } from 'hooks/useNotifyMakeQuickBet'

import {
  StyledButtonWrapper,
  StyledConfirmQuickContentDescription
} from './ConfirmQuickContent.styled'

interface ConfirmQuickContentProps {
  quickBetValue: number
  outcome: QuickBetOutcome
  onClickClose: () => void
}

export const ConfirmQuickContent: FC<ConfirmQuickContentProps> = ({
  outcome,
  onClickClose,
  quickBetValue
}) => {
  const { t } = useTranslation()
  const currencyIcon = useGetCurrencyIcon()
  const { competitorsNames, basketOutcomeName } = useOutcomeInfo(
    outcome.outcomeCompositeId
  )

  const { eventId, outcomeTypeId, parameters } = outcome.outcomeCompositeId
  const outcomeKey = getBasketOutcomeKey({
    event: { id: eventId },
    probability: {
      outcomeTypeId,
      parameters
    }
  })

  const notifyCallback = useNotifyMakeQuickBet()
  const { isLoading, makeBet } = useHandleMakeQuickBet(
    notifyCallback,
    outcomeKey
  )

  const [isChecked, setIsChecked] = useState(true)

  const handleCheckBox = () => {
    setIsChecked(!isChecked)
  }

  const handleSubmit = useCallback(() => {
    setLocalStorageItem(StorageKeys.shouldConfirmQuickBet, !isChecked)
    makeBet(outcome)
  }, [isChecked, makeBet, outcome])

  return (
    <>
      <StyledConfirmQuickContentDescription>
        <div>
          Вы действительно хотите сделать ставку
          <strong>{` ${competitorsNames} ${basketOutcomeName} ${outcome.coefficient} `}</strong>
          на сумму <strong>{`${quickBetValue} ${currencyIcon} ?`}</strong>
        </div>
      </StyledConfirmQuickContentDescription>
      <Checkbox
        checked={isChecked}
        data-test-id={ETestData.TestQuickBetConfirmCheckBox}
        label={t('not show message')}
        onChange={handleCheckBox}
      />
      <StyledButtonWrapper>
        <BasketQuickBetSubmitButton
          disabled={isLoading}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          testData={ETestData.TestQuickBetModalConfirmButton}
          title={t('confirm')}
        />
        <Button
          testData={ETestData.TestQuickBetModalCloseButton}
          view={EButtonViews.SECONDARY}
          onClick={onClickClose}
        >
          {t('сancel')}
        </Button>
      </StyledButtonWrapper>
    </>
  )
}
