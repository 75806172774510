import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { useLocation } from 'react-router-dom'

import {
  ETestData,
  getCreateTestData,
  getEventTestDataId
} from 'shared/lib/testData'
import {
  getFullEventComment,
  OpenActionH2h,
  OpenActionMatchLiveTracker,
  ShowActionComment
} from 'features/event'
import { OpenActionMatchLiveBroadcast } from 'features/event/row/open-action-match-live-broadcast'
import { EventRowActions, EventRowExpandButton } from 'entities/event'
import { EventFavouritesStarButton } from 'widgets/event-favourites-star-button'
import { ERoutes } from 'shared/types/routes'

import { MiniStatistics } from '../mini-statistics'
import { EventRowTeamsNames } from '../teams-names'
import { LinkedEventsExpandButton } from '../event-row/event-row.styled'

import { EventInfoProps } from './event-info.types'

export const EventInfo: FC<EventInfoProps> = memo(
  ({ event, isMainPage, toggleLinkedEventsOpen, isLinkedEventsOpened }) => {
    const isLinkedEventsEnabled = useFeatureFlag(
      EFeatureFlags.LINKED_EVENTS_ENABLED
    )

    const { pathname } = useLocation()
    const isNotSearchPage = pathname !== ERoutes.Search
    const isEventWithLinkedEvents = !!event.linkedEvents?.length
    const { t } = useTranslation()
    const showExpandLinkedEventsButton =
      isEventWithLinkedEvents && isLinkedEventsEnabled && isNotSearchPage
    return (
      <>
        <EventRowTeamsNames event={event} />
        <MiniStatistics event={event} isMainPage={isMainPage} />
        <EventFavouritesStarButton
          data-test-id={getCreateTestData(
            getEventTestDataId(event),
            ETestData.TestEventRowFavorite
          )}
          eventId={event.id}
          size={16}
        />
        <EventRowActions
          lineComponents={
            <>
              <ShowActionComment
                comment={getFullEventComment(event.comments)}
              />
              <OpenActionH2h event={event} />
            </>
          }
          liveComponents={
            <>
              <ShowActionComment
                comment={getFullEventComment(event.comments)}
              />
              <OpenActionMatchLiveTracker event={event} />
              <OpenActionMatchLiveBroadcast event={event} />
            </>
          }
          isLive={event.live}
        />
        {showExpandLinkedEventsButton && (
          <LinkedEventsExpandButton>
            <EventRowExpandButton
              isExpanded={isLinkedEventsOpened}
              onClick={toggleLinkedEventsOpen}
            >{`${t('linked events')} ${
              event.linkedEvents?.length
            }`}</EventRowExpandButton>
          </LinkedEventsExpandButton>
        )}
      </>
    )
  }
)
