import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { broadcastProviderActions } from 'astra-core/containers/BroadcastProvider/slice'
import { ENV, getEnv } from 'astra-core/utils/env'
import { LinkedEvent } from 'betweb-openapi-axios/dist/api'
import { selectEventById } from 'astra-core/containers/EventsProvider/selectors'

import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'
import { useInterval } from 'hooks'
import { outcomesContainerActions } from 'containers/OutcomesContainer/slice'
import { RootState } from 'shared/types/store'

import { actions, reducer, sliceKey } from './slice'
import { eventContainerSaga } from './saga'
import {
  BROADCAST_POLLING_INTERVAL,
  getFetchEventReqData,
  LINE_EVENT_POLLING_INTERVAL,
  LIVE_EVENT_POLLING_INTERVAL
} from './utils'
import { EventParams } from './types'

export function useEventContainer() {
  useInjectReducer({ key: sliceKey, reducer })
  useInjectSaga({ key: sliceKey, saga: eventContainerSaga })

  const { eventId } = useParams<EventParams>()
  const { state } = useLocation<{ linkedEventId?: LinkedEvent['eventId'] }>()

  const event = useSelector((state: RootState) =>
    selectEventById(state, +eventId)
  )

  const dispatch = useDispatch()

  const isBroadcastFeatureFlagEnabled = useFeatureFlag(
    EFeatureFlags.BROADCAST_ENABLED
  )
  const env = getEnv()
  const broadcastEnabled =
    eventId &&
    isBroadcastFeatureFlagEnabled &&
    event?.live &&
    [ENV.STAGE, ENV.PROD].includes(env)

  useEffect(() => {
    dispatch(
      outcomesContainerActions.setSelectedLinkedEventId({
        id: state?.linkedEventId ?? null
      })
    )
  }, [dispatch, state?.linkedEventId])

  const fetchBroadcastsAvailability = useCallback(() => {
    if (broadcastEnabled) {
      dispatch(
        broadcastProviderActions.fetchBroadcastsAvailability({
          reqData: {
            eventIds: [+eventId],
            platform: 'd_web'
          }
        })
      )
    }
  }, [dispatch, eventId, broadcastEnabled])

  useInterval(fetchBroadcastsAvailability, BROADCAST_POLLING_INTERVAL, true)

  const fetchEvent = useCallback(() => {
    if (eventId) {
      dispatch(actions.fetchEvent(getFetchEventReqData({ eventId })))
    }
  }, [dispatch, eventId])

  // TODO remove the request for completed events if its status cannot change
  useInterval(
    fetchEvent,
    event
      ? event.live
        ? LIVE_EVENT_POLLING_INTERVAL
        : LINE_EVENT_POLLING_INTERVAL
      : null,
    true
  )
}
