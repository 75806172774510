import React, { FC } from 'react'
import { Sport, Event } from 'betweb-openapi-axios'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { EEventsStatusList } from 'astra-core/utils/events/types'

import { EventStatusBanner } from 'pages/page-event/Event/components/EventStatusBanner/EventStatusBanner'

import { StyledBannerStats } from '../../EventCompleted.styled'

import { StatsFootball } from './components/StatsFootball'

export const StatsCompleted: FC<{
  sportCode: Sport['code']
  event: Event
}> = ({ sportCode, event }) => {
  if (sportCode === ESportsCodes.FOOTBALL) {
    return <StatsFootball event={event} />
  }
  return (
    <StyledBannerStats>
      <EventStatusBanner
        eventsStatusListType={EEventsStatusList.STATISTICS_NOT_AVAILABLE}
      />
    </StyledBannerStats>
  )
}
