import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { eventsStatusBannerTestData } from 'og-web-core/utils/test-data/constants'

import { EColorsNames, EColorsTypes } from 'shared/types/theme/colors'

import { eventsStatusConfiguration } from './EventStatusBanner.constants'
import { EventsStatusBannerProps } from './EventStatusBanner.types'
import {
  StyledEventStatusBanner,
  StyledIcon,
  StyledEventStatusTitle
} from './EventStatusBanner.styled'

export const EventStatusBanner: FC<EventsStatusBannerProps> = ({
  eventsStatusListType
}) => {
  const [t] = useTranslation()

  const { icon: Icon, statusTitle } =
    eventsStatusConfiguration[eventsStatusListType]

  return (
    <StyledEventStatusBanner
      data-test-id={eventsStatusBannerTestData[eventsStatusListType]}
    >
      <StyledIcon>
        {!!Icon && (
          <Icon
            colorProps={{
              name: EColorsNames.Primary,
              type: EColorsTypes.CUSTOM,
              value: 8
            }}
          />
        )}
      </StyledIcon>

      <StyledEventStatusTitle> {t(statusTitle)}</StyledEventStatusTitle>
    </StyledEventStatusBanner>
  )
}
