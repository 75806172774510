import styled, { keyframes, css } from 'styled-components'

import { Icon } from 'shared/ui/Icon/Icon'
import { IconUnreadNotification } from 'shared/ui/Icon/General/IconUnreadNotification'

import { IStyledPropsAccordion } from './types'

export const IconAccordion = styled(Icon)`
  transition: transform 0.5s ease;
`

export const StyledAccordionBlock = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.custom.primary[8]};
  display: grid;
  grid-template-columns: 1fr;
`
export const StyledAccordionContainer = styled.div<IStyledPropsAccordion>`
  ${(props) => css`
    padding-bottom: ${props.padding}px;
  `}
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 115px 9px;
  padding-right: 21px;
`

export const StyledAccordionTitle = styled.h2<IStyledPropsAccordion>`
  ${(props) => css`
    color: ${props.color
      ? props.theme.colors.default.primary[90]
      : props.theme.colors.default.primary[30]};
  `}
  ${({ theme }) => `
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.regular};
    `};
`

export const StyledAccordionDate = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.fixed.primary[40]};
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
  `};
  white-space: nowrap;
`
export const AnimationDiscription = keyframes`
  0%{
    max-height: 15px;
  }

  100%{
    max-height: 2000px;
  }
`

export const StyledAccordionDiscription = styled.p<IStyledPropsAccordion>`
  ${(props) => css`
    color: ${props.colorDescr
      ? props.theme.colors.default.primary[50]
      : props.theme.colors.default.primary[40]};
  `}
  animation-duration: 1s;
  animation-name: ${AnimationDiscription};
  max-width: 90%;
  overflow: hidden;
  padding-bottom: 16px;
  padding-right: 10px;
  ${({ theme }) => `
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.regular};
  `};
`

export const StyledNotificationAccordion = styled(IconUnreadNotification)`
  margin: 5px 5px auto auto;
`
