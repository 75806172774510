import React, { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { favouritesProviderActions } from 'astra-core/containers/FavouritesProvider'

import { ETestData } from 'shared/lib/testData'
import { Button, EButtonSizes, EButtonViews } from 'shared/ui/Button'

import { SportsTypesTabs } from './components'
import { StyledFilters, StyledTypesTabsWrapper } from './Filters.styled'

export const Filters = memo(() => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const handleClearFavouriteEvents = useCallback(() => {
    dispatch(favouritesProviderActions.deleteFavouriteEvents())
  }, [dispatch])

  return (
    <StyledFilters>
      <StyledTypesTabsWrapper>
        <SportsTypesTabs />
      </StyledTypesTabsWrapper>

      <Button
        data-test-id={ETestData.TestFavoritesClearAll}
        size={EButtonSizes.S}
        view={EButtonViews.SECONDARY}
        onClick={handleClearFavouriteEvents}
      >
        {t('clear all')}
      </Button>
    </StyledFilters>
  )
})
