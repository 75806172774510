import React, { FC } from 'react'

import { IconProps } from '../../types'
import { Icon } from '../../Icon'

export const IconEventPostponed: FC<IconProps> = (props) => (
  <Icon {...props} size={64}>
    <svg
      fill="none"
      height="64"
      viewBox="0 0 64 64"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="currentColor" height="64" rx="8" width="64" />
      <path
        clipRule="evenodd"
        d="M51.7085 16.3027C50.8507 15.9694 49.8733 15.8078 48.816 15.8078C48.4669 15.8078 48.1079 15.828 47.7488 15.8583C47.6491 12.6362 40.6773 10.0404 32.0399 10H31.9402C23.3127 10.0404 16.3409 12.6362 16.2412 15.8684C15.8722 15.8381 15.5231 15.8179 15.174 15.8179C14.1168 15.8179 13.1393 15.9795 12.2816 16.3129C7.11507 18.3027 8.16233 25.8478 14.6254 33.1606C17.0092 35.8473 19.7819 38.1301 22.6046 39.8472C24.5544 42.3269 26.8593 44.3485 29.4194 45.7757C29.9135 46.0511 30.2286 46.5715 30.2096 47.1368C30.1353 49.3445 28.3521 51.0717 26.2265 50.9946L26.2234 50.9945V51.0001H25.6458C24.3697 51.0001 23.3352 52.0746 23.3352 53.4C23.3352 53.7314 23.5938 54 23.9128 54H38.411C34.6273 52.2608 32 48.4372 32 44C32 37.9249 36.9249 33 43 33C43.7779 33 44.5369 33.0807 45.2691 33.2343C46.9942 29.1888 47.9782 24.5462 47.9782 19.6157C47.9782 19.0299 47.9583 18.4542 47.9283 17.8784C48.2276 17.8481 48.5268 17.838 48.816 17.838C49.6538 17.838 50.3819 17.9592 51.0003 18.1916C52.0276 18.5855 52.6261 19.2622 52.8754 20.3127C53.5437 23.1105 51.5888 27.6356 47.8885 31.8172H47.8785C47.3673 32.3966 46.8297 32.9559 46.2729 33.4951C46.9742 33.7134 47.6454 33.9999 48.2787 34.347C48.6524 33.9612 49.0181 33.5656 49.3746 33.1606C55.8377 25.8478 56.8849 18.3027 51.7184 16.3027H51.7085ZM16.1115 31.8172C12.4112 27.6255 10.4464 23.1105 11.1246 20.3127C11.3739 19.2622 11.9724 18.5956 12.9997 18.1916C13.6081 17.9592 14.3462 17.838 15.184 17.838C15.4732 17.838 15.7724 17.8582 16.0716 17.8784C16.0417 18.4542 16.0218 19.0299 16.0218 19.6157C16.0218 25.3933 17.3683 30.7567 19.6722 35.2413C18.4155 34.1908 17.2087 33.0394 16.1215 31.8172H16.1115Z"
        fill="#808080"
        fillRule="evenodd"
      />
      <path
        d="M53 44C53 49.5228 48.5228 54 43 54C37.4772 54 33 49.5228 33 44C33 38.4772 37.4772 34 43 34C48.5228 34 53 38.4772 53 44Z"
        fill="#259325"
      />
      <path
        d="M37.0341 47.5654C36.9264 47.5654 36.8209 47.5355 36.7293 47.479C36.6377 47.4225 36.5637 47.3416 36.5155 47.2454C35.831 45.8835 35.5936 44.3404 35.8372 42.8357C36.0808 41.3311 36.793 39.9417 37.8722 38.8653C40.7001 36.0371 45.3017 36.0371 48.1296 38.8653C48.2351 38.9747 48.2936 39.1211 48.2923 39.2731C48.2909 39.425 48.23 39.5704 48.1225 39.6779C48.015 39.7854 47.8696 39.8463 47.7177 39.8476C47.5657 39.849 47.4193 39.7905 47.3099 39.685C44.933 37.3088 41.0688 37.3088 38.6918 39.685C37.7851 40.5893 37.1868 41.7566 36.9822 43.0208C36.7776 44.285 36.9771 45.5815 37.5523 46.7257C37.5966 46.814 37.6176 46.9122 37.6133 47.011C37.609 47.1098 37.5795 47.2058 37.5276 47.29C37.4757 47.3741 37.4032 47.4436 37.3169 47.4918C37.2305 47.54 37.1329 47.5653 37.0341 47.5654Z"
        fill="white"
      />
      <path
        d="M43.0009 51.243C42.0485 51.2447 41.1051 51.0582 40.225 50.6943C39.3449 50.3303 38.5453 49.7961 37.8723 49.1222C37.8184 49.0684 37.7757 49.0045 37.7466 48.9342C37.7174 48.8639 37.7024 48.7885 37.7024 48.7124C37.7024 48.6362 37.7173 48.5609 37.7465 48.4905C37.7756 48.4202 37.8183 48.3563 37.8721 48.3024C37.9259 48.2486 37.9898 48.2059 38.0601 48.1767C38.1304 48.1476 38.2058 48.1326 38.2819 48.1325C38.3581 48.1325 38.4334 48.1475 38.5038 48.1766C38.5741 48.2057 38.638 48.2484 38.6919 48.3022C41.0688 50.6784 44.9331 50.6784 47.31 48.3022C48.2167 47.3979 48.815 46.2305 49.0196 44.9664C49.2242 43.7022 49.0247 42.4057 48.4496 41.2615C48.3806 41.1241 48.3691 40.9649 48.4176 40.819C48.466 40.6731 48.5705 40.5524 48.7079 40.4835C48.8453 40.4145 49.0045 40.403 49.1504 40.4515C49.2963 40.4999 49.417 40.6044 49.486 40.7418C50.0407 41.8475 50.3032 43.0767 50.2487 44.3126C50.1941 45.5484 49.8242 46.7498 49.1742 47.8023C48.5242 48.8548 47.6158 49.7235 46.5352 50.3258C45.4547 50.928 44.238 51.2437 43.0009 51.243Z"
        fill="white"
      />
      <path
        d="M45.25 40.1016C45.1017 40.1009 44.9593 40.0433 44.8522 39.9408C44.745 39.8383 44.6812 39.6986 44.6739 39.5505C44.6667 39.4023 44.7164 39.257 44.813 39.1445C44.9096 39.032 45.0457 38.9607 45.1932 38.9454L47.026 38.7627L46.6585 36.9206C46.6305 36.7706 46.6627 36.6157 46.7481 36.4893C46.8336 36.3629 46.9654 36.2753 47.115 36.2455C47.2647 36.2157 47.42 36.2461 47.5473 36.3301C47.6747 36.4141 47.7638 36.5449 47.7954 36.6941L48.2877 39.1646C48.3035 39.2439 48.3026 39.3256 48.2849 39.4045C48.2672 39.4834 48.2333 39.5578 48.1851 39.6228C48.137 39.6878 48.0758 39.742 48.0055 39.782C47.9352 39.8219 47.8573 39.8467 47.7768 39.8547L45.3083 40.1009C45.2886 40.1009 45.2693 40.1016 45.25 40.1016Z"
        fill="white"
      />
      <path
        d="M38.7737 51.7648C38.6397 51.7647 38.5099 51.7181 38.4063 51.6331C38.3027 51.5481 38.2318 51.4298 38.2056 51.2983L37.7133 48.8298C37.6975 48.7505 37.6985 48.6687 37.7162 48.5898C37.7339 48.5108 37.7679 48.4365 37.816 48.3715C37.8642 48.3065 37.9254 48.2523 37.9958 48.2123C38.0661 48.1724 38.1441 48.1477 38.2246 48.1397L40.6931 47.8935C40.846 47.8782 40.9988 47.9242 41.1179 48.0215C41.2369 48.1188 41.3124 48.2593 41.3278 48.4123C41.3432 48.5653 41.2971 48.7181 41.1999 48.8371C41.1026 48.9561 40.962 49.0316 40.809 49.047L38.9762 49.2298L39.3433 51.0719C39.3601 51.1561 39.3579 51.2429 39.337 51.3261C39.3162 51.4094 39.2771 51.4869 39.2226 51.5532C39.1681 51.6195 39.0995 51.6729 39.0219 51.7095C38.9443 51.746 38.8595 51.7649 38.7737 51.7648Z"
        fill="white"
      />
    </svg>
  </Icon>
)
