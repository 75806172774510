import { Event } from 'betweb-openapi-axios'

import { RootState } from 'shared/types/store'

export const getSelectedOutcomesGroupId = (
  state: RootState,
  eventId: Event['id']
) => state.outcomesContainer.selectedOutcomesGroup[eventId]?.id

export const selectActiveLinkedEventId = (state: RootState) =>
  state.outcomesContainer.selectedLinkedEventId
