import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { Bet } from 'betweb-openapi-axios'
import { formatAmountLocaleString } from 'astra-core'
import { useDispatch, useSelector } from 'react-redux'
import {
  betsHistoryProviderActions,
  selectCashoutByBetId,
  selectCurrentCashout,
  selectIsMakeCashoutLoading
} from 'astra-core/containers/BetsHistoryProvider'

import { RootState } from 'shared/types/store'
import { EColorsNames } from 'shared/types/theme'
import { IconBonus } from 'shared/ui/Icon/General/IconBonus'
import { BasketHistoryBonusCoupon } from 'widgets/right-container/Basket/BasketHistoryBody/components'
import { getHintAttrNameFormatted } from 'constants/styles/components/hintAttr/utils'
import { EButtonViews } from 'shared/ui/Button'
import { ETestData } from 'shared/lib/testData'

import { ECellEventTitle, ICellEventCashoutText } from './CellEvent.types'
import {
  CellEventBetCountIcon,
  StyledBetAmount,
  StyledCellEventBetCount,
  StyledCellEventCashoutText,
  StyledCellEventCashoutWrapper,
  StyledCellEventNum,
  StyledCellEventTitle,
  CurrentCashoutModalWholeBackground
} from './CellEvent.styled'
import { CurrentCashoutModal } from './components'
import { DISABLE_HOVER_ON_CHILD } from './components/constants'

export const CellEventNum = () => <StyledCellEventNum />

export const CellEventTitle = ({ value, systemType }) => {
  const [t] = useTranslation()

  return (
    <StyledCellEventTitle>
      {ECellEventTitle[value]
        ? `${t(ECellEventTitle[value])} ${systemType}`
        : ECellEventTitle.default}
    </StyledCellEventTitle>
  )
}

const CellEventCashoutText: FC<ICellEventCashoutText> = ({ amount }) => {
  const [t] = useTranslation()
  const currencyIcon = useGetCurrencyIcon()
  const isMakeCashoutLoading = useSelector(selectIsMakeCashoutLoading)

  const hintAttr = useMemo(
    () =>
      isMakeCashoutLoading &&
      getHintAttrNameFormatted(t('currently impossible to sell a bet')),
    [isMakeCashoutLoading, t]
  )

  const text = `${t('sell for')} ${formatAmountLocaleString({
    value: amount,
    currency: currencyIcon
  })}`

  return (
    <StyledCellEventCashoutText
      className={DISABLE_HOVER_ON_CHILD}
      disabled={isMakeCashoutLoading}
      view={EButtonViews.SELL_BET}
      {...hintAttr}
      data-test-id={ETestData.TestBetsHistoryCashoutButton}
    >
      {text}
    </StyledCellEventCashoutText>
  )
}

export const CellEventCashout = ({ id }) => {
  const dispatch = useDispatch()
  const cashout = useSelector((state: RootState) =>
    selectCashoutByBetId(state, id)
  )
  const currentCashout = useSelector(selectCurrentCashout)

  const isShowCurrentCashout = !!(currentCashout && currentCashout.betId === id)
  const isCashoutExist = !!(cashout && cashout.amount)

  const unsetCurrentCashout = (event) => {
    event.stopPropagation()
    dispatch(betsHistoryProviderActions.unsetCurrentCashout())
  }

  const setCurrentCashout = (event) => {
    event.stopPropagation()

    if (isCashoutExist && !isShowCurrentCashout) {
      dispatch(
        betsHistoryProviderActions.setCurrentCashout({ betId: cashout.betId })
      )
    }
  }

  return (
    <StyledCellEventCashoutWrapper onClick={setCurrentCashout}>
      {isCashoutExist && <CellEventCashoutText {...cashout} />}

      {isShowCurrentCashout && (
        <>
          <CurrentCashoutModal />
          <CurrentCashoutModalWholeBackground onClick={unsetCurrentCashout} />
        </>
      )}
    </StyledCellEventCashoutWrapper>
  )
}

export const CellEventBetCount: FC<{ value: Bet }> = ({ value }) => {
  const currencyIcon = useGetCurrencyIcon()
  const isBonus = value.bonusWallet
  const { bet, appliedCouponId } = value

  const additionBonusBlock = isBonus ? (
    <IconBonus />
  ) : appliedCouponId ? (
    <BasketHistoryBonusCoupon appliedCouponId={appliedCouponId} />
  ) : null

  return (
    <StyledCellEventBetCount>
      <CellEventBetCountIcon
        colorProps={{ name: EColorsNames.Primary, value: 50 }}
        size={9}
      />
      <StyledBetAmount>
        {formatAmountLocaleString({
          value: bet,
          currency: !isBonus ? currencyIcon : undefined
        })}
        {additionBonusBlock}
      </StyledBetAmount>
    </StyledCellEventBetCount>
  )
}
