import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { Button } from 'shared/ui/Button'

import {
  StyledBetTypeItemTextProps,
  StyledBetTypeItemWrapperProps
} from './BasketBody.types'

export const StyledBasketBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 12px;
`

export const StyledBasketBody = styled.div`
  ${(p) => `background-color: ${p.theme.colors.default.primary[0]};`}
`

export const StyledThreeBetTypesWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 0 16px;
  position: relative;
`
export const StyledBasketBodyList = styled.div`
  padding-bottom: 12px;
`

export const StyledBetTypeItemWrapper = styled.button<StyledBetTypeItemWrapperProps>`
  ${(props) => css`
    align-items: center;
    background-color: ${props.typeTheme === LayoutTheme.Dark
      ? props.active
        ? props.theme.colors.custom.primary[1]
        : 'transparent'
      : props.active
      ? props.theme.colors.default.primary[90]
      : 'transparent'};
    border: 1px solid ${props.theme.colors.custom.primary[4]};
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
  `}
`

export const StyledBetTypeItemText = styled.div<StyledBetTypeItemTextProps>`
  ${(props) => css`
    color: ${props.active
      ? props.theme.colors.fixed.primary[0]
      : props.theme.colors.custom.primary[17]};
    font-size: ${props.theme.fonts.size.s};
    font-weight: ${props.theme.fonts.weight.normal};
    line-height: 16px;
    padding: 0 6.83px;
    text-align: center;
  `}
`

export const StyledEmptyBasketWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: inherit;
  padding: 0 16px;
`

export const StyledEmptyBasketTitle = styled.div`
  margin-bottom: 8px;
  margin-top: 106px;

  ${(props) => css`
    color: ${props.theme.colors.custom.primary[16]};
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.bold};
    line-height: 20px;
  `}
`

export const StyledEmptyBasketText = styled.div`
  margin-bottom: 70px;

  ${(props) => css`
    color: ${props.theme.colors.fixed.primary[50]};
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.regular};
    line-height: 20px;
    text-align: center;
    width: 200px;
  `}
`

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.default.primary[90]};
  text-decoration: underline;
`

export const StyledButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  ${(props) => css`
    color: ${props.theme.colors.default.primary[90]};
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.regular};
    line-height: 20px;
  `}
`

export const StyledEmptyBasketMakeABetButton = styled(Button)`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const StyledEmptyBasketLoginButton = styled(Button)`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const StyledNotAuthorizedBasketWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: inherit;
  justify-content: center;
  margin-top: -50%;
  padding: 0 16px;
`

export const StyledNotAuthorizedBasketTitle = styled.div`
  margin: 8px 0;

  ${(props) => css`
    color: ${props.theme.colors.custom.primary[16]};
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.bold};
    line-height: 20px;
  `}
`

export const StyledNotAuthorizedBasketText = styled(StyledEmptyBasketText)`
  margin-bottom: 24px;
  width: auto;
`
