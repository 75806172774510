import styled from 'styled-components'

import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { Select } from 'shared/ui/Select'

import { InputFormik } from '../Input/Input'
import { IStyledFullSignUpInput } from '../FullRegistration.types'

export const StyledFullRegistrationWrapper = styled.div`
  padding: 26px 32px;
`

export const StyledFullRegistrationHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  width: 336px;
`

export const StyledFullRegistrationHeaderTitle = styled.div`
  line-height: 20px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.l};
    font-weight: ${theme.fonts.weight.bold};
    color: ${theme.colors.default.primary[90]};
  `}
`

export const StyledFullRegistrationHeaderExit = styled(IconClose)`
  align-self: center;
  cursor: pointer;
`

export const StyledUserDataTitle = styled.div`
  line-height: 20px;
  padding-bottom: 8px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.default.primary[90]};
  `}
`

export const StyledInput = styled(InputFormik)<IStyledFullSignUpInput>`
  padding-bottom: 16px;

  ${(props) =>
    props.width
      ? `
          & > input {
            width: ${props.width};
          } 
        `
      : ''}

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }
`

export const StyledFullRegistrationSelect = styled(Select)`
  margin-bottom: 16px;
`

export const StyledFullRegistrationRefreshPicture = styled.div`
  line-height: 14px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.custom.primary[39]};
    `}
`
