import React, { FC, memo } from 'react'
import { BetStatus } from 'betweb-openapi-axios'

import { IconClock } from 'shared/ui/Icon/General/IconClock'
import { IconCheckCircleFilled } from 'shared/ui/Icon/General/IconCheckCircleFilled'
import { IconCircleArrowToCenter } from 'shared/ui/Icon/General/IconCircleArrowToCenter'
import { IconCircleMinus } from 'shared/ui/Icon/General/IconCircleMinus'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { IconProps } from 'shared/ui/Icon'
import { IIconSelect } from 'pages/page-bets-history/BetsHistoryPageContainer/components/BetsHistory/components/History/components/Table/Table.types'
import { IconDollarInCircle } from 'shared/ui/Icon/General/IconDollarInCircle'

type TDataIcon = Partial<
  Record<
    BetStatus,
    {
      icon: FC
      settings: IconProps
    }
  >
>
const data: TDataIcon = {
  [BetStatus.Current]: {
    icon: IconClock,
    settings: {
      colorProps: {
        name: EColorsNames.Primary,
        type: EColorsTypes.FIXED,
        value: 30
      }
    }
  },
  [BetStatus.Won]: {
    icon: IconCheckCircleFilled,
    settings: {
      colorProps: {
        name: EColorsNames.Green,
        type: EColorsTypes.FIXED,
        value: 4
      }
    }
  },
  [BetStatus.Returned]: {
    icon: IconCircleArrowToCenter,
    settings: {
      colorProps: {
        name: EColorsNames.Yellow,
        type: EColorsTypes.FIXED,
        value: 49
      }
    }
  },
  [BetStatus.Lost]: {
    icon: IconCircleMinus,
    settings: {
      colorProps: {
        name: EColorsNames.Primary,
        type: EColorsTypes.FIXED,
        value: 60
      }
    }
  },
  [BetStatus.Sold]: {
    icon: IconDollarInCircle,
    settings: {
      colorProps: {
        name: EColorsNames.Primary,
        type: EColorsTypes.FIXED,
        value: 60
      }
    }
  }
}

export const IconSelect: FC<IIconSelect> = memo(({ betStatus }) => {
  const item = data[betStatus]

  if (!item) return null

  const SelectedIcon: FC<IconProps> = item.icon

  return <SelectedIcon size={18} {...item.settings} /> || null
})
