import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { cyberbetStoreActions } from 'astra-core/containers/CyberbetProvider'

export const useReset = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(cyberbetStoreActions.onReset())
    }
  }, [dispatch])
}
