import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  selectAccountId,
  selectLoyaltyById,
  selectLoyaltyLoading
} from 'astra-core/containers/AccountProvider/selectors'
import { useHistory, useParams } from 'react-router-dom'
import { useGetCurrencyIcon } from 'astra-core'
import dayjs from 'dayjs'

import { RootState } from 'shared/types/store'
import { IconWatch } from 'shared/ui/Icon/General/IconWatch'
import { COUPON_DATE_FORMAT } from 'shared/lib/bonusCoupons'
import { getFormattedBonusCouponAmount } from 'shared/lib/basket'
import { getLocaleByStatus } from 'pages/page-promotion-and-bonuses/PromotionsAndBonuses/components/BonusCouponsBody/components/Coupons/constants'
import { useAppStore } from 'app/lib/store'

import {
  StyledBonusCouponsImgWrapper,
  StyledBonusCouponsTitle,
  StyledBonusCouponsWrapper,
  StyledCouponBodyDescription,
  StyledCouponDateLeft,
  StyledCouponDateLeftWrapper,
  StyledCouponDateTill,
  StyledCouponDateWrapper
} from './BonusCoupons.styled'

let timeout

export const BonusCoupons = () => {
  const [t] = useTranslation()
  const { id } = useParams<{ id?: string }>()
  const history = useHistory()

  const currencyIcon = useGetCurrencyIcon()
  const couponLoyalty = useSelector((state: RootState) =>
    selectLoyaltyById(state, id)
  )
  const accountId = useSelector(selectAccountId)
  const loading = useSelector(selectLoyaltyLoading)

  const store = useAppStore()

  useEffect(() => {
    timeout = setTimeout(() => {
      const state = store.getState() as unknown as RootState
      const coupon = selectLoyaltyById(state, id)
      if (!coupon && accountId && !loading) {
        history.replace('/profile')
      }
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [history, accountId, store, id, loading])

  if (!couponLoyalty) {
    return null
  }

  const { possibleAmount, coupon, status, expiredAt } = couponLoyalty

  const couponDate = dayjs(expiredAt).format(COUPON_DATE_FORMAT)
  const couponTitle = getFormattedBonusCouponAmount(
    coupon.name,
    possibleAmount ?? coupon.amount,
    currencyIcon
  )

  const localeByStatus = status
    ? getLocaleByStatus(t, couponDate)[status]
    : t('expired')

  return (
    <StyledBonusCouponsWrapper>
      <StyledBonusCouponsTitle>{couponTitle}</StyledBonusCouponsTitle>

      <StyledBonusCouponsImgWrapper />

      <StyledCouponDateWrapper>
        <StyledCouponDateTill status={status}>
          {localeByStatus}
        </StyledCouponDateTill>

        <StyledCouponDateLeftWrapper>
          <IconWatch />
          <StyledCouponDateLeft>{couponDate}</StyledCouponDateLeft>
        </StyledCouponDateLeftWrapper>
      </StyledCouponDateWrapper>

      <StyledCouponBodyDescription>
        {coupon.description}
      </StyledCouponBodyDescription>
    </StyledBonusCouponsWrapper>
  )
}
