import React, { FC } from 'react'

import { ETableHeadVariant, TableBetsHead } from 'widgets/event-row-head'
import { EventRow } from 'widgets/event-row'

import { TableCalendarEventProps } from './TableCalendarEvents.types'

export const TableCalendarEvents: FC<TableCalendarEventProps> = ({
  values,
  isShowOdds,
  variant = ETableHeadVariant.TWO,
  tournament
}) => {
  return (
    <>
      <TableBetsHead
        isShowOdds={isShowOdds}
        tournament={tournament}
        variant={variant}
      />

      {values.events.map((i) => {
        return <EventRow event={i} key={i.id} />
      })}
    </>
  )
}
