import React from 'react'
import { ColumnType } from 'astrabet-templates-kit'

import {
  StyledHeaderCell,
  StyledPlainHeaderCell,
  StyledOutcomesTableHeader
} from './OutcomesTable.styled'

interface LabelProps {
  clearHeaderCell: boolean
  columns: ColumnType[]
  labelInButton?: boolean
  isPlain?: boolean
  titles: string[] | string[][]
}

export const Label = ({
  clearHeaderCell,
  columns,
  labelInButton,
  isPlain,
  titles
}: LabelProps) => {
  if (!clearHeaderCell) return null
  const formattedTitles = titles.filter(Boolean)
  const formattedTitlesFlat = titles.flat() as ColumnType[]
  return (
    <>
      {formattedTitles.map((formattedTitle, i) => {
        if (!formattedTitle.length) return null
        if (isPlain) {
          if (!formattedTitle[0]) return null
          return (
            <StyledOutcomesTableHeader
              columns={formattedTitlesFlat}
              key={formattedTitle[0]}
              labelInButton={labelInButton}
            >
              <StyledPlainHeaderCell>{formattedTitle[0]}</StyledPlainHeaderCell>
            </StyledOutcomesTableHeader>
          )
        }
        return (
          <StyledOutcomesTableHeader
            columns={formattedTitlesFlat}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            labelInButton={labelInButton}
          >
            {columns.map((column, index, array) => {
              if (typeof formattedTitle[index] !== 'string') {
                return null
              }
              return (
                <StyledHeaderCell
                  dangerouslySetInnerHTML={{
                    __html: formattedTitle[index]
                  }}
                  isFirstInRow={index === 0}
                  isLastInRow={index === array.length - 1}
                  isOdd={index > 0 && column === 'odd'}
                  key={formattedTitle[index]}
                />
              )
            })}
          </StyledOutcomesTableHeader>
        )
      })}
    </>
  )
}
