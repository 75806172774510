import React, { FC, useRef } from 'react'

import { ETestData } from 'shared/lib/testData'
import { TableBetsContext } from 'pages/page-live-events/Line/components/ui/TableBets/TableBets.context'

import {
  TableCalendarGroupProps,
  TableCalendarProps
} from './TableCalendar.types'
import {
  StyledTableCalendar,
  StyledTableCalendarDate
} from './TableCalendar.styled'
import { TableCalendarHead } from './TableCalendarHead'
import { TableCalendarEvents } from './TableCalendarEvents'

export const TableCalendar: FC<TableCalendarProps> = ({
  groupedEvents,
  headerEventsProps,
  isLive,
  isLinkHead
}) => {
  const { current: tableBetsContextValue } = useRef({
    ...(headerEventsProps && { tableEventHead: headerEventsProps }),
    isLive,
    isLinkHead
  })

  return (
    <TableBetsContext.Provider value={tableBetsContextValue}>
      <StyledTableCalendar data-test-id={ETestData.TestTableCalendar}>
        {groupedEvents.map((groupByDate) => (
          <TableCalendarGroup
            groupByDate={groupByDate}
            key={groupByDate.eventDate}
          />
        ))}
      </StyledTableCalendar>
    </TableBetsContext.Provider>
  )
}

const TableCalendarGroup: FC<TableCalendarGroupProps> = ({ groupByDate }) => {
  return (
    <StyledTableCalendarDate>
      {groupByDate.groupsByTime.map((group) => (
        <React.Fragment key={group.eventTime}>
          <TableCalendarHead event={group} eventDate={groupByDate.eventDate} />

          {group.values.map((i) => (
            <TableCalendarEvents
              key={i.tournamentId}
              tournament={i?.tournament}
              values={i}
            />
          ))}
        </React.Fragment>
      ))}
    </StyledTableCalendarDate>
  )
}
