import React from 'react'
import { mainPageProviderActions as ogCore } from 'og-core/providers/main-page'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { AppStore } from 'app/lib/store'

import { Main } from './Main'

export const PageMain = () => {
  return (
    <Layout type={ELayoutsList.MAIN}>
      <Main />
    </Layout>
  )
}

export const pageMainPrefetch = ({ store }: { store: AppStore }) => {
  store.dispatch(ogCore.fetchTopLiveEvents())
  store.dispatch(ogCore.fetchTopLineEvents())
  store.dispatch(ogCore.fetchUpcomingEvents())
}
