import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ETestData } from 'shared/lib/testData'
import { EButtonViews } from 'shared/ui/Button'

import {
  StyledEmptyPageFilterDescription,
  StyledEmptyPageFilterDescriptionButton,
  StyledEmptyPageFilterTitle,
  StyledEmptyPageFilterWrapper
} from './EmptyPageFilter.styled'
import { IEmptyPageFilterProps } from './EmptyPageFilter.types'

export const EmptyPageFilter: FC<IEmptyPageFilterProps> = ({
  onButtonClickResetFilter
}) => {
  const [t] = useTranslation()

  return (
    <StyledEmptyPageFilterWrapper>
      <StyledEmptyPageFilterTitle data-test-id={ETestData.TestNothingFound}>
        {t('nothing found')}
      </StyledEmptyPageFilterTitle>
      <StyledEmptyPageFilterDescription>
        {t('try selecting other filters')}
      </StyledEmptyPageFilterDescription>
      <StyledEmptyPageFilterDescriptionButton
        data-test-id={ETestData.TestResetFilter}
        view={EButtonViews.SECONDARY}
        onClick={onButtonClickResetFilter}
      >
        {t('reset filter')}
      </StyledEmptyPageFilterDescriptionButton>
    </StyledEmptyPageFilterWrapper>
  )
}
