import { takeLatest, call, all, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { fetchEventSaga as coreFetchEventSaga } from 'astra-core/containers/EventsProvider/saga'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider/slice'

import { eventsProviderSideEffects } from 'shared/lib/eventsProviderSideEffects'

import { actions } from './slice'
import { FetchEventPayload, FetchEventsByIdsPayload } from './types'
import { getFetchEventReqData } from './utils'

const SOURCE_KEY = 'event'

export function* fetchEventSaga(action: PayloadAction<FetchEventPayload>) {
  yield put(
    eventsProviderActions.fetchEvent({
      reqData: action.payload,
      // TODO: fix TS error
      // @ts-ignore
      sideEffects: eventsProviderSideEffects[SOURCE_KEY]
    })
  )
}

export function* fetchEventsByIdsSaga(
  action: PayloadAction<FetchEventsByIdsPayload>
): Generator {
  const {
    ids,
    params = { withLinkedEvents: false, withCompetitorsLogo: false }
  } = action.payload

  const fetchEventTasks = ids.map((eventId) =>
    // @ts-ignore
    call(coreFetchEventSaga, {
      payload: {
        params,
        reqData: getFetchEventReqData({ eventId }),
        sideEffects: eventsProviderSideEffects[SOURCE_KEY]
      },
      type: eventsProviderActions.fetchEvent.type
    })
  )

  yield all(fetchEventTasks)
}

export function* eventContainerSaga() {
  yield takeLatest(actions.fetchEvent.type, fetchEventSaga)
  yield takeLatest(actions.fetchEventsByIds.type, fetchEventsByIdsSaga)
}
