import { PAYMENT_METHODS } from 'astra-core/containers/BalanceProvider'
import { LayoutTheme } from 'betweb-openapi-axios'
import * as Yup from 'yup'

import { PAYMENT_IMG } from 'pages/page-balance/Balance/constants'

export const BANK_CARD_IMG_LIST = (typeLayoutTheme: LayoutTheme) => [
  { src: PAYMENT_IMG().mastercard, alt: 'mastercard', size: '20' },
  { src: PAYMENT_IMG(typeLayoutTheme).visa, alt: 'visa', size: '29' },
  { src: PAYMENT_IMG().mir, alt: 'mir', size: '13' }
]

export const FORMIK_SCHEMA_VALIDATION_BANK_CARD = Yup.object().shape({
  [PAYMENT_METHODS.CARD]: Yup.string()
    .label(PAYMENT_METHODS.CARD)
    .length(16, 'invalid card number')
    .required('required')
})
