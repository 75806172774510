import React, { FC } from 'react'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

import { SportIcon } from 'shared/ui/Icon/SportIcon'

import {
  LineTournamentGroupSportProps,
  LineTournamentsGroupListProps
} from './TournamentsGroupList.types'
import {
  StyledLineTournamentGroup,
  StyledLineTournamentGroupSport,
  StyledLineTournamentGroupSportText,
  TournamentGroupDivider
} from './TournamentsGroupList.styled'
import { LineTournamentsGroup } from './components/TournamentsGroup'

export const LineTournamentsGroupList: FC<LineTournamentsGroupListProps> = ({
  groupedTournaments,
  showHeader
}) => {
  return (
    <>
      {groupedTournaments.map((group) => (
        <StyledLineTournamentGroup key={group.id}>
          {showHeader && <LineTournamentGroupHeader sport={group} />}
          <LineTournamentsGroup key={group.name} tournamentsGroup={group} />
          <TournamentGroupDivider />
        </StyledLineTournamentGroup>
      ))}
    </>
  )
}

const LineTournamentGroupHeader: FC<LineTournamentGroupSportProps> = ({
  sport
}) => {
  return (
    <StyledLineTournamentGroupSport>
      <SportIcon size={16} sportCode={sport.code as ESportsCodes} isActive />
      <StyledLineTournamentGroupSportText>
        {sport.name}
      </StyledLineTournamentGroupSportText>
    </StyledLineTournamentGroupSport>
  )
}
