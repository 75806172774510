import React, { FC, memo, useCallback, useRef, useState } from 'react'
import { Event, EventStatus } from 'betweb-openapi-axios'
import { getEventStatusType } from 'astra-core/utils/events'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { HotProbsMarketsOutcomesMapItem } from 'astra-core/containers/CommonDataProvider'
import isEqual from 'react-fast-compare'
import { useGetHotProbsSportMarketOutcomeTypes } from 'astra-core/hooks/useGetHotProbsSportMarketOutcomeTypes'
import { useLocation } from 'react-router-dom'

import { ETestData } from 'shared/lib/testData'
import {
  CoefficientTableCellStyled,
  EventRow as EventRowEntity,
  EventRowExpandButton
} from 'entities/event'
import { useEventRowHandleHover, useExpandEventRow } from 'features/event'
import {
  Outcomes,
  OutcomesComponentType
} from 'pages/page-event/Event/components/Outcomes'
import {
  Outcomes as OutcomesV3,
  OutcomesComponentType as OutcomesComponentTypeV3
} from 'pages/page-event/Event/components/OutcomesV3'
import { ERoutes } from 'shared/types/routes'

import { TableBetsRowContext } from '../../lib/context'
import { TeamWrapper } from '../teams-names'
import { EventRowCoefficient } from '../coefficient'
import { LinkedEvents } from '../linked-events'
import { OutcomesHead } from '../outcomes-head'
import { EventInfo } from '../event-info'
import { EventRowMessage } from '../message'

import { ITableColumnHeaderListProps } from './event-row.types'

const CoefficientsList: FC<{
  outcomeTypes?: HotProbsMarketsOutcomesMapItem[]
  event: Event
}> = ({ outcomeTypes, event }) => {
  return (
    <>
      {outcomeTypes?.map((coefficientData) => (
        <EventRowCoefficient
          {...coefficientData}
          event={event}
          key={coefficientData.outcomeTypesName[0]}
        />
      ))}
    </>
  )
}

const toggleTestData = {
  expand: ETestData.TestEventRowFilterExpandAll,
  collapsed: ETestData.TestEventRowFilterCollapseAll
}

export const EventRow: FC<ITableColumnHeaderListProps> = memo(
  ({ event, isMainPage, parentEventId }) => {
    const isShowAllEventProbs = useFeatureFlag(
      EFeatureFlags.SHOW_ALL_EVENT_PROBS
    )
    const isLinkedEventsEnabled = useFeatureFlag(
      EFeatureFlags.LINKED_EVENTS_ENABLED
    )
    const newOutcomesEnabled = useFeatureFlag(
      EFeatureFlags.NEW_OUTCOMES_ENABLED
    )

    const [isLinkedEventsOpened, setIsLinkedEventsOpen] = useState(true)

    const toggleLinkedEventsOpen = useCallback(() => {
      setIsLinkedEventsOpen((prev) => !prev)
    }, [])

    const { pathname } = useLocation()
    const isNotSearchPage = pathname !== ERoutes.Search
    const outcomesRef = useRef(null)
    const isSuspended = event.status === EventStatus.Suspended
    const { toggleOutcomesOpen, isOutcomesOpened } = useExpandEventRow({
      isSuspended
    })

    const showLinkedEvents =
      isLinkedEventsOpened && isLinkedEventsEnabled && isNotSearchPage

    const { handleMouseEnter, handleMouseLeave } = useEventRowHandleHover({
      eventId: event.id,
      isHoverAvailable: !isOutcomesOpened
    })

    const isLoadingOutcomes = isShowAllEventProbs
      ? !event
      : event.status === EventStatus.Suspended

    const { outcomeTypes } = useGetHotProbsSportMarketOutcomeTypes({
      sportId: event.tournament.sportId
    })

    const { eventStatusType } = getEventStatusType(event)

    return (
      <TableBetsRowContext.Provider value={{ event }}>
        <EventRowEntity
          coefficientsItems={
            <CoefficientsList event={event} outcomeTypes={outcomeTypes} />
          }
          expandedData={
            isOutcomesOpened && (
              <OutcomesHead
                event={event}
                inputTestData={ETestData.TestEventRowInputSearch}
                outcomesRef={outcomesRef}
                toggleTestData={toggleTestData}
              />
            )
          }
          infoCellComponents={
            parentEventId ? (
              <TeamWrapper event={event}>{event?.eventType?.name}</TeamWrapper>
            ) : (
              <EventInfo
                event={event}
                isLinkedEventsOpened={isLinkedEventsOpened}
                isMainPage={isMainPage}
                toggleLinkedEventsOpen={toggleLinkedEventsOpen}
              />
            )
          }
          lastCell={
            <CoefficientTableCellStyled withExpandButton>
              <EventRowExpandButton onClick={toggleOutcomesOpen}>
                {event.outcomesCount}
              </EventRowExpandButton>
            </CoefficientTableCellStyled>
          }
          linkedEvents={
            showLinkedEvents &&
            event.linkedEvents && (
              <LinkedEvents
                linkedEvents={event.linkedEvents}
                parentEventId={event.id}
              />
            )
          }
          outcomesComponent={
            isOutcomesOpened ? (
              newOutcomesEnabled ? (
                <OutcomesV3
                  eventId={event.id}
                  eventStatusType={eventStatusType}
                  isLoading={isLoadingOutcomes}
                  outcomesRef={outcomesRef}
                  type={OutcomesComponentTypeV3.EVENTS_LIST}
                />
              ) : (
                <Outcomes
                  eventId={event.id}
                  eventStatusType={eventStatusType}
                  isLoading={isLoadingOutcomes}
                  outcomesRef={outcomesRef}
                  type={OutcomesComponentType.EVENTS_LIST}
                />
              )
            ) : null
          }
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          message={!!outcomeTypes?.length && isSuspended && <EventRowMessage />}
        />
      </TableBetsRowContext.Provider>
    )
  },
  isEqual
)
