import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'

import { actions, reducer, sliceKey } from './slice'
import { eventContainerSaga } from './saga'
import { getFetchEventReqData } from './utils'
import { EventParams } from './types'

export function useCompletedEventContainer() {
  useInjectReducer({ key: sliceKey, reducer })
  useInjectSaga({ key: sliceKey, saga: eventContainerSaga })

  const { eventId } = useParams<EventParams>()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.fetchEvent(getFetchEventReqData({ eventId })))
  }, [dispatch, eventId])
}
