import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'
import { LayoutTheme } from 'betweb-openapi-axios'
import { cyberbetStoreActions } from 'astra-core/containers/CyberbetProvider'

export const useSetCyberbetConfigParams = ({
  frameId
}: {
  frameId: string
}) => {
  const dispatch = useDispatch()
  const currentTheme = useSelector(selectUserSettingLayoutTheme)

  useEffect(() => {
    dispatch(cyberbetStoreActions.setContentElement(`#${frameId}`))
  }, [dispatch, frameId])

  useEffect(() => {
    dispatch(
      cyberbetStoreActions.setDarkMode(currentTheme === LayoutTheme.Dark)
    )
  }, [currentTheme, dispatch])
}
