import styled, { css } from 'styled-components'

import { StyledFooterWrapper } from 'entities/footer-of-page/ui/footer-of-page/footer-of-page.styled'

import { StyledTopDivider } from './components/TournamentsGroupList/components/TournamentsGroup/TournamentGroup.styled'

// TODO: OL-553 Remove after release
// Get rid of isEmpty if returned to grid layout system
export const StyledLineWrapper = styled.div<{
  $hideTopDivider?: boolean
  $isEmpty?: boolean
}>`
  flex: 1;
  margin-bottom: 10px;
  & ${StyledTopDivider} {
    display: ${(props) => (props.$hideTopDivider ? 'none' : 'block')};
  }
  ${({ $isEmpty }) => {
    if ($isEmpty) {
      return css`
        margin: auto;

        & + ${StyledFooterWrapper} {
          margin-top: initial;
        }
      `
    }
  }}
`

export const StyledLoadingIndicatorWrapper = styled.div`
  height: 100%;
`
