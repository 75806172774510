import styled from 'styled-components'

import { Layout } from 'widgets/Layout'
import { StyledContent } from 'widgets/Layout/components/Default/Default.styled'
import { STYLED_HEADER_HEIGHT } from 'widgets/header/Header.styled'

export const LayoutStyled = styled(Layout)`
  ${StyledContent} {
    align-items: center;
    display: flex;
    height: calc(100vh - ${STYLED_HEADER_HEIGHT}px);
    justify-content: center;
    width: 100%;
  }
`
