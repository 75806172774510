import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  searchProviderActions,
  SearchTabsType,
  selectSearchEventsItems,
  selectSearchTabType,
  selectSearchTournamentsItems
} from 'astra-core/containers/SearchProvider'

import {
  StyledSearchInputTabs,
  StyledSearchInputTabsItem
} from './SearchTabs.styled'

export const SearchTabs = () => {
  const [t] = useTranslation()
  const type = useSelector(selectSearchTabType)
  const dispatch = useDispatch()

  const eventsItems = useSelector(selectSearchEventsItems)
  const tournamentsItems = useSelector(selectSearchTournamentsItems)

  const toggleSelectTab = useCallback(
    (value: SearchTabsType) =>
      dispatch(searchProviderActions.setSearchTab({ tab: value })),
    [dispatch]
  )

  return (
    <StyledSearchInputTabs variant={5}>
      <StyledSearchInputTabsItem
        active={type === SearchTabsType.Events}
        handleTabChange={() => toggleSelectTab(SearchTabsType.Events)}
      >
        {t('events')} <span>{eventsItems.length}</span>
      </StyledSearchInputTabsItem>
      <StyledSearchInputTabsItem
        active={type === SearchTabsType.Tournaments}
        handleTabChange={() => toggleSelectTab(SearchTabsType.Tournaments)}
      >
        {t('championships')} <span>{tournamentsItems.length}</span>
      </StyledSearchInputTabsItem>
    </StyledSearchInputTabs>
  )
}
