import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider/slice'
import { isGenericSportEnabled } from 'astra-core/containers/ConfigProvider/saga'
import { ApiManager } from 'astra-core/api'
import {
  ErrorCode,
  SportsCountersApi,
  SportsCountersApiGetScheduledSportsCountersRequest
} from 'betweb-openapi-axios'

import { eventsProviderSideEffects } from 'shared/lib/eventsProviderSideEffects'

import { actions } from './slice'
import { TFetchScheduledEventsPayload } from './types'
import {
  getFilterCurrentSportId,
  selectFilterSelectInputPeriod
} from './selectors'

const SOURCE_KEY = 'calendarEvents'

export function* fetchScheduledEventsSaga(
  action: PayloadAction<TFetchScheduledEventsPayload>
) {
  const currentSportId = yield select(getFilterCurrentSportId)
  const isGenericSportsEnabled = yield call(isGenericSportEnabled)

  yield put(
    eventsProviderActions.fetchScheduledLiveEvents({
      reqData: { ...action.payload, sportId: currentSportId },
      sideEffects: eventsProviderSideEffects[SOURCE_KEY],
      params: {
        withCompetitorsLogo: false,
        withLinkedEvents: true,
        includeSubsports: isGenericSportsEnabled || undefined
      }
    })
  )
}

export function* fetchScheduledEventsCountersSaga() {
  try {
    const api = ApiManager.getApi(SportsCountersApi)
    const filterPeriod = yield select(selectFilterSelectInputPeriod)
    // TODO:PLAT-867 Remove after release
    const isGenericSportsEnabled = yield call(isGenericSportEnabled)

    const request: SportsCountersApiGetScheduledSportsCountersRequest = {
      ...filterPeriod,
      aggregateByGeneric: isGenericSportsEnabled || undefined
    }

    const {
      data: { items }
    } = yield call(() => api.getScheduledSportsCounters(request))

    yield put(actions.fetchScheduledEventsCountersSuccess({ items }))
  } catch (e) {
    yield put(
      actions.fetchScheduledEventsCountersError({
        message: (e as Error).message,
        title: '',
        code: ErrorCode.Unknown,
        reasons: []
      })
    )
  }
}

export function* calendarEventsContainerSaga() {
  yield all([
    takeLatest(actions.fetchScheduledEvents.type, fetchScheduledEventsSaga),
    takeLatest(
      actions.fetchScheduledEventsCounters,
      fetchScheduledEventsCountersSaga
    ),
    takeLatest(actions.setCurrentSportIdFilter, fetchScheduledEventsSaga)
  ])
}
