import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { PromotionsAndBonuses } from './PromotionsAndBonuses'

export const PagePromotionAndBonuses = () => (
  <Layout pageTitle={{ text: 'bonus coupons' }} type={ELayoutsList.PROFILE}>
    <PromotionsAndBonuses />
  </Layout>
)
