import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { verificationActions } from 'og-core/providers/verification/slice'

import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { IconSuccess } from 'shared/ui/Icon/General/IconSuccess'

import {
  StyledFullRegistrationHeaderExit,
  StyledFullRegistrationHeaderWrapper,
  StyledFullRegistrationWrapper,
  StyledText,
  StyledTextSuccessDescription,
  StyledTextSuccessTitle
} from './FullRegistrationStepThree.styled'

export const FullRegistrationStepThreeSuccess = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const closeAuthenticationModal = useCallback(() => {
    dispatch(
      verificationActions.changeCurrentVerificationStep({
        step: null
      })
    )
  }, [dispatch])

  return (
    <StyledFullRegistrationWrapper>
      <StyledFullRegistrationHeaderWrapper>
        <StyledFullRegistrationHeaderExit
          colorProps={{
            name: EColorsNames.Primary,
            value: 63,
            type: EColorsTypes.CUSTOM
          }}
          size={10}
          onClick={closeAuthenticationModal}
        />
      </StyledFullRegistrationHeaderWrapper>
      <StyledText>
        <IconSuccess size={32} />
        <StyledTextSuccessTitle>
          {t('verification was successful')}
        </StyledTextSuccessTitle>
        <StyledTextSuccessDescription>
          {t('access to all site features')}
        </StyledTextSuccessDescription>
      </StyledText>
    </StyledFullRegistrationWrapper>
  )
}
