import { PayloadAction } from '@reduxjs/toolkit'
import { Tournament } from 'betweb-openapi-axios'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { ErrorPayload } from 'astra-core/typings/api'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'
import { requestInitialState } from 'shared/lib/api'
import { getPreloadedState, toIdsArray } from 'shared/lib/@reduxjs'
import { sortAlphabeticallyByField } from 'shared/lib/arrays'
import { getItemLocalStorage } from 'shared/lib/@system'
import { INITIAL_FILTER_PERIOD_ID } from 'pages/page-live-events/Line/components/ui/LineHeader/components/LineHeaderFilters/constants'

import {
  ContainerState,
  FetchLinePayload,
  FetchLineSuccessPayload,
  FetchLineTournamentsPayload,
  FetchLineTournamentsSuccessPayload,
  SetFilterPeriodPayload,
  SetFilterSportIdPayload,
  TFormatLineTournamentsFiltersParams
} from './types'

const REDUCER_KEY = 'lineTournamentsContainer'
export const LINE_TOURNAMENT_FILTER_LS = 'lineTournamentsFilter'

export const defaultState = {
  tournamentsIdsBySport: {},
  eventsIdsByTournament: {},
  fetchItems: requestInitialState,
  fetchEventsItems: requestInitialState,
  filters: {
    isTop: false,
    period: {
      id: INITIAL_FILTER_PERIOD_ID,
      applicationTime: '',
      scheduledFrom: '',
      scheduledTo: ''
    }
  },
  layouts: {
    isLoading: false,
    isEventsLoading: false
  }
}

// The initial state of the LineTournamentsContainer container
export const initialState: ContainerState = getPreloadedState(
  REDUCER_KEY,
  defaultState
)

const lineTournamentsContainerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    fetchLineTournaments(
      state,
      _action: PayloadAction<FetchLineTournamentsPayload>
    ) {
      state.fetchItems.loading = true
      state.fetchItems.error = null
    },
    fetchLineTournamentsSuccess(
      state,
      action: PayloadAction<FetchLineTournamentsSuccessPayload>
    ) {
      const { sportId } = action.payload.reqData
      const { items } = action.payload.resData
      state.fetchItems.loading = false
      state.fetchItems.error = null

      state.layouts.isLoading = false
      state.layouts.isEventsLoading = false

      if (sportId) {
        const filteredByAlphabetItems = sortAlphabeticallyByField<Tournament>(
          items,
          'name'
        )

        const savedLSFilter: TFormatLineTournamentsFiltersParams =
          getItemLocalStorage(LINE_TOURNAMENT_FILTER_LS)

        if (savedLSFilter) {
          state.filters.isTop = savedLSFilter.isTop
        }

        state.tournamentsIdsBySport[sportId] = toIdsArray(
          filteredByAlphabetItems
        )
      }
    },
    fetchLineTournamentsError(state, action: PayloadAction<ErrorPayload>) {
      state.fetchItems.loading = false
      state.fetchItems.error = action.payload

      state.layouts.isLoading = false
      state.layouts.isEventsLoading = false
    },
    toggleFilterIsTop(state) {
      if (!state.layouts.isLoading || !state.layouts.isEventsLoading) {
        state.filters.isTop = !state.filters.isTop
        state.layouts.isLoading = true
        state.layouts.isEventsLoading = true
        state.tournamentsIdsBySport = defaultState.tournamentsIdsBySport
        state.eventsIdsByTournament = defaultState.eventsIdsByTournament
      }
    },
    setFilterPeriod(state, { payload }: PayloadAction<SetFilterPeriodPayload>) {
      state.filters.period = {
        ...state.filters.period,
        ...payload,
        applicationTime:
          payload.id !== INITIAL_FILTER_PERIOD_ID ? dayjs().format() : ''
      }
      state.layouts.isLoading = true
      state.layouts.isEventsLoading = true
    },
    setFilterSportId(
      state,
      { payload }: PayloadAction<SetFilterSportIdPayload>
    ) {
      state.filters.sportId = payload.sportId
    },
    resetLineFilter(state) {
      state.filters = defaultState.filters
      state.layouts.isLoading = true
      state.fetchEventsItems.loading = true
    },
    fetchLine(state, _action: PayloadAction<FetchLinePayload>) {
      state.fetchEventsItems.loading = true
      state.fetchEventsItems.error = null
    },
    fetchLineSuccess(state, action: PayloadAction<FetchLineSuccessPayload>) {
      const { tournamentId } = action.payload.reqData
      const { items } = action.payload.resData

      state.fetchEventsItems.loading = false
      state.fetchEventsItems.error = null
      state.layouts.isEventsLoading = false

      if (tournamentId) {
        state.eventsIdsByTournament[tournamentId] = toIdsArray(items)

        const savedLSFilter: TFormatLineTournamentsFiltersParams =
          getItemLocalStorage(LINE_TOURNAMENT_FILTER_LS)

        if (savedLSFilter) {
          state.filters.isTop = savedLSFilter.isTop
        }
      }
    },
    fetchLineError(state, action: PayloadAction<ErrorPayload>) {
      state.fetchEventsItems.loading = false
      state.fetchEventsItems.error = action.payload
      state.layouts.isEventsLoading = false
    }
  }
})

export const {
  actions: lineTournamentsContainerActions,
  reducer: reducerLine,
  name: sliceKeyLine
} = lineTournamentsContainerSlice
