import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  betsHistoryProviderActions,
  selectBetsHistoryCollapsedBets,
  selectBetsHistoryPageValues
} from 'astra-core/containers/BetsHistoryProvider'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { isEqualDepth } from 'astra-core/utils'

import { useFetchBetsHistoryPage } from 'pages/page-bets-history/BetsHistoryPageContainer/hooks'
import { useAppSelector } from 'app/lib/store'

import { Filter, SectionSelect, Table } from './components'
import { ITableData } from './History.types'
import { StyledHistory } from './History.styled'

export const History = () => {
  const dispatch = useDispatch()
  const { collapsedList } = useAppSelector(
    selectBetsHistoryCollapsedBets,
    isEqualDepth
  )
  const betsListTableData = useSelector(selectBetsHistoryPageValues)
  const isCashoutEnabled = useFeatureFlag(EFeatureFlags.CASHOUT_ENABLED)

  useFetchBetsHistoryPage()

  useEffect(() => {
    return () => {
      dispatch(betsHistoryProviderActions.resetBetsHistory())
    }
  }, [dispatch])

  const onClickRowCollapse = useCallback(
    (id) => dispatch(betsHistoryProviderActions.setCollapsedBets(id)),
    [dispatch]
  )

  useEffect(() => {
    if (isCashoutEnabled) {
      dispatch(betsHistoryProviderActions.subscribeCashoutSSE())

      return () => {
        dispatch(betsHistoryProviderActions.unsubscribeCashoutSSE())
      }
    }
  }, [dispatch, isCashoutEnabled])

  return (
    <StyledHistory>
      <SectionSelect />

      <Filter />

      <Table
        collapsedRows={collapsedList}
        data={betsListTableData as ITableData[]}
        onRowCollapse={onClickRowCollapse}
      />
    </StyledHistory>
  )
}
