import React, { FC, memo, useCallback, useContext } from 'react'
import {
  ESportsCodes,
  selectMatchPhase,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { useSelector } from 'react-redux'
import { useToggleEventFavourite } from 'astra-core'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'
import { useEventBackLink } from 'og-web-core/utils/events'
import { BasketQuickBetContext } from 'og-web-core/providers/basket/quick-bet'

import { ETestData } from 'shared/lib/testData'
import {
  EventContext,
  IEventContext
} from 'pages/page-event/Event/Event.context'
import { RootState } from 'shared/types/store'
import { IconChevronLeft } from 'shared/ui/Icon/General/IconChevronLeft'
import { BasketButtonSmallWindow } from 'widgets/right-container/Basket/BasketButtonSmallWindow'
import { TeamLogo } from 'shared/ui/Icon/TeamLogo'
import { IconFavorite } from 'shared/ui/Icon/General/IconFavorite'
import { routesWithoutPrevPath } from 'features/event/row/open-event-page/lib'
import { BasketQuickBetButton } from 'widgets/right-container/Basket/BasketQuickBetButton/BasketQuickBetButton'

import { useEventBannerData } from '../../hooks'
import { StyledEventHead, StyledEventTitle } from '../../../../Event.styled'

import { EventDateTime, Stats } from './components'
import { ActionsList } from './components/Actions/Actions'
import { ICON_ACTION_PROPS } from './constants'
import {
  CurrentServerFlag,
  StyledActionFavourite,
  StyledActionsWrapper,
  StyledBannerCompetitor,
  StyledBannerCompetitors,
  StyledBannerCompetitorsHeader,
  StyledBannerContent,
  StyledBannerMainContent,
  StyledComment,
  StyledCommentsWrapper,
  StyledDateTimeEventWrapepr,
  StyledEventBanner,
  StyledEventBannerHeader,
  StyledEventBannerHeaderControls,
  StyledEventBannerWrapper,
  StyledEventEmblemWrapper,
  StyledEventStatistics,
  StyledLiveDotsIndicator,
  StyledMatchPhase,
  StyledNameTeamWrapper,
  StyledStatisticsWrapper,
  StyledTeamsSection
} from './EventBannerLargeFeatureFlag.styled'
import { ScoreByPeriods } from './components/ScoreByPeriods'
import {
  EventCommentsProps,
  EventCompetitorsBannerProps,
  EventCompetitorsProps
} from './EventBannerLargeFeatureFlag.types'

export const EventBannerLargeFeatureFlag: FC = memo(() => {
  const { event, isSpecialStatus } = useContext(EventContext) as IEventContext

  const { competitors, bannerScore, containerRef, currentServer, matchPhase } =
    useEventBannerData()

  const sportData = useSelector((state: RootState) =>
    selectSport(state, event.tournament.sportId)
  )

  const currentMatchPhase = useSelector((state: RootState) =>
    selectMatchPhase(state, Number(matchPhase?.value))
  )

  const redirectToBack = useEventBackLink({
    event,
    sportData,
    routesWithoutPrevPath
  })

  const { isEventFavourite, toggleEventFavourite } = useToggleEventFavourite(
    event.id
  )

  const user = useSelector(selectAccountUser)
  const { isQuickBetActive } = useContext(BasketQuickBetContext)

  const handleClickFavorite = useCallback(() => {
    if (user) {
      toggleEventFavourite()
    }
  }, [toggleEventFavourite, user])

  const hasComments = !!event.comments.length
  const isQuickBetButtonVisible = isQuickBetActive && user

  return (
    <StyledEventBannerWrapper
      data-test-id={ETestData.TestEventBannerWrapper}
      ref={containerRef}
      sportCode={sportData?.code as ESportsCodes}
    >
      <StyledEventBannerHeader>
        <StyledEventBannerHeaderControls>
          <StyledEventHead
            to={{
              pathname: redirectToBack,
              state: { maintainLineFilter: true }
            }}
          >
            <IconChevronLeft
              data-test-id={ETestData.TestIconButtonBackHeader}
            />

            <StyledEventTitle>
              {sportData?.name}. {event.tournament.name}
            </StyledEventTitle>

            {isQuickBetButtonVisible ? (
              <BasketQuickBetButton />
            ) : (
              <BasketButtonSmallWindow />
            )}
          </StyledEventHead>

          <StyledActionFavourite
            $isActive={isEventFavourite}
            data-test-id={ETestData.TestEventIconStarButton}
            onClick={handleClickFavorite}
          >
            <IconFavorite
              {...ICON_ACTION_PROPS}
              $isActiveState={isEventFavourite}
              withActive
            />
          </StyledActionFavourite>
        </StyledEventBannerHeaderControls>
      </StyledEventBannerHeader>

      <StyledEventBanner>
        <StyledBannerContent>
          <StyledBannerMainContent>
            <EventCompetitorsBanner
              bannerScore={bannerScore}
              competitors={competitors}
              currentMatchPhase={currentMatchPhase?.name}
              currentServer={currentServer}
              eventId={event.id}
              isLive={event.live && !isSpecialStatus}
              sportCode={sportData?.code as ESportsCodes}
            />

            {hasComments && <EventComments comments={event.comments} />}
          </StyledBannerMainContent>

          <StyledActionsWrapper>
            <ActionsList sportCode={sportData?.code as ESportsCodes} />
          </StyledActionsWrapper>
        </StyledBannerContent>
      </StyledEventBanner>
    </StyledEventBannerWrapper>
  )
})

const EventComments: FC<EventCommentsProps> = ({ comments }) => {
  const commentsFromat = [comments.join(', ')]
  return (
    <StyledCommentsWrapper>
      {commentsFromat?.map((comment) => (
        <StyledComment key={comment}>{comment}</StyledComment>
      ))}
    </StyledCommentsWrapper>
  )
}

const EventCompetitors: FC<EventCompetitorsProps> = ({
  competitors,
  currentServer,
  sportCode
}) => {
  const competitorsArr = Object.keys(competitors)

  const isActiveServer = useCallback(
    (index: string) => {
      if (currentServer && currentServer.value) {
        return currentServer.value === index
      }

      return false
    },
    [currentServer]
  )

  return (
    <StyledTeamsSection>
      {competitorsArr?.map((item, index) => (
        <StyledNameTeamWrapper key={item}>
          <CurrentServerFlag $isActive={isActiveServer(String(index + 1))} />

          <TeamLogo logoUrl={competitors[item][0]?.competitorLogo?.logoUrl} />

          <CompetitorItem
            name={competitors[item][0]?.name}
            sportCode={sportCode as ESportsCodes}
          />
        </StyledNameTeamWrapper>
      ))}
    </StyledTeamsSection>
  )
}

type CompetitorProps = { name?: string; sportCode: ESportsCodes }
const CompetitorItem: FC<CompetitorProps> = memo(({ name, sportCode }) => {
  return (
    <StyledBannerCompetitor sportCode={sportCode as ESportsCodes}>
      {name}
    </StyledBannerCompetitor>
  )
})

const EventCompetitorsBanner: FC<EventCompetitorsBannerProps> = ({
  competitors,
  bannerScore,
  currentServer,
  isLive,
  eventId,
  currentMatchPhase,
  sportCode
}) => {
  return (
    <StyledBannerCompetitors>
      <StyledEventStatistics>
        <StyledEventEmblemWrapper>
          <StyledBannerCompetitorsHeader>
            <StyledDateTimeEventWrapepr>
              {isLive && <StyledLiveDotsIndicator />}
              <EventDateTime />
            </StyledDateTimeEventWrapepr>
            {currentMatchPhase && (
              <StyledMatchPhase>{currentMatchPhase}</StyledMatchPhase>
            )}
          </StyledBannerCompetitorsHeader>
          <EventCompetitors
            competitors={competitors}
            currentServer={currentServer}
            sportCode={sportCode}
          />
        </StyledEventEmblemWrapper>

        <StyledStatisticsWrapper>
          {isLive && (
            <>
              <Stats eventId={eventId} />
              <ScoreByPeriods bannerScore={bannerScore} isLive={isLive} />
            </>
          )}
        </StyledStatisticsWrapper>
      </StyledEventStatistics>
    </StyledBannerCompetitors>
  )
}
