import React, { FC, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ALL_BASKET_ERROR_MESSAGES } from 'astra-core/containers/BasketProvider/constants'
import { BasketQuickBetContext } from 'og-web-core/providers/basket/quick-bet'

import { ETestData } from 'shared/lib/testData'
import { IconWarning } from 'shared/ui/Icon/General/IconWarning'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { ERoutes } from 'shared/types/routes'

import {
  StyledIconWrapper,
  StyledLink,
  StyledQuickBetErrorText,
  StyledQuickBetErrorWrapper
} from './BasketQuickBetError.styled'
import {
  BasketQuickBetErrorProps,
  EBasketQuickBetError
} from './BasketQuickBetError.types'

export const BasketQuickBetError: FC<BasketQuickBetErrorProps> = (props) => {
  const { error } = props
  const [t] = useTranslation()

  const { setQuickBetModalVisible, setLowerBalanceModalVisible, setModal } =
    useContext(BasketQuickBetContext)

  const onClickLink = () => {
    setQuickBetModalVisible(false)
    setLowerBalanceModalVisible(false)
    setModal(null)
  }

  return (
    <StyledQuickBetErrorWrapper>
      <StyledIconWrapper>
        <IconWarning
          colorProps={{
            name: EColorsNames.Yellow,
            value: 10,
            type: EColorsTypes.CUSTOM
          }}
          size={16}
        />
      </StyledIconWrapper>
      <StyledQuickBetErrorText
        data-test-id={ETestData.TestQuickBetInputValidationWarning}
      >
        {error === EBasketQuickBetError.insufficientBalance && (
          <Trans
            components={[
              <StyledLink to={ERoutes.ProfileBalance} onClick={onClickLink} />
            ]}
            i18nKey={ALL_BASKET_ERROR_MESSAGES.BETS_INSUFFICIENT_BALANCE}
            t={t}
          />
        )}
        {error === EBasketQuickBetError.minBetError &&
          t(ALL_BASKET_ERROR_MESSAGES.MIN_AMOUNT_MUST_EXCEED)}
      </StyledQuickBetErrorText>
    </StyledQuickBetErrorWrapper>
  )
}
