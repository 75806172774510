import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  getOutcomeTypeById,
  selectOutcomeCategoryById
} from 'astra-core/containers/CommonDataProvider'
import { getBasketOutcomeName } from 'astra-core/utils/outcomes'
import { BetOutcome, OutcomeCategory, OutcomeType } from 'betweb-openapi-axios'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { selectVirtualMarketInfosMap } from 'astra-core/containers/CommonDataProvider/selectors'
import { selectConfig } from 'astra-core/containers/ConfigProvider/selectors'
import { selectPlayers } from 'astra-core/containers/PlayersProvider/selectors'
import { getOutcomeNameInBetsHistory } from 'astra-core/utils/outcomes/computeEventProbabilitiesByMarket/v3'

import { RootState } from 'shared/types/store'

import { StyledCellOutcome } from '../../Outcome.styled'

export const OutcomeName = ({ outcome }: { outcome: BetOutcome }) => {
  const outcomeType = useSelector((state: RootState) =>
    getOutcomeTypeById(state, outcome.outcomeTypeId)
  )
  const outcomeCategory = useSelector((state: RootState) =>
    selectOutcomeCategoryById(state, outcome.outcomeCategoryId)
  )
  const newOutcomesEnabled = useFeatureFlag(EFeatureFlags.NEW_OUTCOMES_ENABLED)

  return newOutcomesEnabled ? (
    <OutcomeNameNew
      outcome={outcome}
      outcomeCategory={outcomeCategory}
      outcomeType={outcomeType}
    />
  ) : (
    <OutcomeNameDeprecated
      outcome={outcome}
      outcomeCategory={outcomeCategory}
      outcomeType={outcomeType}
    />
  )
}

interface Props {
  outcome: BetOutcome
  outcomeCategory?: OutcomeCategory
  outcomeType?: OutcomeType
}

const OutcomeNameNew = ({ outcome, outcomeType, ...props }: Props) => {
  const virtualMarketInfosMap = useSelector(selectVirtualMarketInfosMap)
  const config = useSelector(selectConfig)
  const playersMap = useSelector(selectPlayers)

  const outcomeName = useMemo(() => {
    if (!outcomeType) return ''
    return getOutcomeNameInBetsHistory(
      outcome,
      virtualMarketInfosMap,
      outcomeType,
      config.CLIENT_TYPE,
      playersMap,
      outcome?.event?.competitors || []
    )
  }, [
    outcome,
    virtualMarketInfosMap,
    playersMap,
    outcomeType,
    config.CLIENT_TYPE
  ])

  return (
    <StyledCellOutcome colSpan={2} style={{ borderRight: 'none' }}>
      {outcomeName}
    </StyledCellOutcome>
  )
}

const OutcomeNameDeprecated = ({
  outcome,
  outcomeCategory,
  outcomeType
}: Props) => {
  const outcomeName = useMemo(
    () =>
      getBasketOutcomeName({
        probWithOutcome: { ...outcome, outcomeTypeData: outcomeType! },
        event: outcome.event!,
        isBetOutcome: true,
        outcomeCategory
      }),
    [outcome, outcomeCategory, outcomeType]
  )
  return (
    <StyledCellOutcome colSpan={2} style={{ borderRight: 'none' }}>
      {outcomeName || ''}
    </StyledCellOutcome>
  )
}
