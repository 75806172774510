import React, { FC } from 'react'
import { CompetitorStatistics, Event } from 'betweb-openapi-axios'
import { useTranslation } from 'react-i18next'
import { EEventsStatusList } from 'astra-core/utils/events/types'

import { EventStatusBanner } from 'pages/page-event/Event/components/EventStatusBanner/EventStatusBanner'
import { StyledBannerStats } from 'pages/page-event-completed/EventCompleted/EventCompleted.styled'

import { StatisticsItems, StatisticsMeasureItems } from './StatsFootball.types'
import {
  StyledBannerStatsItem,
  StyledBannerStatsItemScore,
  StyledBannerStatsItemHeader,
  StyledStatsItemTitle,
  StyledBannerStatsBarWrapper,
  StyledBannerStatsBarContainer,
  StyledBannerStatsBarHomeItem,
  StyledBannerStatsBarAwayItem
} from './StatsFootball.styled'
import { calculatePercentages, filterStatisticsItems } from './utils'

const statisticsItems: StatisticsItems<CompetitorStatistics> = [
  {
    field: StatisticsMeasureItems.ShotsOnTarget,
    title: 'shots on target'
  },
  {
    field: StatisticsMeasureItems.Corners,
    title: 'corners'
  },
  {
    field: StatisticsMeasureItems.FreeKicks,
    title: 'falls'
  },
  {
    field: StatisticsMeasureItems.YellowCards,
    title: 'yellow cards'
  }
]

export const StatsFootball: FC<{ event: Event }> = ({ event }) => {
  const [t] = useTranslation()
  const eventStatistics = event.fullStatistics

  const getStatisticsItem = (
    title: string,
    field: keyof CompetitorStatistics
  ) => {
    const homeValue = (field && eventStatistics?.homeStatistics?.[field]) ?? 0
    const awayValue = (field && eventStatistics?.awayStatistics?.[field]) ?? 0

    if (typeof homeValue !== 'number' || typeof awayValue !== 'number') {
      return null
    }

    return (
      <>
        <StyledBannerStatsItemHeader>
          <StyledBannerStatsItemScore>{homeValue}</StyledBannerStatsItemScore>
          <StyledStatsItemTitle>{t(title)}</StyledStatsItemTitle>
          <StyledBannerStatsItemScore>{awayValue}</StyledBannerStatsItemScore>
        </StyledBannerStatsItemHeader>
        <StatsBar awayValue={awayValue} homeValue={homeValue} />
      </>
    )
  }

  if (
    !eventStatistics ||
    !eventStatistics.homeStatistics ||
    !eventStatistics.awayStatistics
  ) {
    return (
      <StyledBannerStats>
        <EventStatusBanner
          eventsStatusListType={EEventsStatusList.STATISTICS_NOT_AVAILABLE}
        />
      </StyledBannerStats>
    )
  }

  const filteredStatisticsItems = filterStatisticsItems(
    statisticsItems,
    eventStatistics.homeStatistics,
    eventStatistics.awayStatistics
  )

  return (
    <StyledBannerStats>
      {filteredStatisticsItems.length === 0 ? (
        <EventStatusBanner
          eventsStatusListType={EEventsStatusList.STATISTICS_NOT_AVAILABLE}
        />
      ) : (
        filteredStatisticsItems.map(({ field, title }) => (
          <StyledBannerStatsItem key={field}>
            {getStatisticsItem(title, field)}
          </StyledBannerStatsItem>
        ))
      )}
    </StyledBannerStats>
  )
}
const StatsBar: FC<{
  homeValue: number
  awayValue: number
}> = ({ homeValue, awayValue }) => {
  const { homePercentage, awayPercentage } = calculatePercentages(
    homeValue,
    awayValue
  )

  return (
    <StyledBannerStatsBarWrapper>
      <StyledBannerStatsBarContainer>
        <StyledBannerStatsBarHomeItem width={homePercentage} />
      </StyledBannerStatsBarContainer>
      <StyledBannerStatsBarContainer>
        <StyledBannerStatsBarAwayItem width={awayPercentage} />
      </StyledBannerStatsBarContainer>
    </StyledBannerStatsBarWrapper>
  )
}
