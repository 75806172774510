import {
  OddsTablePropsNew,
  ProbabilityWithOutcome
} from 'astrabet-templates-kit'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import { VirtualMarket } from 'betweb-openapi-axios'
import { customizeGroupName } from 'astra-core/utils/outcomes/computeEventProbabilitiesByMarket/v3/customizeGroupName'

const getArrayDepth = (value) =>
  Array.isArray(value) ? 1 + Math.max(...value.map(getArrayDepth)) : 0

const wrapTitle = (title: OddsTablePropsNew['title']): string[][] =>
  getArrayDepth(title) === 2
    ? (title as string[][])
    : wrapTitle([title] as string | string[] | string[][])

const removeEmptyElements = (arr: string[][]) => {
  const nonEmptyElements = filter(arr, (item) => !isEmpty(item))
  return isEmpty(nonEmptyElements) ? [''] : nonEmptyElements
}

export const formatTitles = (
  title: OddsTablePropsNew['title'],
  probabilities: ProbabilityWithOutcome[],
  categoryMarket: VirtualMarket,
  competitors: OddsTablePropsNew['competitors']
): string[] | string[][] => {
  const wrappedTitles = wrapTitle(title)
  const result: string[][] = []
  wrappedTitles.forEach((titles) => {
    if (titles !== undefined) {
      const titlesArr: string[] = []
      titles.forEach((t) => {
        if (t !== undefined) {
          const formattedTitle = customizeGroupName(
            t,
            probabilities,
            categoryMarket,
            competitors
          )
          titlesArr.push(formattedTitle || '')
        }
      })
      result.push(titlesArr)
    }
  })
  return removeEmptyElements(result)
}
