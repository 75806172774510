import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectInputSearchLine } from 'astra-core/containers/SearchProvider'
import { useGroupedEventsByGroupId } from 'astra-core/hooks/useEvents'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { RootState } from 'shared/types/store'

import {
  selectIsLiveFilterUsed,
  selectLayoutIsLoading,
  selectLiveEventsBySearch
} from '../LiveContainer/selectors'
import { LiveParams } from '../LiveContainer/types'
import { liveContainerActions } from '../LiveContainer/slice'

import { StyledLiveTable } from './Line.styled'
import { ClearResultSearch, TableBets } from './components/ui'
import { EmptyPageFilter } from './components/EmptyPageFilter'

export const LiveTable: FC = () => {
  const searchText = useSelector(selectInputSearchLine)
  const { sportId } = useParams<LiveParams>()
  const dispatch = useDispatch()
  const isLiveFilterUsed = useSelector(selectIsLiveFilterUsed)

  const events = useSelector((state: RootState) =>
    selectLiveEventsBySearch(state, +sportId)
  )
  const eventsGroups = useGroupedEventsByGroupId(events)
  const isLayoutLoading = useSelector(selectLayoutIsLoading)

  const resetLiveFilter = useCallback(() => {
    dispatch(liveContainerActions.resetLiveFilter({ isResetFilter: true }))
  }, [dispatch])

  return (
    <StyledLiveTable>
      {isLayoutLoading && !eventsGroups.length ? (
        <LoaderSpinner />
      ) : eventsGroups.length ? (
        <TableBets eventsGroups={eventsGroups} isLive />
      ) : searchText ? (
        <ClearResultSearch />
      ) : isLiveFilterUsed ? (
        <EmptyPageFilter onButtonClickResetFilter={resetLiveFilter} />
      ) : null}
    </StyledLiveTable>
  )
}
