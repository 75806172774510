import styled from 'styled-components'

export const OutcomesWrapper = styled.div`
  height: calc(100% - 171px);
`

export const OutcomesLoaderWrap = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.default.primary[5]};
  display: flex;
  height: calc(100% - 189px);
  justify-content: center;
  min-height: 50px;
  position: relative;
  width: 100%;
`

export const OutcomesTables = styled.div`
  background-color: ${({ theme }) => theme.colors.custom.primary[36]};
  height: max-content;
  padding: 0.5rem;

  &:empty {
    display: none;
  }
`
