import { createContext } from 'react'

import { TableBetsEventHeaderProps } from './TableBets.types'

export interface TableBetsContextProps {
  tableEventHead?: TableBetsEventHeaderProps
  isLive?: boolean
  isLinkHead?: boolean
  isFavourite?: boolean
}

export const TableBetsContext = createContext<TableBetsContextProps>({
  tableEventHead: { withSportIcon: false, withSportName: false }
})
