import React from 'react'
import { VirtualGameVendor } from 'betweb-openapi-axios/dist/api'

import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { CyberbetFrame } from 'widgets/cyberbet-frame'
import { ServiceAvailability } from 'shared/ui/ServiceAvailability'

import { LayoutStyled } from './page.styled'

export const PageGamesCyberbet = () => {
  return (
    <LayoutStyled type={ELayoutsList.DEFAULT}>
      <ServiceAvailability vendor={VirtualGameVendor.Oddingg}>
        <CyberbetFrame frameId="oddin-frame" />
      </ServiceAvailability>
    </LayoutStyled>
  )
}
