import React from 'react'
import { Bet } from 'betweb-openapi-axios'
import {
  selectBetsHistoryDataBetsHasMore,
  selectBetsHistoryPagination,
  selectBetsHistoryDataBetsFetchItems,
  betsHistoryProviderActions
} from 'astra-core/containers/BetsHistoryProvider'

import { DataListWithReduxPaginationProps } from 'shared/ui/DataList'

import {
  CellBonusCount,
  CellBonusMark,
  CellButtonToggle,
  CellCoefficient,
  CellDate,
  CellEventBetCount,
  CellEventCashout,
  CellEventNum,
  CellEventTitle,
  CellIcon,
  CellResultAmount,
  CellResultText
} from './components'

export const TABLE_COLUMNS = [
  {
    Header: '',
    id: 'iconWrap',
    columns: [
      {
        accessor: 'status',
        Cell: ({ value }) => <CellIcon betStatus={value} />
      }
    ]
  },
  {
    Header: 'date',
    columns: [
      {
        accessor: 'timestamp',
        Cell: ({ value }) => <CellDate value={value} />
      }
    ]
  },
  {
    Header: 'event',
    columns: [
      {
        accessor: 'id',
        Cell: () => <CellEventNum />
      },
      {
        accessor: 'betType',
        Cell: ({ row }) => (
          <CellEventTitle
            systemType={row.original.systemType ?? ''}
            value={row.original.betType}
          />
        )
      },
      {
        id: 'bet',
        Cell: ({ row: { original: value } }) => (
          <CellEventBetCount value={value} />
        )
      },
      {
        accessor: 'cashout',
        Cell: ({ row }) => <CellEventCashout id={row.original.id} />
      },
      {
        accessor: 'eventAdditional',
        Cell: () => (
          <>
            <td aria-label="Empty cell" />
          </>
        )
      }
    ]
  },
  {
    Header: 'outcome',
    columns: [
      {
        accessor: 'outcomeBonusIcon',
        Cell: ({ value }) => <CellBonusMark value={value} />
      },
      {
        accessor: 'outcomeBonusCount',
        Cell: ({ value }) => <CellBonusCount value={value} />
      },
      {
        accessor: 'coefficient',
        Cell: ({ row }) => <CellCoefficient bet={row.original as Bet} />
      }
    ]
  },
  {
    Header: 'result',
    columns: [
      {
        accessor: 'resultText',
        Cell: ({ row }) => <CellResultText bet={row.original as Bet} />
      },
      {
        id: 'amount',
        Cell: ({ row }) => <CellResultAmount bet={row.original as Bet} />
      }
    ]
  },
  {
    Header: '',
    id: 'expander',
    columns: [
      {
        id: 'expander',
        Cell: ({ row }) => <CellButtonToggle dataItem={row.original} />
      }
    ]
  }
]

export const PAGINATION_SETTINGS = {
  setPaginationAction: betsHistoryProviderActions.setPagination,
  selectorHasMore: selectBetsHistoryDataBetsHasMore,
  selectorPagination: selectBetsHistoryPagination,
  selectorRequestStatus: selectBetsHistoryDataBetsFetchItems
} as DataListWithReduxPaginationProps['pagination']
