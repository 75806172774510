import styled from 'styled-components'

import {
  EventRowOutcomesValues,
  StyledEventRowInfoCell,
  StyledLineTableRowWrapper
} from 'entities/event/row/ui/event-row/event-row.styled'
import { StyledTableCellFirst } from 'entities/event/coefficient/ui/table/coefficient-table.styled'

export const StyledLinkedEventsWrapper = styled.div`
  & > div:last-child {
    ${StyledLineTableRowWrapper} {
      border-bottom: 1px solid
        ${(props) => props.theme.colors.custom.primary[4]};
    }
    ${EventRowOutcomesValues} {
      border-bottom: none;
    }
    ${StyledEventRowInfoCell} {
      border-bottom: none;
    }
  }

  border-left: 1px solid ${(props) => props.theme.colors.custom.primary[4]};
  display: flex;

  flex-direction: column;
`

export const StyledRowWrapper = styled.div`
  ${StyledTableCellFirst} {
    border-left: none;
    margin-left: 16px;
  }
  ${EventRowOutcomesValues} {
    border-bottom: 1px solid ${(props) => props.theme.colors.custom.primary[4]};
  }
  ${StyledEventRowInfoCell} {
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.colors.custom.primary[4]};
    display: flex;
    padding-left: 16px;
  }
  ${StyledLineTableRowWrapper} {
    border-bottom: none;
  }
`

export const StyledExpandButton = styled.div<{ hidden: boolean }>`
  border-bottom: 1px solid ${(props) => props.theme.colors.custom.primary[4]};
  color: ${(props) => props.theme.colors.default.primary[50]};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.custom.primary[37]};
  }
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  font-size: ${(props) => props.theme.fonts.size.xxs};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  padding: 4px 0 4px 20px;
`
