import {
  useDispatch,
  useSelector,
  useStore,
  TypedUseSelectorHook
} from 'react-redux'

import { RootState } from 'shared/types/store'

import type { AppDispatch, AppStore } from './configureStore'

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppStore: () => AppStore = useStore
