import React, { FC, memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Config, selectConfig } from 'astra-core/containers/ConfigProvider'
import { VirtualGameVendor } from 'betweb-openapi-axios'
import { getCurrentLng } from 'astra-core/utils'
import { useBetGamesData } from 'og-core/providers/virtual-games/hooks'
import { Maybe } from 'astra-core/typings'

import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { ServiceAvailability } from 'shared/ui/ServiceAvailability'

import { StyledLoaderPointsWrapper } from './BetGames.styled'

const BET_GAMES_DOM_NODE = 'betgames-iframe-content'

const removeBetGames = (betGamesScript) => {
  document.head.removeChild(betGamesScript)
  document.getElementById(BET_GAMES_DOM_NODE)?.firstElementChild?.remove() // To remove the iFrame added by BetGames script
}

const loadBetGames = (config: Config, token: Maybe<string>) => {
  const clientUrl = config.BETGAMES_CLIENT_URL
  const script = document.createElement('script')

  script.onload = () => {
    window.BetGames.setup({
      containerId: BET_GAMES_DOM_NODE,
      clientUrl,
      apiUrl: config.BETGAMES_SERVER,
      partnerCode: config.BETGAMES_PARTNER_CODE,
      partnerToken: token || '-',
      language: getCurrentLng(),
      timezone: new Date().getTimezoneOffset() / 60
    })
  }

  script.type = 'text/javascript'
  script.src = `${clientUrl}/public/betgames.js?${new Date()}`

  document.head.appendChild(script)

  return script
}

export const BetGames: FC = () => {
  const { loaded, token } = useBetGamesData()

  if (!loaded) {
    return (
      <StyledLoaderPointsWrapper>
        <LoaderPoints />
      </StyledLoaderPointsWrapper>
    )
  }

  return (
    <ServiceAvailability vendor={VirtualGameVendor.BetGames}>
      <BetGamesScript token={token} />
    </ServiceAvailability>
  )
}

const BetGamesScript = memo(({ token }: { token: Maybe<string> }) => {
  const config = useSelector(selectConfig)

  useEffect(() => {
    const betGamesScript = loadBetGames(config, token)
    return () => {
      removeBetGames(betGamesScript)
    }
  }, [token, config])

  return <div id={BET_GAMES_DOM_NODE} />
})
