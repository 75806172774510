import { ELoyaltyStatus } from 'astra-core/containers/AccountProvider'

export const getIsActiveTab = (
  tab: ELoyaltyStatus,
  currentTab: ELoyaltyStatus
) => tab === currentTab

export const BONUS_COUPONS_BODY_TABS_LIST = (loyaltyLengthByStatus) => [
  {
    name: ELoyaltyStatus.ALL,
    locale: 'all',
    amount: loyaltyLengthByStatus[ELoyaltyStatus.ALL]
  },
  {
    name: ELoyaltyStatus.ACTIVE,
    locale: 'active',
    amount: loyaltyLengthByStatus[ELoyaltyStatus.ACTIVE]
  },
  {
    name: ELoyaltyStatus.ARCHIVE,
    locale: 'archive',
    amount: loyaltyLengthByStatus[ELoyaltyStatus.ARCHIVE]
  }
]
