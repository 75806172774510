import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { ETabTypes } from 'astra-core/containers/BalanceProvider'

import { ERoutes } from 'shared/types/routes'
import { PAYMENT_ID_IMG } from 'pages/page-balance/Balance/constants'

import {
  StyledMenuItemWrapper,
  StyledMenuItemAdditionalText,
  StyledMenuItemDesc,
  StyledMenuItemImg,
  StyledMenuItemTitle
} from './MenuItem.styled'
import { IMenuItemProps } from './MenuItem.types'

const getTransferTimeInfo = (pathname: string) =>
  pathname === `${ERoutes.ProfileBalance}${ETabTypes.Withdraw}`
    ? 'transfer time'
    : 'instant transfer'

export const MenuItem: FC<IMenuItemProps> = ({
  method,
  selectedId,
  testData,
  onChange
}) => {
  const { id, name, fee } = method
  const [t] = useTranslation()
  const { pathname } = useLocation()

  return (
    <StyledMenuItemWrapper
      active={id === selectedId}
      data-test-id={testData}
      key={id}
      onClick={() => onChange(id)}
    >
      <StyledMenuItemImg>
        <img alt="" src={PAYMENT_ID_IMG[id] ?? PAYMENT_ID_IMG.card} />
      </StyledMenuItemImg>
      <StyledMenuItemDesc>
        <StyledMenuItemTitle>{name}</StyledMenuItemTitle>
        <StyledMenuItemAdditionalText>
          {t('commission')} {fee.toFixed(2)}%
        </StyledMenuItemAdditionalText>
        <StyledMenuItemAdditionalText>
          {t(getTransferTimeInfo(pathname))}
        </StyledMenuItemAdditionalText>
      </StyledMenuItemDesc>
    </StyledMenuItemWrapper>
  )
}
