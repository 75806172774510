import React from 'react'

export const IconVsportOrange = () => {
  return (
    <svg
      fill="none"
      height="62"
      viewBox="0 0 176 62"
      width="176"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0V62H176V23.808C176 23.808 142.096 23.808 94.7309 18.352C47.7778 12.9435 0 0 0 0Z"
        fill="url(#paint0_linear_3325_97877)"
      />
      <path
        d="M0 0V62H176V23.808C176 23.808 142.096 23.808 94.7309 18.352C47.7778 12.9435 0 0 0 0Z"
        fill="url(#paint1_radial_3325_97877)"
        fillOpacity="0.2"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3325_97877"
          x1="0"
          x2="176"
          y1="62"
          y2="62"
        >
          <stop stopColor="#D34C00" />
          <stop offset="1" stopColor="#FF9F3F" />
        </linearGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(105.5 -16) rotate(105.399) scale(122.394 429.068)"
          gradientUnits="userSpaceOnUse"
          id="paint1_radial_3325_97877"
          r="1"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.487372" stopColor="white" stopOpacity="0" />
          <stop offset="0.487438" stopColor="white" stopOpacity="0.2" />
          <stop offset="0.487475" stopColor="white" stopOpacity="0.5" />
        </radialGradient>
      </defs>
    </svg>
  )
}
