export enum StatisticsMeasureItems {
  Corners = 'corners',
  YellowCards = 'yellowCards',
  ShotsOnTarget = 'shotsOnTarget',
  FreeKicks = 'freeKicks'
}

export type StatisticsItems<CompetitorStatistics> = {
  field: keyof CompetitorStatistics
  title: string
}[]
