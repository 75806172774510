import * as Sentry from '@sentry/react'
import { ENV, getEnv } from 'astra-core'

export const initSentryClient = () => {
  if (process.env.NODE_ENV === 'production' && getEnv() === ENV.PROD) {
    const sentryProfilingEnabled =
      window.__PRELOADED_STATE__.configProvider.config.FEATURE_FLAGS
        .SENTRY_PROFILING

    Sentry.init({
      dsn: window.__PRELOADED_STATE__?.configProvider?.config?.SENTRY_DSN,
      environment: ENV.PROD,
      integrations: sentryProfilingEnabled
        ? [Sentry.browserTracingIntegration()]
        : [],
      tracesSampleRate: sentryProfilingEnabled ? 1.0 : 0, // Set sample rate for capturing performance (100% in this case)
      beforeSend(event) {
        event.tags = event.tags || {}
        event.tags.source = 'client' // Tagging the error as originating from the server
        return event
      }
    })
  }
}
