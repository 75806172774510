import styled, { css } from 'styled-components'

import { DataListWithReduxPagination } from 'shared/ui/DataList'

export const StyledTable = styled.table`
  width: 100%;
`

export const StyledTableWrapper = styled(DataListWithReduxPagination)<{
  isData: boolean
}>`
  ${(props) => css`
    overflow-y: scroll;

    ${!props.isData &&
    css`
      align-items: center;
      display: flex;
      justify-content: center;
    `}
  `}
`

export const StyledTableBody = styled.tbody``
