import React, { FC, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import range from 'lodash/range'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { getColor } from 'astra-core/utils/sportColors'
import { useTheme } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'

import { getMainPageEventsConfiguration } from '../MainPageEvents.constants'
import { MainPageEventsContext } from '../MainPageEvents.context'

import { SportsItemProps, SportsListProps } from './SportsList.types'
import {
  StyledSportsListItem,
  StyledSportsListItemIcon,
  StyledSportsListItemLabel,
  StyledSportsListItemSkeleton,
  StyledSportsWrapper
} from './SportsList.styled'

export const SportsList: FC<SportsListProps> = () => {
  const { mainPageEventsListType } = useContext(MainPageEventsContext)

  const isEntityTagsEnabled = useFeatureFlag(EFeatureFlags.ENTITY_TAGS_ENABLED)

  const config = getMainPageEventsConfiguration(isEntityTagsEnabled)

  const { selectSports, selectCurrentSport } = config[mainPageEventsListType]

  const currentSport = useSelector(selectCurrentSport)

  const sports = useSelector(selectSports)

  return (
    <StyledSportsWrapper>
      {sports.map((sport) => (
        <SportItem
          isActive={sport.id === currentSport}
          key={sport.id}
          sport={sport}
        />
      ))}
    </StyledSportsWrapper>
  )
}

export const SportsListSkeleton: FC = () => (
  <StyledSportsWrapper>
    {range(8).map((item) => (
      <StyledSportsListItemSkeleton key={item} />
    ))}
  </StyledSportsWrapper>
)

const SportItem: FC<SportsItemProps> = ({ sport, isActive }) => {
  const dispatch = useDispatch()
  const { mainPageEventsListType, scrollToIndex } = useContext(
    MainPageEventsContext
  )
  const typeTheme = useSelector(selectUserSettingLayoutTheme)
  const theme = useTheme()

  const isEntityTagsEnabled = useFeatureFlag(EFeatureFlags.ENTITY_TAGS_ENABLED)

  const config = getMainPageEventsConfiguration(isEntityTagsEnabled)

  const { setCurrentSport } = config[mainPageEventsListType]

  const handleSportItemClick = useCallback(() => {
    dispatch(setCurrentSport({ sportId: sport.id }))
    scrollToIndex()
  }, [dispatch, setCurrentSport, scrollToIndex, sport.id])

  const bgColor =
    typeTheme === LayoutTheme.Dark
      ? theme.colors.fixed.primary[80]
      : getColor(sport.code as ESportsCodes, typeTheme).background

  return (
    <StyledSportsListItem
      bgColor={bgColor}
      isActive={isActive}
      onClick={handleSportItemClick}
    >
      <StyledSportsListItemIcon
        isActive={isActive}
        size={16}
        sportCode={sport.code as ESportsCodes}
      />
      <StyledSportsListItemLabel>{sport.name}</StyledSportsListItemLabel>
    </StyledSportsListItem>
  )
}
