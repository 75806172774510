import styled, { css } from 'styled-components'

import { StyledInputWrapper } from 'shared/ui/Input'

import { SavedCardItemProps } from './CardInput.types'

export const StyledCardInputCardCVC = styled.div`
  width: 88px;
  ${StyledInputWrapper} {
    width: 66px;
  }
`

export const StyledImg = styled.img`
  height: 25px;
`

export const StyledImgMir = styled.img`
  height: 12.53px;
`

export const StyledSavedCards = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 462px;
`

export const StyledSavedCardsTitle = styled.div`
  line-height: 20px;
  padding-bottom: 8px;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.m};
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.custom.primary[39]};
    `}
`

export const StyledSavedCardsItem = styled.div<SavedCardItemProps>`
  align-items: center;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.default.primary[5] : theme.colors.default.primary[0]};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  grid-column-gap: 9px;
  height: 32px;
  padding: 6px 16px 6px 17px;
`

export const StyledSavedCardsItemLabel = styled.div<SavedCardItemProps>`
  color: ${({ active, theme }) =>
    active
      ? theme.colors.default.primary[90]
      : theme.colors.default.primary[30]};
  flex: 1;
  font-size: ${({ theme }) => theme.fonts.size.s};
  text-transform: capitalize;
`

export const StyledSavedCardsItemValue = styled.div`
  line-height: 20px;

  ${({ theme }) => `
    color: ${theme.colors.default.primary[90]};
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.normal};
  `};
`

export const StyledSavedCardsItemMask = styled.span`
  margin-right: 4px;

  ${({ theme }) => `
    color: ${theme.colors.custom.primary[57]};
  `};
`

export const StyledSaveCardCheckbox = styled.div`
  margin-bottom: 32px;
`

export const StyledNoSavedCards = styled.div`
  line-height: 16px;
  margin-bottom: 20px;

  ${({ theme }) => css`
    color: ${theme.colors.default.primary[70]};
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
  `};
`
