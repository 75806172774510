import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  betsHistoryProviderActions,
  selectBetsHistoryCollapsedBets
} from 'astra-core/containers/BetsHistoryProvider'
import { isEqualDepth } from 'astra-core/utils'

import { ETestData } from 'shared/lib/testData'
import { useAppSelector } from 'app/lib/store'

import { StyledFilterTextButton } from '../../Filter.styled'

export const ButtonToggleCollapse = () => {
  const { isCollapsedAll } = useAppSelector(
    selectBetsHistoryCollapsedBets,
    isEqualDepth
  )
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const handleCollapse = useCallback(
    () => dispatch(betsHistoryProviderActions.toggleCollapseAllBets()),
    [dispatch]
  )

  const buttonToggleCollapseText = useMemo(
    () => t(isCollapsedAll ? 'expand all' : 'collapse all'),
    [isCollapsedAll, t]
  )

  return (
    <StyledFilterTextButton
      data-test-id={ETestData.TestBetsHistoryFilterTextButton}
      onClick={handleCollapse}
    >
      {buttonToggleCollapseText}
    </StyledFilterTextButton>
  )
}
