import { useField } from 'formik'
import React, { FC, memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { getCreateTestData, ETestData } from 'shared/lib/testData'

import {
  StyledCustomInputSubtext,
  StyledInput,
  StyledInputWrapper,
  StyledLabel
} from './Input.styled'
import { ICustomInput, IInputFormik } from './Input.types'

const CustomInput: FC<ICustomInput> = ({ onBlur, testData, ...props }) => {
  const [t] = useTranslation()
  const [field, meta, helpers] = useField(props.name || '')
  const { error, touched } = meta

  const errorMessage = useMemo(
    () => (error && touched ? error : ''),
    [error, touched]
  )

  const handleBlur = useCallback(
    (e) => {
      helpers.setValue(e.target.value.trim())
      field.onBlur(e)
      onBlur?.(e)
    },
    [field, onBlur, helpers]
  )

  return (
    <>
      <StyledInput
        {...props}
        $isError={!!errorMessage}
        data-test-id={testData}
        onBlur={handleBlur}
      />

      {!!errorMessage && (
        <StyledCustomInputSubtext
          data-test-id={getCreateTestData(
            ETestData.TestErrorMessage,
            errorMessage
          )}
        >
          {t(errorMessage)}
        </StyledCustomInputSubtext>
      )}
    </>
  )
}

export const InputFormik: FC<IInputFormik> = memo(
  ({ className, label, testData, ...props }) => {
    const [t] = useTranslation()

    const componentProps = {
      ...props,
      ...(props.placeholder && { placeholder: t(props.placeholder) })
    }

    return label ? (
      <StyledInputWrapper className={className}>
        <StyledLabel htmlFor={props.name}>{t(label)}</StyledLabel>
        <CustomInput testData={testData} {...componentProps} />
      </StyledInputWrapper>
    ) : (
      <CustomInput {...componentProps} />
    )
  }
)
