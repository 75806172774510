import isEmpty from 'lodash/isEmpty'
import { createSelector } from '@reduxjs/toolkit'
import {
  DEFAULT_PAGE_SIZE,
  getEvents
} from 'astra-core/containers/EventsProvider'
import { selectInputSearchLine } from 'astra-core/containers/SearchProvider'
import { getEventsBySearch } from 'astra-core/utils'
import isEqual from 'lodash/isEqual'

import { RootState } from 'shared/types/store'
import { getValues } from 'shared/lib/@reduxjs'

import { initialState } from './slice'

const EMPTY_ARRAY = []

const selectEventsIds = (state: RootState) =>
  state.liveContainer.eventsIdsBySport || initialState.eventsIdsBySport

export const getLiveEventsIdsBySport = (state: RootState, sportId: number) =>
  selectEventsIds(state)[sportId] || EMPTY_ARRAY

export const getLiveFetchItems = (state: RootState) =>
  state.liveContainer.fetchItems || initialState.fetchItems

export const getLiveFilters = (state: RootState) =>
  state.liveContainer.filters ?? initialState.filters

export const getFilterIsTop = (state: RootState) =>
  state.liveContainer.filters.isTop ?? initialState.filters.isTop

export const getLayoutIsLoading = (state: RootState) =>
  state.liveContainer.layouts.isLoading ?? initialState.layouts.isLoading

export const getPaginationIsLoading = (state: RootState) =>
  state.liveContainer.layouts.paginationLoading ??
  initialState.layouts.paginationLoading

export const selectLiveEvents = createSelector(
  [getLiveEventsIdsBySport, getEvents],
  (eventsIdsBySport, events) => getValues(eventsIdsBySport, events)
)

export const selectLiveEventsBySearch = createSelector(
  [selectLiveEvents, selectInputSearchLine],
  (liveEvents, searchText) =>
    getEventsBySearch({
      events: liveEvents,
      searchText
    })
)

export const selectLiveIsEmpty = createSelector([getEvents], (events) =>
  isEmpty(events)
)

export const selectLiveFetchItems = createSelector(
  [getLiveFetchItems],
  (liveFetchItems) => liveFetchItems
)

export const selectFilterIsTop = createSelector(
  [getFilterIsTop],
  (isTop) => isTop
)

export const selectLayoutIsLoading = createSelector(
  [getLayoutIsLoading],
  (isLoading) => isLoading
)

export const selectIsLiveFilterUsed = createSelector(
  [getLiveFilters],
  (filters) => !isEqual(filters, initialState.filters)
)

export const selectLiveEventsPaginationHasMore = (
  state: RootState,
  sportId: number
) => !!state.liveContainer?.pageKeyBySport[sportId] ?? false

export const selectLiveEventsPaginationKey = (
  state: RootState,
  sportId: number
) => state.liveContainer?.pageKeyBySport[sportId]

export const selectLiveEventsPageSize = (state: RootState, sportId: number) =>
  state.liveContainer?.pageSizeBySport[sportId] ?? DEFAULT_PAGE_SIZE
