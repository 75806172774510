import { BetsStatisticsList, BetType, Currency } from 'betweb-openapi-axios'
import sumBy from 'lodash/sumBy'
import keyBy from 'lodash/keyBy'
import { Dictionary } from 'astra-core/typings'

export enum BETS_STATISTICS_EXTRA_KEYS {
  'TOTAL' = 'total'
}

type BETS_STATISTICS_KEYS = BETS_STATISTICS_EXTRA_KEYS | BetType

export type BetsStatisticsEntryType = {
  amount: number
  count: number
  key: BETS_STATISTICS_KEYS
}

type AdjustBetsStatisticsListResultType = {
  betsStatisticsEntries: Dictionary<BetsStatisticsEntryType>
  currencyIcon: string
}

export const adjustBetsStatisticsList = (
  betsStatisticsList: BetsStatisticsList,
  currencies: Dictionary<Currency>
): AdjustBetsStatisticsListResultType => {
  // eslint-disable-next-line camelcase
  const { items: betsStatisticsItems, currency_id } = betsStatisticsList
  const totalAmount = sumBy(betsStatisticsItems, 'amount')
  const totalCount = sumBy(betsStatisticsItems, 'count')
  const betsStatisticsTotalItem = {
    amount: totalAmount,
    count: totalCount,
    key: BETS_STATISTICS_EXTRA_KEYS.TOTAL
  }

  return {
    betsStatisticsEntries: keyBy(
      [
        betsStatisticsTotalItem,
        ...betsStatisticsItems.map(({ amount, count, betType }) => ({
          amount,
          count,
          key: betType
        }))
      ],
      'key'
    ),
    // eslint-disable-next-line camelcase
    currencyIcon: currencies[currency_id]?.symbol || ''
  }
}
