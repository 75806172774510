import React, { FC, useMemo } from 'react'
import { OddsTablePropsNew } from 'astrabet-templates-kit'

import {
  StyledOutcomesTable,
  StyledTableWrapper,
  StyledTablePlainRow,
  StyledPlainWrapper
} from './OutcomesTable.styled'
import { OddsDivider } from './OddsDivider'
import { Label } from './Label'
import { formatTitles } from './utils/formatTitles'
import { OutcomesTableCell } from './OutcomesTableCell'
import { getCellKey, getRowKey } from './utils/getKey'

export const OutcomesTable: FC<OddsTablePropsNew> = ({
  rows,
  columns,
  title,
  withoutDivider,
  withoutTitle,
  probabilities,
  isPlain,
  isLast,
  competitors,
  renderOdd,
  categoryMarket,
  labelInButton,
  ...props
}) => {
  const formattedTitles = useMemo(
    () => formatTitles(title, probabilities, categoryMarket, competitors),
    [title, probabilities, competitors, categoryMarket]
  )

  const clearHeaderCell = useMemo(() => {
    if (formattedTitles.flat().length === 1) {
      return formattedTitles.flat()[0] !== ''
    }

    return !withoutTitle && !!formattedTitles.length
  }, [formattedTitles, withoutTitle])

  if (!rows.length) {
    return null
  }

  return (
    <>
      {!withoutDivider && <OddsDivider />}
      <StyledTableWrapper>
        <Label
          clearHeaderCell={clearHeaderCell}
          columns={columns}
          isPlain={isPlain}
          labelInButton={labelInButton}
          titles={formattedTitles}
        />
        <StyledOutcomesTable
          columns={columns}
          isPlain={isPlain}
          labelInButton={labelInButton}
        >
          <PlainWrapper isPlain={isPlain}>
            {rows.map((row, rowIndex) => {
              const rowKey = getRowKey(row, rowIndex)
              const last = isLast && rowIndex === rows.length - 1

              return isPlain ? (
                <StyledTablePlainRow key={rowKey}>
                  {columns.map((column, columnIndex) => {
                    if (column === 'label') {
                      return null
                    }

                    const cellValue = row[columnIndex]
                    const keyColumn = getCellKey(cellValue, columnIndex)
                    const cellKey = `${rowKey}-${keyColumn || columnIndex}`

                    return (
                      <OutcomesTableCell
                        categoryMarket={categoryMarket}
                        data={cellValue}
                        hideBorder={last}
                        isOdd={column === 'odd'}
                        key={cellKey}
                        labelInButton={labelInButton}
                        renderOdd={renderOdd}
                        isPlain
                        {...props}
                      />
                    )
                  })}
                </StyledTablePlainRow>
              ) : (
                <React.Fragment key={rowKey}>
                  {columns.map((column, columnIndex) => {
                    if (column === 'label' && labelInButton) {
                      return null
                    }

                    const cellValue = row[columnIndex]
                    const keyColumn = getCellKey(cellValue, columnIndex)
                    const cellKey = `${rowKey}-${keyColumn || columnIndex}`

                    return (
                      <OutcomesTableCell
                        categoryMarket={categoryMarket}
                        data={cellValue}
                        hideBorder={last && !clearHeaderCell}
                        hideBorderBottom={last}
                        isOdd={column === 'odd'}
                        key={cellKey}
                        labelInButton={labelInButton}
                        renderOdd={renderOdd}
                        {...props}
                      />
                    )
                  })}
                </React.Fragment>
              )
            })}
          </PlainWrapper>
        </StyledOutcomesTable>
      </StyledTableWrapper>
    </>
  )
}

const PlainWrapper: FC<{ isPlain?: boolean }> = ({
  children,
  isPlain = false
}) => {
  return isPlain ? (
    <StyledPlainWrapper>{children}</StyledPlainWrapper>
  ) : (
    <>{children}</>
  )
}
