import React, { FC } from 'react'
import { SportTournamentsGroup } from 'astra-core/hooks/useEvents/types'
import { selectSport } from 'astra-core/containers/CommonDataProvider/selectors'

import SportHeader from 'shared/ui/SportHeader/SportHeader'
import { useAppSelector } from 'app/lib/store'

import { TableBets } from '../ui/TableBets/TableBets'

import { StyledSportGroup } from './SportsGroup.styled'

type ISportGroupComponent = {
  sportGroup: SportTournamentsGroup
  showSportHeader: boolean
}
const SportGroup: FC<ISportGroupComponent> = ({
  sportGroup,
  showSportHeader = false
}) => {
  const sport = useAppSelector((s) => selectSport(s, sportGroup.sportId))
  return (
    <StyledSportGroup>
      {showSportHeader && <SportHeader sport={sport} />}
      <TableBets eventsGroups={sportGroup.eventGroups} isLive />
    </StyledSportGroup>
  )
}

export default SportGroup
