import React from 'react'
import { useTranslation } from 'react-i18next'
import { VirtualGameVendor } from 'betweb-openapi-axios'

import { ERoutes } from 'shared/types/routes'
import { ELayoutsList, Layout } from 'widgets/Layout'
import { IconBetradarLogo } from 'shared/ui/Icon/General/IconBetradarLogo'
import { ServiceAvailability } from 'shared/ui/ServiceAvailability'

import { VSPORT_GAMES_LIST, VSPORT_GAMES_TITLE } from './constants'
import {
  GridStyled,
  PageTitleStyled,
  CardStyles,
  ContentStyled,
  CardTitleStyles,
  StyledCardBackground,
  StyledCardIcon,
  StyledCardDescription
} from './page.styled'

export const PageGamesVsport = () => {
  const [t] = useTranslation()

  return (
    <Layout type={ELayoutsList.GAME}>
      <ContentStyled>
        <ServiceAvailability vendor={VirtualGameVendor.BetRadarVirtualSports}>
          <GridStyled>
            <PageTitleStyled>{VSPORT_GAMES_TITLE}</PageTitleStyled>
            {VSPORT_GAMES_LIST.map((game) => (
              <CardStyles
                key={game.id}
                to={`${ERoutes.VsportGames}/${game.id}`}
              >
                <StyledCardBackground src={game.backgroundSrc} />
                <StyledCardIcon>{game.iconCard}</StyledCardIcon>
                <StyledCardDescription>
                  <IconBetradarLogo size={{ width: 60, height: 8 }} />
                  <CardTitleStyles>{t(`${game.title}`)}</CardTitleStyles>
                </StyledCardDescription>
              </CardStyles>
            ))}
          </GridStyled>
        </ServiceAvailability>
      </ContentStyled>
    </Layout>
  )
}
