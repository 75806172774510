import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { BetGames } from './BetGames'

export const PageGamesBet = () => {
  return (
    <Layout type={ELayoutsList.GAME}>
      <BetGames />
    </Layout>
  )
}
