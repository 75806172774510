import React, { useCallback } from 'react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { selectStepOneData } from 'og-core/providers/verification/selectors'
import { verificationActions } from 'og-core/providers/verification/slice'
import { EVerificationSteps } from 'og-core/providers/verification/types'
import { useTranslation } from 'react-i18next'

import { ETestData } from 'shared/lib/testData'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'

import {
  StyledFullRegistrationButton,
  StyledSlideControl
} from '../FullRegistration.styled'

import {
  INPUT_DATA_LIST_STEP_ONE,
  SLIDE_ONE_FORMIK_SCHEMA
} from './constants/constants'
import {
  StyledFullRegistrationHeaderExit,
  StyledFullRegistrationHeaderTitle,
  StyledFullRegistrationHeaderWrapper,
  StyledFullRegistrationWrapper,
  StyledInput,
  StyledInputWrapper,
  StyledUserDataTitle
} from './FullRegistrationStepOne.styled'

export const FullRegistrationStepOne = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const authenticationDataStepOne = useSelector(selectStepOneData)

  const closeAuthenticationModal = useCallback(() => {
    dispatch(
      verificationActions.changeCurrentVerificationStep({
        step: null
      })
    )
  }, [dispatch])

  const changeAuthenticationCurrentStep = useCallback(() => {
    dispatch(
      verificationActions.changeCurrentVerificationStep({
        step: EVerificationSteps.TWO
      })
    )
  }, [dispatch])

  const handleFormikSubmit = useCallback(
    (data) => {
      dispatch(verificationActions.verificationStepOne(data))
    },
    [dispatch]
  )

  return (
    <StyledFullRegistrationWrapper>
      <StyledFullRegistrationHeaderWrapper>
        <StyledFullRegistrationHeaderTitle>
          {t('verification')}
        </StyledFullRegistrationHeaderTitle>
        <StyledFullRegistrationHeaderExit
          colorProps={{
            name: EColorsNames.Primary,
            value: 63,
            type: EColorsTypes.CUSTOM
          }}
          size={10}
          onClick={closeAuthenticationModal}
        />
      </StyledFullRegistrationHeaderWrapper>

      <StyledUserDataTitle>{t('user data')}</StyledUserDataTitle>
      <Formik
        initialValues={authenticationDataStepOne}
        validationSchema={SLIDE_ONE_FORMIK_SCHEMA}
        validateOnMount
        onSubmit={handleFormikSubmit}
      >
        {({ isValid }) => (
          <Form>
            {INPUT_DATA_LIST_STEP_ONE.map((itemProps) => (
              <StyledInputWrapper key={itemProps.name}>
                <StyledInput
                  testData={`${ETestData.TestVerificationFormInput}-${itemProps.name}`}
                  {...itemProps}
                />
              </StyledInputWrapper>
            ))}

            <StyledSlideControl
              activeItem={0}
              disabled={!isValid}
              pointCount={2}
              onChange={changeAuthenticationCurrentStep}
            />
            <StyledFullRegistrationButton
              disabled={!isValid}
              testData={ETestData.TestVerificationFormContinue}
              type="submit"
            >
              {t('continue')}
            </StyledFullRegistrationButton>
          </Form>
        )}
      </Formik>
    </StyledFullRegistrationWrapper>
  )
}
