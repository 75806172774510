import styled from 'styled-components'

export const StyledEventsTableWrapper = styled.div`
  display: grid;
`
export const StyledSearchEventsWrapper = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.custom.primary[2]};
  display: flex;
  margin-bottom: 4px;
  padding: 0 16px;
  width: 100%;
`

export const StyledSearchEventsName = styled.div`
  line-height: 20px;
  margin-left: 8px;
  margin-right: auto;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.l};
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.default.primary[90]};
    `}
`

export const StyledSearchTableBetsHead = styled.div`
  div:first-child {
    background: ${(props) => props.theme.colors.default.primary[5]};
  }
`

export const StyledSearchEventsGroups = styled.div`
  margin: 8px 0 0;
`
