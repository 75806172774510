import React from 'react'
// eslint-disable-next-line react/no-deprecated
import { hydrateRoot } from 'react-dom/client'
// TODO: Switch to hydrate func fo React v18
import { loadableReady } from '@loadable/component'
import './app/lib/i18n'
import axios from 'axios'
import ApiBuilder from 'astra-core/config/ApiBuilder'
import CoreConfiguration from 'astra-core/config/CoreConfiguration'
import { addLogoutInterceptor } from 'og-web-core/shared/axios/interceptors'
import { addCaptchaInterceptor } from 'og-web-core/shared/axios/addCaptchaInterceptor'

import { configureAppStore } from 'app/lib/store'
import { virtualGamesRoutes } from 'pages/model'

import { initSentryClient } from './shared/lib/sentry/client'

/*
Redux Store Init
Statically injected reducers are pre-filled with initial data here,
while initial states of dynamically injected reducers are pre-filled
on behalf of each particular slice.
 */
const store = configureAppStore({
  // @ts-ignore
  preloadedState: window.__PRELOADED_STATE__
})

const axiosInstance = axios.create()
// @ts-ignore
addLogoutInterceptor(axiosInstance, store, virtualGamesRoutes)
// @ts-ignore
addCaptchaInterceptor(axiosInstance, store)

// Init core configuration
CoreConfiguration.init({
  apiBuilder: ApiBuilder.create(axiosInstance)
})

// Init Sentry Client
initSentryClient()

// Log APP_VERSION
const appVersion = window.__PRELOADED_STATE__.configProvider.config.APP_VERSION
if (appVersion) {
  console.log('APP_VERSION', appVersion)
}

// Load all components needed before rendering
loadableReady().then(() => {
  import('AppContainer').then(({ AppContainer }) => {
    hydrateRoot(document.getElementById('root'), <AppContainer store={store} />)
  })
})

if (module.hot) {
  module.hot.accept()
}
