import styled from 'styled-components'

export const StyledStatisticsWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.custom.primary[2]};
  height: 100%;
  position: relative;
`

export const StyledLoadingIndicatorWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.custom.primary[2]};
  inset: 0;
  position: absolute;
`
