import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider/slice'
import { selectSport } from 'astra-core/containers/CommonDataProvider/selectors'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider/types'
import { selectEventResultEnriched } from 'astra-core/containers/ResultsProvider/selectors'
import { resultsProviderActions } from 'astra-core/containers/ResultsProvider/slice'
import { Event } from 'betweb-openapi-axios'

import { RootState } from 'shared/types/store/root-state'

import { StyledEventCompletedWrapper } from './EventCompleted.styled'
import { StatsCompleted } from './components/EventCompletedStat/EventCompletedStats'
import { EventBannerCompleted } from './components/EventBannerCompleted/EventBannerCompleted'

export const EventCompleted: FC<{ event: Event }> = ({ event }) => {
  const dispatch = useDispatch()

  const eventResult = useSelector((state: RootState) =>
    selectEventResultEnriched(state, event.id)
  )

  useEffect(() => {
    dispatch(resultsProviderActions.fetchEventResults({ eventId: event.id }))
  }, [dispatch, event.id])

  const sportData = useSelector((state: RootState) =>
    selectSport(state, eventResult?.tournament.sportId)
  )

  useEffect(() => {
    if (sportData?.code === ESportsCodes.FOOTBALL) {
      dispatch(
        eventsProviderActions.fetchEventStatistics({ eventId: eventResult.id })
      )
    }
  }, [dispatch, sportData?.code, eventResult?.id])

  if (!eventResult) return null

  return (
    <StyledEventCompletedWrapper>
      <EventBannerCompleted eventResult={eventResult} sportData={sportData} />
      <StatsCompleted event={event} sportCode={sportData.code} />
    </StyledEventCompletedWrapper>
  )
}
