import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { verificationActions } from 'og-core/providers/verification/slice'

import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { IconWarning2 } from 'shared/ui/Icon/General/IconWarning2'
import { EButtonViews } from 'shared/ui/Button'

import {
  StyledFullRegistrationHeaderExit,
  StyledFullRegistrationHeaderWrapper,
  StyledFullRegistrationWrapper,
  StyledText,
  StyledTextErrorButton,
  StyledTextErrorDescription,
  StyledTextErrorTitle
} from './FullRegistrationStepThree.styled'

export const FullRegistrationStepThreeError = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const closeAuthenticationModal = useCallback(() => {
    dispatch(
      verificationActions.changeCurrentVerificationStep({
        step: null
      })
    )
  }, [dispatch])

  const handleSupport = () => {
    window.LC_API.open_chat_window()
  }

  return (
    <StyledFullRegistrationWrapper>
      <StyledFullRegistrationHeaderWrapper>
        <StyledFullRegistrationHeaderExit
          colorProps={{
            name: EColorsNames.Primary,
            value: 63,
            type: EColorsTypes.CUSTOM
          }}
          size={10}
          onClick={closeAuthenticationModal}
        />
      </StyledFullRegistrationHeaderWrapper>
      <StyledText>
        <IconWarning2
          colorProps={{
            name: EColorsNames.Yellow,
            value: 10,
            type: EColorsTypes.CUSTOM
          }}
          size={32}
        />
        <StyledTextErrorTitle>
          {t('verification required')}
        </StyledTextErrorTitle>
        <StyledTextErrorDescription>
          {t('further confirmation')}
        </StyledTextErrorDescription>
        <StyledTextErrorButton
          view={EButtonViews.SECONDARY}
          onClick={handleSupport}
        >
          {t('contact tech support')}
        </StyledTextErrorButton>
      </StyledText>
    </StyledFullRegistrationWrapper>
  )
}
