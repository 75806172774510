import React, {
  forwardRef,
  memo,
  PropsWithChildren,
  useCallback,
  useImperativeHandle,
  useState
} from 'react'
import isEqual from 'react-fast-compare'

import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'

import { PanelHandle, PanelProps } from './Category.types'
import {
  StyledPanel,
  StyledPanelHead,
  StyledPanelTitle
} from './Category.styled'

export const CategoryPanel = memo(
  forwardRef<PanelHandle, PropsWithChildren<PanelProps>>(
    ({ title = '', children }, forwardedRef) => {
      const [opened, setOpened] = useState<boolean>(true)

      const toggleOpened = useCallback(() => setOpened((opened) => !opened), [])
      const open = useCallback(() => setOpened(true), [])
      const close = useCallback(() => setOpened(false), [])

      useImperativeHandle(forwardedRef, () => ({ open, close }), [close, open])

      return (
        <StyledPanel opened={opened}>
          <StyledPanelHead onClick={toggleOpened}>
            <StyledPanelTitle
              dangerouslySetInnerHTML={{
                __html: title
              }}
            />
            <IconChevronDown
              colorProps={{
                name: EColorsNames.Primary,
                value: 50,
                type: EColorsTypes.FIXED
              }}
              size={8}
              twist={opened ? 180 : 0}
            />
          </StyledPanelHead>
          {opened && children}
        </StyledPanel>
      )
    }
  ),
  isEqual
)

CategoryPanel.displayName = 'CategoryPanel'
