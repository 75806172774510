import styled, { css } from 'styled-components'

export const StyledSportHeader = styled.div`
  align-items: center;
  column-gap: 8px;
  display: flex;
  grid-area: text;
  padding: 8px;
`

export const StyledSportName = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.default.primary[90]};
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.bold};
  `}
`
