import React, { FC, memo, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { LayoutTheme, VirtualGameVendor } from 'betweb-openapi-axios'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'
import { useLiveGamesData } from 'og-core/providers/virtual-games/hooks/useLiveGamesData'
import { Maybe } from 'astra-core/typings'

import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { ServiceAvailability } from 'shared/ui/ServiceAvailability'

import { StyledLiveGames, StyledLoaderPointsWrapper } from './LiveGames.styled'

export const LiveGames: FC = () => {
  const { loaded, token } = useLiveGamesData()

  if (!loaded) {
    return (
      <StyledLoaderPointsWrapper>
        <LoaderPoints />
      </StyledLoaderPointsWrapper>
    )
  }

  return (
    <ServiceAvailability vendor={VirtualGameVendor.LiveGames}>
      <LiveGamesContainer token={token} />
    </ServiceAvailability>
  )
}

const LIVE_GAMES_CONTAINER_ID = 'magicwin_container'

const LiveGamesContainer = memo(({ token }: { token: Maybe<string> }) => {
  const { i18n } = useTranslation()
  const layoutTheme = useSelector(selectUserSettingLayoutTheme)

  const setup = useMemo(
    () => ({
      container: LIVE_GAMES_CONTAINER_ID,
      lang: i18n.languages[0] || 'en',
      runFrom: 2,
      options: {
        defaultCurrency: 'RUB',
        skin: layoutTheme === LayoutTheme.Light ? 1 : 2,
        oddCulture: 0
      },
      UserToken: token!
    }),
    [i18n.languages, layoutTheme, token]
  )

  useEffect(() => {
    window.MagicGames.addWidget(setup)
    return () => {
      window.MagicGames.Loaded = 0
    }
  }, [setup])

  return (
    <StyledLiveGames
      id={LIVE_GAMES_CONTAINER_ID}
      isDarkMode={layoutTheme === LayoutTheme.Dark}
    />
  )
})
