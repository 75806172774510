import styled from 'styled-components'

export const StyledTableCalendar = styled.div`
  padding-bottom: 35px;
`

export const StyledTableCalendarDate = styled.div`
  color: ${(props) => props.theme.colors.default.primary[50]};
  font-size: ${(props) => props.theme.fonts.size.xs};
`
