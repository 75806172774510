import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { getColor } from 'astra-core/utils/sportColors'

import { SportIcon } from 'shared/ui/Icon/SportIcon'
import { Marquee } from 'shared/ui/Marquee'

import { GenericSportFilterItemProps } from './GenericSportListItem.types'
import {
  StyledGenericSportListItem,
  StyledGenericSportListItemText
} from './GenericSportListItem.styled'

export const GenericSportListItem: FC<GenericSportFilterItemProps> = ({
  sport,
  onClickItem,
  isActive,
  customLabel
}) => {
  const typeTheme = useSelector(selectUserSettingLayoutTheme)

  const activeBackground = useMemo(() => {
    const { wrapperColor } = getColor(sport.code as ESportsCodes, typeTheme)
    return wrapperColor
  }, [sport, typeTheme])

  return (
    <StyledGenericSportListItem
      activeBackground={activeBackground}
      isActive={isActive}
      onClick={onClickItem}
    >
      <SportIcon sportCode={sport.code as ESportsCodes} isActive />
      <StyledGenericSportListItemText>
        <Marquee enableAnimation={false} id={sport.id}>
          {customLabel || sport.name}
        </Marquee>
      </StyledGenericSportListItemText>
    </StyledGenericSportListItem>
  )
}
