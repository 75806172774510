import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const ContentStyled = styled.div`
  padding: 16px 5px 0;
`
export const PageTitleStyled = styled.div`
  grid-column: 1 / -1;
  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.xxl};
      font-weight: ${theme.fonts.weight.bold};
    `}
  line-height: 32px;
`

export const GridStyled = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, 176px);
  justify-content: center;
`

export const CardStyles = styled(Link)`
  border-radius: 8px;
  display: block;
  height: 142px;
  overflow: hidden;
  position: relative;
  width: 176px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.fixed.primary[95]};
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
  }

  &:hover::after {
    opacity: 0.2;
  }
  &:active::after {
    opacity: 0;
  }
`
export const StyledCardBackground = styled.img`
  left: 0;
  object-fit: cover;
  object-position: top;
  position: absolute;
  top: 0;
  width: 100%;
`

export const StyledCardIcon = styled.div`
  bottom: -4px;
  left: 0;
  position: absolute;
`

export const StyledCardDescription = styled.div`
  bottom: 0;
  content: '';
  display: flex;
  flex-direction: column;
  gap: 6px;
  left: 0;
  padding: 0 0 9px 12px;
  position: absolute;
`

export const CardTitleStyles = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.fixed.primary[10]};
    font-size: ${theme.fonts.size.m};
  `}
`
