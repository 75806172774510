import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  balanceProviderActions,
  selectCurrentWithdrawalMethod,
  selectPaymentAccountId,
  selectPaymentAccountsById
} from 'astra-core/containers/BalanceProvider'
import { useTranslation } from 'react-i18next'
import { MethodParameterType, PaymentAccount } from 'betweb-openapi-axios'
import { useTheme } from 'styled-components'
import { findTransactionParam } from 'astra-core'

import { Radio } from 'shared/ui/Radio'
import { adjustCardNumber } from 'shared/lib/adjustCardNumber'

import { PAYMENT_IMG } from '../../constants'

import { CardInputProps } from './CardInput.types'
import {
  StyledImg,
  StyledNoSavedCards,
  StyledSavedCards,
  StyledSavedCardsItem,
  StyledSavedCardsItemLabel,
  StyledSavedCardsItemMask,
  StyledSavedCardsItemValue,
  StyledSavedCardsTitle
} from './CardInput.styled'

export const CardInput: FC<CardInputProps> = () => {
  const [t] = useTranslation()
  const paymentAccounts = useSelector(selectPaymentAccountsById)
  const currentKey = useSelector(selectPaymentAccountId)
  const currentWithdrawal = useSelector(selectCurrentWithdrawalMethod)
  const dispatch = useDispatch()
  const handleChangePaymentAccount = useCallback(
    (key: PaymentAccount['key'] | undefined) => {
      dispatch(balanceProviderActions.handleChangePaymentAccount(key))
    },
    [dispatch]
  )
  const { typeLayoutTheme } = useTheme()

  const isActiveWithdrawParam = useCallback(
    (parameterType: MethodParameterType) =>
      currentWithdrawal &&
      !!findTransactionParam(currentWithdrawal?.withdraw_params, parameterType),
    [currentWithdrawal]
  )

  if (paymentAccounts && paymentAccounts.length) {
    return (
      <StyledSavedCards>
        <StyledSavedCardsTitle>{t('saved cards')}</StyledSavedCardsTitle>
        {paymentAccounts.map((paymentAccount) => {
          const { type } = JSON.parse(paymentAccount.target)
          const currentAccountActive = paymentAccount.key === currentKey
          const paymentTitle = paymentAccount.title.length
            ? paymentAccount.title
            : adjustCardNumber(paymentAccount.key)

          return (
            <StyledSavedCardsItem
              active={currentAccountActive}
              key={paymentAccount.key}
              onClick={() => handleChangePaymentAccount(paymentAccount.key)}
            >
              <Radio checked={currentAccountActive} readOnly />
              <StyledImg alt={type} src={PAYMENT_IMG(typeLayoutTheme)[type]} />
              <StyledSavedCardsItemLabel active={currentAccountActive}>
                {type}
              </StyledSavedCardsItemLabel>
              <StyledSavedCardsItemValue>
                <StyledSavedCardsItemMask>****</StyledSavedCardsItemMask>
                {paymentTitle}
              </StyledSavedCardsItemValue>
            </StyledSavedCardsItem>
          )
        })}
        {/* todo integrate after backend improvements */}
        {/* <StyledSavedCardsItem */}
        {/*  active={!currentKey} */}
        {/* > */}
        {/*  <Radio checked={!currentKey} readOnly /> */}
        {/*  <StyledSavedCardsItemLabel active> */}
        {/*    {t('new card')} */}
        {/*  </StyledSavedCardsItemLabel> */}
        {/* </StyledSavedCardsItem> */}
      </StyledSavedCards>
    )
  } else if (isActiveWithdrawParam(MethodParameterType.AccountKey)) {
    return (
      <StyledNoSavedCards>
        {t('you do not have saved bank cards')}
      </StyledNoSavedCards>
    )
  } else {
    return null
  }
}
