import styled, { css } from 'styled-components'

import { Carousel } from 'shared/ui/Carousel'
import { Button } from 'shared/ui/Button'
import { StyledIcon } from 'shared/ui/Icon'
import { SCROLLBAR_WIDTH } from 'widgets/Layout/constants'

export const StyledCarousel = styled(Carousel)`
  gap: 4px;
  padding: 0px 16px 8px 16px;
  padding-right: calc(${SCROLLBAR_WIDTH} - 16px);
`

export const StyledGenericSportsListControl = styled(Button)`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.custom.primary[72]};
    border: 1px ${theme.colors.custom.primary[72]} solid;
    border-radius: 4px;
    display: flex;
    height: 24px;
    justify-content: center;
    padding: 4px;
    position: absolute;
    width: 24px;
    & > ${StyledIcon} {
      color: ${theme.colors.custom.primary[34]} !important;
    }

    &:hover {
      background: ${theme.colors.custom.primary[74]};
      border-color: ${theme.colors.custom.primary[45]};

      & > ${StyledIcon} {
        color: ${theme.colors.custom.primary[75]} !important;
      }
    }

    ${StyledGenericSportsList}:hover & {
      display: flex;
    }
  `}
`

export const StyledGenericSportsList = styled('div')`
  display: grid;
  position: relative;
  width: 100%;

  & ${StyledGenericSportsListControl} {
    display: none;
  }
`

export const StyledGenericSportsListControlLeft = styled(
  StyledGenericSportsListControl
)`
  left: 4px;
  top: 16px;
`

export const StyledGenericSportsListControlRight = styled(
  StyledGenericSportsListControl
)`
  right: 4px;
  top: 16px;
`
