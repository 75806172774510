import styled, { css } from 'styled-components'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider/types'

import { SPORT_CERTAIN_DATA } from 'pages/page-event/Event/components/EventBanner/components/EventBannerLargeFeatureFlag/constants'

export const StyledEventBanner = styled.div`
  background-color: ${(props) => props.theme.colors.default.primary[0]};
  padding: 16px 8px;
`

export const StyledEventBannerWrapper = styled.div<{ sportCode: ESportsCodes }>`
  background: ${(p) =>
    p.sportCode
      ? (SPORT_CERTAIN_DATA[p.sportCode] || SPORT_CERTAIN_DATA.default)
          .backgroundColor
      : 'none'};
  border-radius: 8px;

  overflow: hidden;
  padding-top: 16px;
`

export const StyledEventInfo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;
`

export const StyledEventTitle = styled.span`
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  font-size: ${(props) => props.theme.fonts.size.m};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  height: 20px;
  line-height: 20px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  width: 100%;
`

export const StyledTitle = styled.div`
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #fff 91%, rgba(255, 255, 255, 0) 100%);
  background-clip: text;
  left: 50%;
  overflow: hidden;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  white-space: nowrap;
  width: 100%;
`

export const StyledDateTimeWrapepr = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 24px;
  padding: 0 6px 0 6px;
  position: relative;
  width: fit-content;

  &:after {
    background: ${(props) => props.theme.colors.fixed.primary[0]};
    border-radius: 4px;
    content: '';
    display: flex;
    height: 24px;
    left: 0;
    opacity: 0.2;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export const StyledEventDateTime = styled.div`
  ${(props) => css`
    align-items: center;
    color: ${props.theme.colors.fixed.primary[0]};
    display: flex;
    font-size: ${props.theme.fonts.size.m};
    font-weight: ${props.theme.fonts.weight.normal};
    line-height: 20px;
    padding: 4px 8px;
  `}
`

export const StyledTeamsSection = styled.div`
  display: grid;
  gap: 16px;
  grid-template-areas: 'team1 status team2';
  grid-template-columns: 1fr auto 1fr;
  padding: 0 16px 16px 16px;
  width: 100%;
`

export const StyledNameTeamWrapper = styled.div<{ area: string }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 12px;
  grid-area: ${(props) => props.area};
  overflow: hidden;
`
export const StyledBannerCompetitor = styled.div`
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.l};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  height: 20px;
  justify-content: center;
  line-height: 20px;
  overflow: hidden;
  position: relative;
  width: 100%;
`

export const StyledCompetitorName = styled.div`
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #fff 91%, rgba(255, 255, 255, 0) 100%);
  background-clip: text;
  left: 50%;
  overflow: hidden;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  white-space: nowrap;
  width: 100%;
`

export const StyledMatchStatus = styled.div`
  align-items: center;
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 18px;
  grid-area: status;
  line-height: 28px;
  margin-top: 17px;
  overflow: hidden;
`
export const StyledMatchStatusTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.xl};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  line-height: 28px;
`
export const StyledMatchStatusScore = styled.div`
  align-items: center;
  display: flex;
  font-size: 38px;
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  gap: 8px;
  line-height: 32px;
`
export const StyledMatchStatusScoreItems = styled.div``

export const StyledActions = styled.div<{ sportCode: ESportsCodes }>`
  &:empty {
    display: none;
  }

  align-items: center;
  background: ${(p) =>
    p.sportCode
      ? (SPORT_CERTAIN_DATA[p.sportCode] || SPORT_CERTAIN_DATA.default)
          .fixedColor
      : 'none'};
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  grid-column-gap: 12px;
  height: 32px;
  justify-content: center;
  line-height: 16px;
  opacity: 0.5;
`
