import { all, put, select, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  eventsProviderActions,
  MAX_PAGE_SIZE
} from 'astra-core/containers/EventsProvider'

import { eventsProviderSideEffects } from 'shared/lib/eventsProviderSideEffects'
import {
  getItemLocalStorage,
  removeItemLocalStorage,
  setItemLocalStorage
} from 'shared/lib/@system'
import { getFetchLineReqData } from 'pages/page-line/LineContainer/utils'

import {
  LINE_TOURNAMENT_FILTER_LS,
  lineTournamentsContainerActions
} from './slice'
import {
  FetchLinePayload,
  FetchLineTournamentsPayload,
  TFormatLineTournamentsFiltersParams
} from './types'
import { selectLineTournamentsFilters } from './selectors'
import {
  formatLineTournamentsFiltersData,
  getFetchLineTournamentsReqData
} from './utils'

const SOURCE_KEY = 'lineTournaments'
const SOURCE_KEY_LINE_EVENTS = 'lineEvents'

const fetchLineSort = ['scheduledAt.asc']
const fetchLineStatuses = ['TRADING', 'SUSPENDED', 'PLANNING', 'OPEN']

export function* fetchLineTournamentsSaga(
  action: PayloadAction<FetchLineTournamentsPayload>
) {
  const { sportId } = action.payload
  const filters = yield select(selectLineTournamentsFilters)

  const savedLSFilter = getItemLocalStorage(
    LINE_TOURNAMENT_FILTER_LS
  ) as TFormatLineTournamentsFiltersParams

  // from LS we filter only by TOP
  const currentFilter = savedLSFilter
    ? { ...filters, isTop: savedLSFilter.isTop }
    : filters

  const lineTournamentsFilter = formatLineTournamentsFiltersData(currentFilter)

  const reqData = getFetchLineTournamentsReqData({
    sportId,
    ...lineTournamentsFilter
  })

  yield put(
    eventsProviderActions.fetchTournaments({
      reqData: {
        ...reqData,
        pageSize: MAX_PAGE_SIZE,
        eventLiveliness: false,
        // @ts-ignore
        eventStatuses: fetchLineStatuses
      },

      sideEffects: eventsProviderSideEffects[SOURCE_KEY],
      params: {
        withNewRequest: true
      }
    })
  )
}

export function* fetchLineSaga(action: PayloadAction<FetchLinePayload>) {
  const { tournamentId } = action.payload.reqData
  const filters = yield select(selectLineTournamentsFilters)

  const savedLSFilter = getItemLocalStorage(
    LINE_TOURNAMENT_FILTER_LS
  ) as TFormatLineTournamentsFiltersParams

  // from LS we filter only by TOP
  const currentFilter = savedLSFilter
    ? { ...filters, isTop: savedLSFilter.isTop }
    : filters

  const lineTournamentsFilter = formatLineTournamentsFiltersData(currentFilter)

  const reqData = getFetchLineReqData({
    tournamentId: String(tournamentId),
    ...lineTournamentsFilter
  })

  yield put(
    eventsProviderActions.fetchEvents({
      reqData: {
        ...reqData,
        pageSize: MAX_PAGE_SIZE,
        sort: fetchLineSort,
        // @ts-ignore
        statuses: fetchLineStatuses,
        hasActiveLineOutcomes: true
      },
      sideEffects: eventsProviderSideEffects[SOURCE_KEY_LINE_EVENTS],
      params: action.payload.params
    })
  )
}

export function* changeLineFilterSaga(_action) {
  const filters = yield select(selectLineTournamentsFilters)

  setItemLocalStorage(LINE_TOURNAMENT_FILTER_LS, filters)
}

export function* removeLineFilterSaga(_action) {
  yield removeItemLocalStorage(LINE_TOURNAMENT_FILTER_LS)
}

export function* lineTournamentsContainerSaga() {
  yield all([
    takeLatest(
      lineTournamentsContainerActions.fetchLineTournaments.type,
      fetchLineTournamentsSaga
    ),
    takeLatest(lineTournamentsContainerActions.fetchLine.type, fetchLineSaga),
    takeLatest(
      lineTournamentsContainerActions.toggleFilterIsTop.type,
      changeLineFilterSaga
    ),
    // do not delete
    // takeLatest(
    //   lineTournamentsContainerActions.setFilterPeriod.type,
    //   changeLineFilterSaga
    // ),
    takeLatest(
      lineTournamentsContainerActions.resetLineFilter.type,
      removeLineFilterSaga
    )
  ])
}
