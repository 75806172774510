import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { useParams } from 'react-router-dom'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { RootState } from 'shared/types/store'
import { StyledLoader } from 'shared/ui/DataList/DataListDefault/styled'
import { useInfiniteScroll } from 'hooks'

import { LiveTable } from '../Line/Live'
import { GenericLiveTable } from '../Line/GenericLiveTable'

import {
  getPaginationIsLoading,
  selectFilterIsTop,
  selectLiveEventsPaginationHasMore,
  selectLiveIsEmpty
} from './selectors'
import { useLiveContainerData } from './hooks'
import { LiveParams } from './types'
import { liveContainerActions } from './slice'
import { getFetchLiveReqData } from './utils'

export function LiveContainer() {
  const isEmpty = useSelector(selectLiveIsEmpty)
  // TODO:PLAT-867 Remove after release
  const isGenericSportEnabled = useFeatureFlag(
    EFeatureFlags.GENERIC_SPORT_ENABLED
  )
  const isResponseOfEventsEnabled = useFeatureFlag(
    EFeatureFlags.NEW_EVENTS_ENDPOINT
  )

  useLiveContainerData()

  const { sportId } = useParams<LiveParams>()
  const dispatch = useDispatch()

  const isPaginationLoading = useSelector(getPaginationIsLoading)
  const hasMore = useSelector((state: RootState) =>
    selectLiveEventsPaginationHasMore(state, +sportId)
  )

  const top = useSelector(selectFilterIsTop)

  const loadMore = useCallback(() => {
    const requestEnabled = hasMore && !isPaginationLoading && sportId

    if (requestEnabled) {
      dispatch(
        liveContainerActions.fetchLivePagination(
          getFetchLiveReqData({ sportId, top })
        )
      )
    }
  }, [hasMore, isPaginationLoading, sportId, dispatch, top])

  const { loadingRef } = useInfiniteScroll(loadMore, isPaginationLoading)

  if (isEmpty) return <LoaderSpinner />

  if (isResponseOfEventsEnabled) {
    return (
      <>
        {isGenericSportEnabled ? <GenericLiveTable /> : <LiveTable />}
        {hasMore && (
          <div ref={loadingRef}>{isPaginationLoading && <StyledLoader />}</div>
        )}
      </>
    )
  }

  return isGenericSportEnabled ? <GenericLiveTable /> : <LiveTable />
}
